import React, { useEffect, useState, useMemo, useCallback } from "react";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Profile.module.css";
import ProfileModal from "../../../components/Modals/ProfileModal";
import IntroModal from "../../../components/Modals/IntroModal";
import SkillsModal from "../../../components/Modals/SkillsModal";
import { firestore, auth } from "../../../firebase";
import RepostsModal from "../../../components/Modals/RepostsModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import icon from "../../../assets/images/icon.png";
import community from "../../../assets/images/community.png";
import yc from "../../../assets/images/yc.png";
import bigben from "../../../assets/images/bigben.jpg";
import pg from "../../../assets/images/pg.jpeg";
import cofounder from "../../../assets/images/cofounder.png";
import demoday from "../../../assets/images/demoday.png";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/icons/profileImage.jpg";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import InterestsModal from "../../../components/Modals/InterestsModal";
import AddExperienceModal from "../../../components/Modals/AddExperienceModal";
import EditExperienceModal from "../../../components/Modals/EditExperienceModal";
import EditSingleExperienceModal from "../../../components/Modals/EditSingleExperienceModal";
import AddEducationModal from "../../../components/Modals/AddEducationModal";
import EditEducationModal from "../../../components/Modals/EditEducationModal";
import EditSingleEducationModal from "../../../components/Modals/EditSingleEducationModal";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { convertUrlsToLinks } from "../../../utils/textUtils";

function Profile() {
  const [hoverStates, setHoverStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    intro: "",
    link: "",
    linkText: "",
    skills: [],
    interests: [],
    experiences: [],
    education: [],
    profileImage: "",
  });
  const [expandedTexts, setExpandedTexts] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showEditSingleExperienceModal, setShowEditSingleExperienceModal] =
    useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showEditSingleEducationModal, setShowEditSingleEducationModal] =
    useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [displayedReactions, setDisplayedReactions] = useState(3);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  const [connectionsCount, setConnectionsCount] = useState(0);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [activityType, setActivityType] = useState("Posts");

  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const [showAllSkills, setShowAllSkills] = useState(false);
  const [showAllInterests, setShowAllInterests] = useState(false);
  const [debugExperiences, setDebugExperiences] = useState([]);

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    comments: 0,
  });

  const handleConnectionRemoved = useCallback(() => {
    setConnectionsCount((prevCount) => prevCount - 1);
  }, []);

  // Add these functions after your other useCallback functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  // Add this useEffect to fetch activity counts

  const isMobile = () => {
    return window.innerWidth < 648;
  };

  const toggleShowAllSkills = () => setShowAllSkills(true);
  const toggleShowAllInterests = () => setShowAllInterests(true);

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [profileModalScrollPosition, setProfileModalScrollPosition] =
    useState(0);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [
    addExperienceModalScrollPosition,
    setAddExperienceModalScrollPosition,
  ] = useState(0);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [addEducationModalScrollPosition, setAddEducationModalScrollPosition] =
    useState(0);
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] = useState(false);
  const [
    editExperienceModalScrollPosition,
    setEditExperienceModalScrollPosition,
  ] = useState(0);
  const [isEditExperienceModalOpen, setIsEditExperienceModalOpen] =
    useState(false);
  const [
    editEducationModalScrollPosition,
    setEditEducationModalScrollPosition,
  ] = useState(0);
  const [isEditEducationModalOpen, setIsEditEducationModalOpen] =
    useState(false);

  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const storage = getStorage();

  const navigate = useNavigate();

  const [showFullIntro, setShowFullIntro] = useState(false);

  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);

  const [reactions, setReactions] = useState([]);

  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const toggleShowAllExperiences = () => {
    setShowAllExperiences(true);
  };

  const toggleShowAllEducation = () => {
    setShowAllEducation(true);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleExpandText = useCallback((id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const MAX_LENGTH = 250; // Same as Intro text

  const renderTruncatedText = useCallback(
    (text, id) => {
      if (!text) return null;

      const isExpanded = expandedTexts[id];

      if (text.length <= MAX_LENGTH) {
        return <p className={classes.description}>{text}</p>;
      }

      if (isExpanded) {
        return <p className={classes.description}>{text}</p>;
      }

      const truncatedText = text.slice(0, MAX_LENGTH).trim();
      return (
        <p className={classes.description}>
          {truncatedText}
          <span
            className={classes.moreButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleExpandText(id);
            }}
          >
            ...more
          </span>
        </p>
      );
    },
    [expandedTexts, toggleExpandText]
  );

  const renderDescription = (description, id) => {
    if (!description) return null;

    return (
      <>
        <p
          className={`${classes.role} ${
            expandedDescriptions[id] ? classes.expanded : ""
          }`}
          style={{
            whiteSpace: "pre-wrap",
            WebkitLineClamp: expandedDescriptions[id] ? "unset" : 3,
          }}
        >
          {description}
          {!expandedDescriptions[id] && description.split("\n").length > 3 && (
            <span
              className={classes.moreButton}
              onClick={() => toggleDescription(id)}
            >
              ...more
            </span>
          )}
        </p>
        {expandedDescriptions[id] && (
          <span
            className={classes.moreButton}
            onClick={() => toggleDescription(id)}
          >
            Show less
          </span>
        )}
      </>
    );
  };

  const renderIntroText = () => {
    const introText =
      userData.introHtml || "Describe who you are, and what you do.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  const sortedExperiences = useMemo(() => {
    if (!userData.experiences) return [];
    return [...userData.experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [userData.experiences]);

  useEffect(() => {
    console.log("Sorted experiences:", sortedExperiences);
    setDebugExperiences(sortedExperiences);
  }, [sortedExperiences]);

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromOwnProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
      },
    });
  };

  const fetchPosts = useCallback(async () => {
    if (!currentUser) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${currentUser.uid}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoadingPosts(false);
    }
  }, [currentUser]);

  const handlePostClick = (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: false,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Reactions":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const handleShowAllComments = () => {
    navigate("/all-comments/me");
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  useEffect(() => {
    if (currentUser && activityType === "Posts") {
      fetchPosts();
    }
  }, [currentUser, activityType, fetchPosts]);

  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <div className={classes.postInteractionCount}>
        {commentCount > 0 && (
          <span
            className={classes.commentText}
            onClick={() => onCommentClick(postId)}
          >
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span
            className={classes.repost}
            onClick={() => onClick(postId, reposts)}
          >
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </div>
    );
  };

  const fetchConnectionsCount = useCallback(async (userId) => {
    const connectionsRef = collection(firestore, `users/${userId}/connections`);
    const connectionsSnapshot = await getDocs(connectionsRef);
    const connectedCount = connectionsSnapshot.docs.filter(
      (doc) => doc.data().status === "Connected"
    ).length;
    setConnectionsCount(connectedCount);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchConnectionsCount(currentUser.uid);
    }
  }, [currentUser, fetchConnectionsCount]);

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
  };

  const ReactionsSummary = ({ reactions, onClick }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div className={classes.reactionsSummary} onClick={onClick}>
        <div className={classes.reactionStack}>
          {topEmojis.reverse().map((emoji, index) => (
            <div
              key={index}
              className={classes.reactionEmoji}
              style={{ zIndex: topEmojis.length - index }}
            >
              {emoji}
            </div>
          ))}
        </div>
        <span className={classes.reactionCount}>{reactionCount}</span>
      </div>
    );
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const toggleProfileModal = (isSave = false) => {
    if (!showProfileModal) {
      setProfileModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, profileModalScrollPosition);
      }, 0);
    }
    setShowProfileModal(!showProfileModal);
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const toggleIntroModal = (isSave = false) => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const toggleSkillsModal = (isSave = false) => {
    if (!showSkillsModal) {
      // Opening the modal
      setSkillsModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, skillsModalScrollPosition);
      }, 0);
    }
    setShowSkillsModal(!showSkillsModal);
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleInterestsModal = (isSave = false) => {
    if (!showInterestsModal) {
      // Opening the modal
      setModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, modalScrollPosition);
      }, 0);
    }
    setShowInterestsModal(!showInterestsModal);
    setIsInterestsModalOpen(!isInterestsModalOpen);
  };

  const toggleAddExperienceModal = (isSave = false) => {
    if (!showAddExperienceModal) {
      setAddExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addExperienceModalScrollPosition);
      }, 0);
    }
    setShowAddExperienceModal(!showAddExperienceModal);
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);
  };

  const toggleEditExperienceModal = () => {
    if (!showEditExperienceModal) {
      setEditExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editExperienceModalScrollPosition);
      }, 0);
    }
    setShowEditExperienceModal(!showEditExperienceModal);
    setIsEditExperienceModalOpen(!isEditExperienceModalOpen);
    setShowEditSingleExperienceModal(false);
  };

  const toggleAddEducationModal = (isSave = false) => {
    if (!showAddEducationModal) {
      setAddEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEducationModalScrollPosition);
      }, 0);
    }
    setShowAddEducationModal(!showAddEducationModal);
    setIsAddEducationModalOpen(!isAddEducationModalOpen);
  };

  const toggleEditEducationModal = () => {
    if (!showEditEducationModal) {
      setEditEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEducationModalScrollPosition);
      }, 0);
    }
    setShowEditEducationModal(!showEditEducationModal);
    setIsEditEducationModalOpen(!isEditEducationModalOpen);
    setShowEditSingleEducationModal(false);
  };

  const openEditSingleEducationModal = (education) => {
    setSelectedEducation(education);
    setShowEditSingleEducationModal(true);
    setShowEditEducationModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? true : state))
      );
    }
  };

  const handleMouseLeave = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? false : state))
      );
    }
  };

  const handleProfileSave = (newUserData) => {
    setUserData((prevData) => {
      const updatedData = { ...prevData };

      Object.keys(newUserData).forEach((key) => {
        if (key !== "experiences" && key !== "education") {
          updatedData[key] = newUserData[key];
        }
      });

      if (newUserData.experiences) {
        updatedData.experiences = newUserData.experiences;
      }

      if (newUserData.education) {
        updatedData.education = newUserData.education;
      }

      return updatedData;
    });
  };

  const handleExperienceAdded = (newExperience) => {
    setUserData((prevData) => ({
      ...prevData,
      experiences: [...prevData.experiences, newExperience],
    }));
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      return (
        <div className={classes.mediaPreview}>
          <img src={thumbnailUrl} alt="Video preview" />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const handleEducationAdded = (newEducation) => {
    setUserData((prevData) => ({
      ...prevData,
      education: [...(prevData.education || []), newEducation],
    }));
  };

  const userImage = userData.profileImage || profileImage;

  const openEditSingleExperienceModal = (experience) => {
    setSelectedExperience(experience);
    setShowEditSingleExperienceModal(true);
    setShowEditExperienceModal(false);
  };

  const openConnectionsModal = () => {
    setConnectionsModalScrollPosition(window.scrollY);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  const fetchComments = useCallback(async () => {
    if (!currentUser) return;
    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${currentUser.uid}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentedPostsData = await Promise.all(
        commentedPostsSnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const originalPostRef = doc(
            firestore,
            `users/${data.originalPosterId}/posts/${data.postId}`
          );
          const originalPostSnap = await getDoc(originalPostRef);

          if (originalPostSnap.exists()) {
            const postData = originalPostSnap.data();
            return {
              id: docSnapshot.id,
              ...data,
              originalPost: {
                id: data.postId,
                ...postData,
                postPreview: {
                  mediaType: postData.content?.type || null,
                  mediaUrl: postData.content?.url || null,
                  text: postData.text || "",
                  thumbnail: postData.content?.thumbnail || null,
                },
              },
              lastCommentedAt: data.lastCommentedAt.toDate(),
            };
          }
          return null;
        })
      );

      const validCommentedPosts = commentedPostsData.filter(
        (post) => post !== null
      );
      setCommentedPosts(validCommentedPosts);
      console.log("Fetched commented posts:", validCommentedPosts);
    } catch (error) {
      console.error("Error fetching commented posts:", error);
    } finally {
      setLoadingComments(false);
    }
  }, [currentUser, firestore]);

  // Update the renderComments function to use the new data structure
  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t commented yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you comment on will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> commented on this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <div className={classes.defaultImage}>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="gray"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>You</span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                commentedPost.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postInteractions}>
                      {commentedPost.originalPost?.reactions &&
                        Object.keys(commentedPost.originalPost.reactions)
                          .length > 0 && (
                          <ReactionsSummary
                            reactions={commentedPost.originalPost.reactions}
                            onClick={() =>
                              handleReactionsClick(
                                commentedPost.originalPost.id,
                                commentedPost.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={commentedPost.originalPost?.id}
                          reposts={commentedPost.originalPost?.reposts}
                          commentCount={
                            commentedPost.originalPost?.commentCount
                          }
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(commentedPost.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
        {commentedPosts.length > 3 &&
          displayedComments < commentedPosts.length && (
            <div
              className={classes.showAllPosts}
              onClick={handleShowAllComments}
            >
              Show all comments
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </>
    );
  };

  const handleReactionClick = (reactionId, reactionEmoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: { fromUserProfile: false, reactionEmoji: reactionEmoji },
    });
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>You haven’t reacted yet</p>{" "}
              <p className={classes.defaultText}>
                Posts you react to will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}>
                  {" "}
                  reacted <span className={classes.textEmoji}>🐘</span> to this{" "}
                </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <div className={classes.defaultImage}>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="gray"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reacted{" "}
                        <span className={classes.textEmoji}>
                          {reaction.emoji}
                        </span>{" "}
                        to this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() =>
                        handleReactionClick(reaction.id, reaction.emoji)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {reaction.originalPost?.reactions &&
                      Object.keys(reaction.originalPost.reactions).length >
                        0 && (
                        <ReactionsSummary
                          reactions={reaction.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              reaction.originalPost.id,
                              reaction.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <div className={classes.rightInteractions}>
                      <PostInteractionCount
                        postId={reaction.originalPost?.id}
                        reposts={reaction.originalPost?.reposts}
                        commentCount={reaction.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(reaction.originalPost?.id)
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reactions.length > 3 && displayedReactions < reactions.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reactions/me")}
          >
            Show all reactions
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const fetchReactions = useCallback(async () => {
    if (!currentUser) return;
    setLoadingReactions(true);
    try {
      const reactedPostsRef = collection(
        firestore,
        `users/${currentUser.uid}/reactedPosts`
      );
      const reactedPostsQuery = query(
        reactedPostsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactedPostsSnapshot = await getDocs(reactedPostsQuery);

      const reactionsData = await Promise.all(
        reactedPostsSnapshot.docs.map(async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const originalPostRef = doc(
            firestore,
            `users/${reactionData.originalPosterId}/posts/${reactionData.postId}`
          );
          const originalPostSnap = await getDoc(originalPostRef);
          const originalPostData = originalPostSnap.exists()
            ? originalPostSnap.data()
            : null;

          return originalPostData
            ? {
                id: docSnapshot.id,
                ...reactionData,
                originalPost: {
                  id: reactionData.postId,
                  ...originalPostData,
                  postPreview: {
                    mediaType: originalPostData.content?.type || null,
                    mediaUrl: originalPostData.content?.url || null,
                    text: originalPostData.text || "",
                    thumbnail: originalPostData.content?.thumbnail || null,
                  },
                },
                reactedAt: reactionData.reactedAt.toDate(),
                emoji: reactionData.emoji,
              }
            : null;
        })
      );

      const validReactions = reactionsData.filter(
        (reaction) => reaction !== null
      );
      setReactions(validReactions);
    } catch (error) {
      console.error("Error fetching reactions:", error);
    } finally {
      setLoadingReactions(false);
    }
  }, [currentUser]);

  const fetchReposts = useCallback(async () => {
    if (!currentUser) return;
    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${currentUser.uid}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = await Promise.all(
        repostsSnapshot.docs.map(async (docSnapshot) => {
          const repostData = docSnapshot.data();
          const originalPostRef = doc(
            firestore,
            `users/${repostData.originalPosterId}/posts/${repostData.postId}`
          );
          const originalPostSnap = await getDoc(originalPostRef);
          const originalPostData = originalPostSnap.exists()
            ? originalPostSnap.data()
            : null;

          return originalPostData
            ? {
                id: docSnapshot.id,
                ...repostData,
                originalPost: {
                  id: repostData.postId,
                  ...originalPostData,
                  postPreview: {
                    mediaType: originalPostData.content?.type || null,
                    mediaUrl: originalPostData.content?.url || null,
                    text: originalPostData.text || "",
                    thumbnail: originalPostData.content?.thumbnail || null,
                  },
                },
                repostedAt: repostData.repostedAt.toDate(),
              }
            : null;
        })
      );

      const validReposts = repostsData.filter((repost) => repost !== null);
      setReposts(validReposts);
    } catch (error) {
      console.error("Error fetching reposts:", error);
    } finally {
      setLoadingReposts(false);
    }
  }, [currentUser]);

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t reposted yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you share will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> reposted this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <div className={classes.defaultImage}>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="gray"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {repost.originalPost?.reactions &&
                      Object.keys(repost.originalPost.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={repost.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              repost.originalPost.id,
                              repost.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <PostInteractionCount
                      postId={repost.originalPost?.id}
                      reposts={repost.originalPost?.reposts}
                      commentCount={repost.originalPost?.commentCount}
                      onClick={handleRepostsClick}
                      onCommentClick={() =>
                        handlePostClick(repost.originalPost?.id)
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reposts/me")}
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const handleCloseTouch = (modalToggleFunction) => (event) => {
    event.preventDefault();
    modalToggleFunction();
  };

  useEffect(() => {
    if (currentUser) {
      if (activityType === "Posts") {
        fetchPosts();
      } else if (activityType === "Reposts") {
        fetchReposts();
      } else if (activityType === "Reactions") {
        fetchReactions();
      } else if (activityType === "Comments") {
        fetchComments();
      }
    }
  }, [
    currentUser,
    activityType,
    fetchPosts,
    fetchReposts,
    fetchReactions,
    fetchComments,
  ]);

  const fetchActivityCounts = useCallback(async () => {
    if (!currentUser) return;

    try {
      const userId = currentUser.uid;

      // Fetch posts count
      const postsRef = collection(firestore, `users/${userId}/posts`);
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      // Fetch reactions count
      const reactionsRef = collection(
        firestore,
        `users/${userId}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      // Fetch reposts count
      const repostsRef = collection(firestore, `users/${userId}/reposts`);
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      // Fetch comments count
      const commentsRef = collection(
        firestore,
        `users/${userId}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        posts: postsCount,
        reactions: reactionsCount,
        reposts: repostsCount,
        comments: commentsCount,
      });
    } catch (error) {
      console.error("Error fetching activity counts:", error);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchActivityCounts();
    }
  }, [currentUser, fetchActivityCounts]);

  const isActivityEmpty = () => {
    return (
      activityCounts.posts === 0 &&
      activityCounts.reactions === 0 &&
      activityCounts.comments === 0 &&
      activityCounts.reposts === 0
    );
  };

  const fetchAllData = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);

        // Fetch user data
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const data = userDoc.data();

          // Fetch experiences
          const experiencesSnapshot = await getDocs(
            collection(firestore, "users", userId, "experiences")
          );
          const experiences = experiencesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            dateAdded: doc.data().dateAdded,
          }));

          // Fetch education
          const educationSnapshot = await getDocs(
            collection(firestore, "users", userId, "education")
          );
          const education = educationSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Fetch posts
          await fetchPosts();

          // Fetch comments
          await fetchComments();

          // Fetch reactions
          await fetchReactions();

          // Fetch reposts
          await fetchReposts();

          // Fetch connections count
          await fetchConnectionsCount(userId);

          // Fetch activity counts
          await fetchActivityCounts();

          setUserData((prevData) => ({
            ...prevData,
            ...data,
            experiences: experiences || [],
            education: education || [],
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      fetchPosts,
      fetchComments,
      fetchReactions,
      fetchReposts,
      fetchConnectionsCount,
      fetchActivityCounts,
    ]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchAllData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({});
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, fetchAllData]);

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar userImage={userImage} />
        ) : (
          <MainNavbar userImage={userImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${classes.page} ${
        isInterestsModalOpen ||
        isSkillsModalOpen ||
        isConnectionsModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isProfileModalOpen ||
        isIntroModalOpen ||
        isAddExperienceModalOpen ||
        isAddEducationModalOpen ||
        isEditExperienceModalOpen ||
        isEditEducationModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isInterestsModalOpen
          ? { top: `-${modalScrollPosition}px` }
          : isSkillsModalOpen
          ? { top: `-${skillsModalScrollPosition}px` }
          : isConnectionsModalOpen
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isProfileModalOpen
          ? { top: `-${profileModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isAddExperienceModalOpen
          ? { top: `-${addExperienceModalScrollPosition}px` }
          : isAddEducationModalOpen
          ? { top: `-${addEducationModalScrollPosition}px` }
          : isEditExperienceModalOpen
          ? { top: `-${editExperienceModalScrollPosition}px` }
          : isEditEducationModalOpen
          ? { top: `-${editEducationModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      <div className={classes.content}>
        {showProfileModal && (
          <ProfileModal
            onClose={toggleProfileModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleProfileModal(true);
            }}
          />
        )}
        {showIntroModal && (
          <IntroModal
            onClose={toggleIntroModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleIntroModal(true);
            }}
          />
        )}
        {showSkillsModal && (
          <SkillsModal
            onClose={toggleSkillsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleSkillsModal(true);
            }}
          />
        )}
        {showInterestsModal && (
          <InterestsModal
            onClose={toggleInterestsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleInterestsModal(true);
            }}
          />
        )}
        {showAddExperienceModal && (
          <AddExperienceModal
            onClose={toggleAddExperienceModal}
            currentUser={currentUser}
            onExperienceAdded={(newExperience) => {
              handleExperienceAdded(newExperience);
              toggleAddExperienceModal(true);
            }}
          />
        )}
        {showEditExperienceModal && (
          <EditExperienceModal
            onClose={() => {
              toggleEditExperienceModal();
            }}
            currentUser={currentUser}
            onExperienceUpdated={handleProfileSave}
            onEditSingle={openEditSingleExperienceModal}
          />
        )}
        {showEditSingleExperienceModal && selectedExperience && (
          <EditSingleExperienceModal
            experience={selectedExperience}
            onClose={() => {
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onUpdate={(updatedExperience) => {
              handleProfileSave({
                experiences: userData.experiences.map((exp) =>
                  exp.id === updatedExperience.id ? updatedExperience : exp
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onDelete={(deletedExperienceId) => {
              handleProfileSave({
                experiences: userData.experiences.filter(
                  (exp) => exp.id !== deletedExperienceId
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showAddEducationModal && (
          <AddEducationModal
            onClose={toggleAddEducationModal}
            currentUser={currentUser}
            onEducationAdded={(newEducation) => {
              handleEducationAdded(newEducation);
              toggleAddEducationModal(true);
            }}
          />
        )}
        {showEditEducationModal && (
          <EditEducationModal
            onClose={() => {
              toggleEditEducationModal();
            }}
            currentUser={currentUser}
            onEducationUpdated={handleProfileSave}
            onEditSingle={openEditSingleEducationModal}
          />
        )}
        {showEditSingleEducationModal && selectedEducation && (
          <EditSingleEducationModal
            education={selectedEducation}
            onClose={() => {
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onUpdate={(updatedEducation) => {
              handleProfileSave({
                education: userData.education.map((exp) =>
                  exp.id === updatedEducation.id ? updatedEducation : exp
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onDelete={(deletedEducationId) => {
              handleProfileSave({
                education: userData.education.filter(
                  (exp) => exp.id !== deletedEducationId
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showConnectionsModal && (
          <ConnectionsModal
            onClose={handleModalClose}
            currentUserId={currentUser?.uid}
            loggedInUserId={currentUser?.uid}
            isOwnProfile={true}
            showConnectionDate={true}
            onConnectionRemoved={handleConnectionRemoved} // Make sure this line is present
          />
        )}

        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={() => {
              setShowReactionsModal(false);
              setIsReactionsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, reactionsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser.uid}
          />
        )}
        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={() => {
              setShowRepostsModal(false);
              setIsRepostsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, repostsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser.uid}
          />
        )}

        <div className={classes.centreCards}>
          {/* PROFILE CARD */}
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                onClick={handleCloseTouch(toggleProfileModal)}
                className={classes.profileImage}
              >
                <img src={userImage || defaultImage} alt="Profile" />
              </div>
              <div onClick={handleCloseTouch(toggleProfileModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              {userData.firstName} {userData.lastName}{" "}
              <span className={classes.connectionType}>• You</span>
            </div>

            <div>
              <div className={classes.bio}>{userData.bio}</div>

              <div className={classes.location}>
                {userData.city}, {userData.country}{" "}
              </div>
              {userData.link && (
                <div className={classes.link}>
                  <a
                    href={userData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {userData.linkText || userData.link}
                  </a>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangeRed"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.data}>
                <span
                  className={classes.numbers}
                  onClick={openConnectionsModal}
                  style={{ cursor: "pointer" }}
                >
                  {connectionsCount}{" "}
                  {connectionsCount === 1 ? "Connection" : "Connections"}
                </span>
                <span className={classes.dot}>•</span>
                <span className={classes.numbers}>0 Following</span>
              </div>
              <div className={classes.buttons}>
                {!userData.bio && (
                  <div
                    className={classes.headlineButton}
                    onClick={handleCloseTouch(toggleProfileModal)}
                  >
                    <svg
                      className=""
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14m-7 7V5"
                      />
                    </svg>
                    Add a headline
                  </div>
                )}
                {!userData.link && (
                  <div
                    className={classes.button}
                    onClick={handleCloseTouch(toggleProfileModal)}
                  >
                    <svg
                      className=""
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14m-7 7V5"
                      />
                    </svg>
                    Add a link
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                !userData.introHtml ? classes.emptyCardTitle : ""
              }`}
            >
              Intro
              <div onClick={handleCloseTouch(toggleIntroModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.introText}>
              <pre>
                {userData.introHtml ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: renderIntroText().text }}
                  />
                ) : (
                  <span className={classes.emptyIntroText}>
                    Tell us your story. What drives you? What are you working on
                    or interested in? Give others a quick snapshot of who you
                    are.
                  </span>
                )}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={handleMoreClick}
                  >
                    ...more
                  </span>
                )}
              </pre>
            </div>
          </div>
          <div className={classes.activityCard}>
            <div
              className={`${classes.activityCardTitle} ${
                isActivityEmpty() ? classes.emptyCardTitle : ""
              }`}
            >
              Activity
            </div>

            <ActivityButtons
              selectedActivity={activityType}
              onSelectionChange={handleActivityChange}
              activityCounts={activityCounts}
              showAllButtons={true}
            />
            {activityType === "Posts" && (
              <>
                {loadingPosts ? (
                  <div className={classes.loadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={classes.posts}>
                      {posts.length === 0 ? (
                        <div>
                          <p className={classes.defaultTextBold}>
                            You haven’t posted yet
                          </p>{" "}
                          <p className={classes.defaultText}>
                            Posts you create will be displayed here.
                          </p>
                          <div className={classes.noPostBorder}></div>
                          <div className={classes.postHeader}>
                            <span className={classes.postAuthor}>You</span>
                            <span className={classes.postMessage}>
                              {" "}
                              posted this{" "}
                            </span>
                            <span className={classes.dot}>•</span>
                            <span>now</span>
                          </div>
                          <div className={classes.noPost}>
                            <div className={classes.postContent}>
                              <div className={classes.defaultImage}>
                                <svg
                                  class="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <p className={classes.noTextPreview}>Post</p>
                            </div>
                          </div>
                          <div className={classes.noPostInteractions}>
                            <div>100 reactions</div>
                            <div className={classes.noRightInteractions}>
                              <div>50 comments</div>
                              <span className={classes.dot}>•</span>
                              <div>25 reposts</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {posts.slice(0, displayedPosts).map((post, index) => (
                            <React.Fragment key={post.id}>
                              {index !== 0 && (
                                <div className={classes.postBorder}></div>
                              )}
                              <div>
                                <div className={classes.postHeader}>
                                  <span className={classes.postAuthor}>
                                    You
                                  </span>
                                  <span className={classes.postMessage}>
                                    {" "}
                                    posted this{" "}
                                  </span>
                                  <span className={classes.dot}>•</span>
                                  <span>{formatTimestamp(post.createdAt)}</span>
                                </div>
                                <div
                                  className={`${classes.post} ${
                                    !post.postPreview.mediaType &&
                                    post.postPreview.text
                                      ? classes.textOnlyPost
                                      : ""
                                  }`}
                                  onClick={() => handlePostClick(post.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className={classes.postContent}>
                                    {post.postPreview &&
                                      post.postPreview.mediaType &&
                                      renderFilePreview(post.postPreview)}
                                    {post.postPreview &&
                                      post.postPreview.text && (
                                        <p className={classes.textPreview}>
                                          {truncateText(
                                            post.postPreview.text,
                                            3
                                          )}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className={classes.postInteractions}>
                                {post.reactions &&
                                  Object.keys(post.reactions).length > 0 && (
                                    <ReactionsSummary
                                      reactions={post.reactions}
                                      onClick={() =>
                                        handleReactionsClick(
                                          post.id,
                                          post.reactions
                                        )
                                      }
                                    />
                                  )}
                                <div className={classes.rightInteractions}>
                                  <PostInteractionCount
                                    postId={post.id}
                                    reposts={post.reposts}
                                    commentCount={post.commentCount}
                                    onClick={handleRepostsClick}
                                    onCommentClick={() =>
                                      handlePostClick(post.id)
                                    }
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </div>
                    {posts.length > 3 && displayedPosts < posts.length && (
                      <div
                        className={classes.showAllPosts}
                        onClick={() => navigate("/all-posts/me")}
                      >
                        Show all posts
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 12H5m14 0-4 4m4-4-4-4"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {activityType === "Reactions" && renderReactions()}
            {activityType === "Comments" && renderComments()}
            {activityType === "Reposts" && renderReposts()}
          </div>
          <div className={classes.card}>
            <div className={classes.extraCardTitle}>
              Startups
              <div>
                <div>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p className={classes.defaultText}>
                Got a startup or working on one? Share it here! Attract
                followers, find team members, and even raise funds from the
                community.
              </p>
            </div>

            <div className={classes.startupContainer}>
              <div className={classes.defaultImageTwo}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.startupAbout}>
                <div className={classes.startupRole}>Startup</div>
                <div className={classes.startupName}>Role</div>
                <div className={classes.startupName}>Description</div>
                <div className={classes.startupFollowing}>1K Followers</div>
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.extraCardTitle}>
              Communities
              <div>
                <div>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p className={classes.defaultText}>
                Join the conversation! Find or create forums on topics you're
                passionate about. Connect with like-minded folks, share ideas,
                and grow your network.
              </p>
            </div>

            <div className={classes.startupContainer}>
              <div className={classes.defaultImageTwo}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.startupAbout}>
                <div className={classes.startupRole}>Community</div>
                <div className={classes.startupName}>Role</div>
                <div className={classes.startupName}>Description</div>
                <div className={classes.startupFollowing}>500 Followers</div>
              </div>
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.extraCardTitle}>
              Events
              <div>
                <div>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p className={classes.defaultText}>
                What's happening in your scene? List events you're hosting or
                join exciting programmes. From meetups to accelerators, this is
                where it happens!
              </p>
            </div>
            <div className={classes.startupContainer}>
              <div className={classes.defaultImageThree}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.startupAbout}>
                <div className={classes.startupRole}>Event</div>
                <div className={classes.startupName}>Role</div>
                <div className={classes.startupName}>Description</div>
                <div className={classes.startupFollowing}>250 Followers</div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.rightCards}>
          {/* <div className={classes.card}>
            <div className={classes.cardTitle}>
              Donations
              <div>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.cardAbout}>You have no Donations.</div>
          </div> */}
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.skills && userData.skills.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Skills
              <div onClick={handleCloseTouch(toggleSkillsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.cardAbout}>
              {userData.skills && userData.skills.length > 0 ? (
                userData.skills
                  .slice(0, showAllSkills ? userData.skills.length : 9)
                  .map((skill, index) => (
                    <div key={index} className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="orangered"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>{skill}</span>
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    List your superpowers! What are you great at? Your skills
                    could be the missing piece someone's looking for.
                  </div>

                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Soft skill</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Technical skill</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.skills &&
              userData.skills.length > 9 &&
              !showAllSkills && (
                <div
                  className={classes.showAllExperiences}
                  onClick={toggleShowAllSkills}
                >
                  Show all skills
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </div>
              )}
          </div>

          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.interests && userData.interests.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Interests
              <div onClick={handleCloseTouch(toggleInterestsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.cardAbout}>
              {userData.interests && userData.interests.length > 0 ? (
                userData.interests
                  .slice(0, showAllInterests ? userData.interests.length : 9)
                  .map((interest, index) => (
                    <div key={index} className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6 text-gray"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="orangeRed"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>{interest}</span>
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    What gets you excited? Share your passions and interests.
                    You never know who might share them!
                  </div>
                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>Soft interest</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>
                        Technical interest
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.interests &&
              userData.interests.length > 9 &&
              !showAllInterests && (
                <div
                  className={classes.showAllExperiences}
                  onClick={toggleShowAllInterests}
                >
                  Show all interests
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </div>
              )}
          </div>
          <div className={classes.experiencesCard}>
            <div
              className={`${classes.experiencesCardTitle} ${
                !userData.experiences || userData.experiences.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Experience
              <div>
                <div onClick={handleCloseTouch(toggleAddExperienceModal)}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>

                <div
                  className={`${classes.pencilIcon} ${
                    !userData.experiences || userData.experiences.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.experiences && userData.experiences.length > 0
                      ? handleCloseTouch(toggleEditExperienceModal)
                      : undefined
                  }
                >
                  {userData.experiences && userData.experiences.length > 0 && (
                    <div onClick={toggleEditExperienceModal}>
                      <svg
                        className={classes.experienceIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.experiencesCardAbout}>
              {sortedExperiences.length > 0 ? (
                sortedExperiences
                  .slice(0, showAllExperiences ? sortedExperiences.length : 3)
                  .map((experience) => (
                    <div
                      key={experience.id}
                      className={classes.experienceContainer}
                    >
                      <p className={classes.organisation}>
                        {experience.organisation}
                      </p>
                      <p className={classes.role}>{experience.role}</p>

                      <p className={classes.dates}>
                        {experience.startMonth} {experience.startYear} -{" "}
                        {experience.isCurrentlyWorking
                          ? "Current"
                          : `${experience.endMonth} ${experience.endYear}`}
                      </p>
                      {renderTruncatedText(
                        experience.description,
                        `exp-${experience.id}`
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    Your journey matters. Jot down your key experiences - every
                    bit counts.
                  </div>
                  <div className={classes.noExperienceContainer}>
                    <p className={classes.noOrganisation}>Organisation</p>
                    <p className={classes.noRole}>Role</p>
                    <p className={classes.noRole}>Description</p>
                    <p className={classes.dates}>2024 - Current</p>
                  </div>
                </div>
              )}
            </div>
            {sortedExperiences.length > 3 && !showAllExperiences && (
              <div
                className={classes.showAllExperiences}
                onClick={toggleShowAllExperiences}
              >
                Show all experiences
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className={classes.educationCard}>
            <div
              className={`${classes.educationCardTitle} ${
                !userData.education || userData.education.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Education
              <div>
                <div onClick={toggleAddEducationModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                <div
                  className={`${classes.pencilIcon} ${
                    !userData.education || userData.education.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.education && userData.education.length > 0
                      ? handleCloseTouch(toggleEditEducationModal)
                      : undefined
                  }
                >
                  {!userData.education || userData.education.length === 0 || (
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.educationCardAbout}>
              {userData.education && userData.education.length > 0 ? (
                userData.education
                  .sort((a, b) => {
                    const now = new Date();
                    const currentYear = now.getFullYear();
                    const currentMonth = monthsList.indexOf(
                      now.toLocaleString("default", { month: "long" })
                    );

                    const getEndDate = (edu) => {
                      if (edu.isCurrentlyStudying) {
                        return new Date(currentYear, currentMonth);
                      }
                      return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
                    };

                    const aEnd = getEndDate(a);
                    const bEnd = getEndDate(b);

                    if (aEnd > bEnd) return -1;
                    if (aEnd < bEnd) return 1;

                    const aStart = new Date(
                      `${a.startMonth} 1, ${a.startYear}`
                    );
                    const bStart = new Date(
                      `${b.startMonth} 1, ${b.startYear}`
                    );

                    if (aStart > bStart) return -1;
                    if (aStart < bStart) return 1;

                    return new Date(b.dateAdded) - new Date(a.dateAdded);
                  })
                  .slice(0, showAllEducation ? userData.education.length : 3)
                  .map((education) => (
                    <div
                      key={education.id}
                      className={classes.educationContainer}
                    >
                      <p className={classes.school}>{education.school}</p>
                      <p className={classes.degree}>{education.degree}</p>
                      <p className={classes.subject}>{education.subject}</p>
                      <p className={classes.grade}>{education.grade}</p>

                      <p className={classes.dates}>
                        {education.startMonth} {education.startYear} -{" "}
                        {education.isCurrentlyStudying
                          ? "Present"
                          : `${education.endMonth} ${education.endYear}`}
                      </p>
                      {renderTruncatedText(
                        education.description,
                        `edu-${education.id}`
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    School of life or traditional education? Share your learning
                    path. It might inspire others.
                  </div>
                  <div className={classes.noExperienceContainer}>
                    <p className={classes.noSchool}>School</p>
                    <p className={classes.noDegree}>Degree</p>
                    <p className={classes.noSubject}>Subject</p>
                    <p className={classes.grade}>Grade</p>
                    <p className={classes.noSubject}>Description</p>
                    <p className={classes.dates}>2021 - 2024</p>
                  </div>
                </div>
              )}
            </div>
            {userData.education &&
              userData.education.length > 3 &&
              !showAllEducation && (
                <div
                  className={classes.showAllExperiences}
                  onClick={toggleShowAllEducation}
                >
                  Show all educations
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </div>
              )}
          </div>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Profile;
