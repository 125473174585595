import React, { useState, useEffect, useMemo } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import classes from "./ReactionsModal.module.css";
import { useNavigate } from "react-router-dom";

const ReactionsModal = ({ onClose, postId, reactions, currentUserId }) => {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const userIds = Object.keys(reactions);
      const fetchedUsers = {};
      for (const userId of userIds) {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const connectionType = await checkConnectionType(
            currentUserId,
            userId
          );

          // Fetch the reactedAt timestamp
          const reactedPostDoc = await getDoc(
            doc(firestore, `users/${userId}/reactedPosts/${postId}`)
          );
          const reactedAt = reactedPostDoc.exists()
            ? reactedPostDoc.data().reactedAt
            : null;

          fetchedUsers[userId] = { ...userData, connectionType, reactedAt };
        }
      }
      setUsers(fetchedUsers);
      setLoading(false);
    };

    fetchUsers();
  }, [reactions, currentUserId, postId]);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  const emojiCounts = useMemo(() => {
    const counts = {};
    Object.values(reactions).forEach((emoji) => {
      counts[emoji] = (counts[emoji] || 0) + 1;
    });
    return counts;
  }, [reactions]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const filteredReactions = useMemo(() => {
    return Object.entries(reactions).filter(([userId, emoji]) => {
      const user = users[userId];
      if (!user) return false;
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [reactions, users, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === currentUserId) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const sortedReactions = useMemo(() => {
    return Object.entries(reactions)
      .map(([userId, emoji]) => ({ userId, emoji, ...users[userId] }))
      .sort((a, b) => {
        if (a.userId === currentUserId) return -1;
        if (b.userId === currentUserId) return 1;
        // If reactedAt is missing for some reason, put those at the end
        if (!a.reactedAt) return 1;
        if (!b.reactedAt) return -1;
        // Sort from most recent to oldest
        return b.reactedAt.toMillis() - a.reactedAt.toMillis();
      });
  }, [reactions, users, currentUserId]);

  const formatTimeDifference = (timestamp) => {
    if (!timestamp) return "";

    const now = new Date();
    const reactionTime = timestamp.toDate();
    const diffInSeconds = Math.floor((now - reactionTime) / 1000);

    if (diffInSeconds < 60) return "now";
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes}m`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d`;
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks}w`;
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths}mo`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears}y`;
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  useEffect(() => {
    function detectSafari() {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      document.documentElement.style.setProperty(
        "--emoji-font-size-modal",
        isSafari ? "1rem" : "1rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top-modal",
        isSafari ? "1rem" : "1rem"
      );
      console.log("Is Safari (Modal):", isSafari); // For debugging
    }

    detectSafari();
  }, []);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {Object.keys(reactions).length}{" "}
            {Object.keys(reactions).length === 1 ? "Reaction" : "Reactions"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <div className={classes.reactionsList}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : sortedReactions.length > 0 ? (
            sortedReactions
              .filter((user) => {
                const fullName =
                  `${user.firstName} ${user.lastName}`.toLowerCase();
                return fullName.includes(searchTerm.toLowerCase());
              })
              .map(({ userId, emoji, ...user }) => (
                <div key={userId} className={classes.reactionItem}>
                  <div className={classes.userEmojiContainer}>
                    <img
                      onClick={() => handleNavigate(userId)}
                      src={user.profileImage || defaultImage}
                      alt={`${user.firstName} ${user.lastName}`}
                      className={classes.profileImage}
                    />
                    <span className={classes.userEmoji}>
                      <span className={classes.emojiContent}>{emoji}</span>
                    </span>
                  </div>
                  <div className={classes.userInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(userId)}
                        >
                          {user.firstName} {user.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          •{" "}
                          {userId === currentUserId
                            ? "You"
                            : user.connectionType || "Connection"}
                        </span>
                      </div>
                    </h3>
                    <p
                      className={`${classes.bio} ${!user.bio && classes.noBio}`}
                    >
                      {user.bio || user.username}
                    </p>
                    <p className={classes.reactionTime}>
                      {formatTimeDifference(user.reactedAt)}
                    </p>
                  </div>
                </div>
              ))
          ) : (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No reactions found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReactionsModal;
