import React, { useEffect, useState, useCallback } from "react";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Notifications.module.css";
import { firestore, auth } from "../../../firebase";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
  writeBatch,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import NotificationDropdown from "./NotificationDropdown";
import { useNavigate, Link } from "react-router-dom";
import { useNotification } from "../../../contexts/NotificationContext";

function Notifications() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });

  const { state, dispatch } = useNotification();
  const [localNewStatus, setLocalNewStatus] = useState({});
  const [deletedNotifications, setDeletedNotifications] = useState(new Set());
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState({});

  const { notification } = useNotification();

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const handlePostPreviewClick = (postId) => {
    navigate(`/post/${postId}`);
  };

  const generateThumbnail = (videoElement, notificationId) => {
    if (videoElement && !videoThumbnails[notificationId]) {
      videoElement.addEventListener("loadeddata", () => {
        videoElement.currentTime = 1; // Set to 1 second
      });

      videoElement.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const thumbnailUrl = canvas.toDataURL();
        setVideoThumbnails((prev) => ({
          ...prev,
          [notificationId]: thumbnailUrl,
        }));
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}yr`;
    }
  };

  const handleUserClick = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const fullName = `${userData.firstName} ${userData.lastName}`;
        return {
          name: fullName,
          profileImage: userData.profileImage || profileImage,
        };
      }
      console.log(`No user document found for ID: ${userId}`);
      return { name: "Unknown User", profileImage: profileImage };
    } catch (error) {
      console.error(`Error fetching user data for ${userId}:`, error);
      return { name: "Unknown User", profileImage: profileImage };
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};

    const fetchNotifications = async () => {
      if (currentUser) {
        setIsLoading(true);
        const notificationsRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "in", [
            "connection_request",
            "connection_accepted",
            "connection_declined",
            "reaction",
            "repost",
            "comment",
            "reply",
            "comment_reaction",
          ])
        );

        unsubscribe = onSnapshot(q, async (snapshot) => {
          try {
            const notificationsPromises = snapshot.docs.map(async (doc) => {
              if (!doc.exists()) {
                console.log(`Notification document ${doc.id} does not exist.`);
                return null;
              }
              const data = doc.data();
              let userData = {
                name: "Unknown User",
                profileImage: profileImage,
              };
              let postData = { postPreview: {} };

              if (
                data.from ||
                data.reactorId ||
                data.reposterId ||
                data.commenterId ||
                data.replierId
              ) {
                const userId =
                  data.from ||
                  data.reactorId ||
                  data.reposterId ||
                  data.commenterId ||
                  data.replierId;
                userData = await fetchUserData(userId);
              }

              if (
                [
                  "reaction",
                  "repost",
                  "comment",
                  "reply",
                  "comment_reaction",
                ].includes(data.type) &&
                data.postId
              ) {
                postData = await fetchPostData(
                  data.originalPosterId || currentUser.uid,
                  data.postId
                );
              }

              return {
                id: doc.id,
                ...data,
                fromUserName: userData.name,
                fromUserImage: userData.profileImage,
                reactionCount: postData.reactionCount,
                commentCount: postData.commentCount,
                postPreview: postData.postPreview,
                commentText: data.commentText || "",
                replyText: data.replyText || "",
                parentCommentPreview: data.parentCommentPreview || {},
                createdAt: data.createdAt?.toDate() || new Date(),
                updatedAt:
                  data.updatedAt?.toDate() ||
                  data.createdAt?.toDate() ||
                  new Date(),
                isNew: data.isNew !== false,
              };
            });

            let newNotifications = (
              await Promise.all(notificationsPromises)
            ).filter(Boolean);

            newNotifications = filterDuplicateRequests(newNotifications);

            newNotifications.sort(
              (a, b) =>
                (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
            );

            setLocalNotifications(newNotifications);

            setLocalNewStatus((prevStatus) => {
              const newStatus = { ...prevStatus };
              newNotifications.forEach((notif) => {
                if (notif.isNew && !(notif.id in newStatus)) {
                  newStatus[notif.id] = true;
                }
              });
              return newStatus;
            });

            setIsLoading(false);
            setHasLoadedOnce(true);
          } catch (error) {
            console.error("Error processing notifications:", error);
            setIsLoading(false);
            setHasLoadedOnce(true);
          }
        });
      } else {
        setIsLoading(false);
        setHasLoadedOnce(true);
      }
    };

    fetchNotifications();

    return () => unsubscribe();
  }, [currentUser]);

  // New function to filter out duplicate connection requests
  const filterDuplicateRequests = (notifications) => {
    const connectionRequests = new Map();

    return notifications.filter((notification) => {
      if (notification.type === "connection_request") {
        if (
          !connectionRequests.has(notification.from) ||
          notification.createdAt >
            connectionRequests.get(notification.from).createdAt
        ) {
          connectionRequests.set(notification.from, notification);
          return true;
        }
        return false;
      }
      return true;
    });
  };

  const fetchPostData = async (postOwnerId, postId) => {
    try {
      // First, try to fetch the post from the postOwnerId's collection
      let postDoc = await getDoc(
        doc(firestore, `users/${postOwnerId}/posts`, postId)
      );

      // If the post is not found, search in all users' collections
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const q = query(usersRef);
        const querySnapshot = await getDocs(q);

        for (const userDoc of querySnapshot.docs) {
          const potentialPostDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/posts`, postId)
          );
          if (potentialPostDoc.exists()) {
            postDoc = potentialPostDoc;
            break;
          }
        }
      }

      if (postDoc.exists()) {
        const postData = postDoc.data();
        return {
          reactionCount: postData.reactions
            ? Object.keys(postData.reactions).length
            : 0,
          commentCount: postData.commentCount || 0,
          postPreview: {
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            text: postData.text || "",
            thumbnail: postData.content?.thumbnail || null, // Add this line
          },
        };
      }
      console.log(`No post document found for ID: ${postId}`);
      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    } catch (error) {
      console.error(`Error fetching post data for ${postId}:`, error);
      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    }
  };

  const handleConnectionRequest = async (
    notificationId,
    fromUserId,
    action
  ) => {
    console.log(
      `Handling connection request: ${action} for notification ${notificationId} from user ${fromUserId}`
    );

    const currentUserRef = doc(firestore, `users/${currentUser.uid}`);
    const fromUserRef = doc(firestore, `users/${fromUserId}`);
    const currentUserConnectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${fromUserId}`
    );
    const fromUserConnectionRef = doc(
      firestore,
      `users/${fromUserId}/connections/${currentUser.uid}`
    );

    try {
      const fromUserData = await fetchUserData(fromUserId);
      const currentUserData = await fetchUserData(currentUser.uid);

      console.log(`From user data:`, fromUserData);
      console.log(`Current user data:`, currentUserData);

      if (action === "accept") {
        await updateDoc(currentUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        await updateDoc(fromUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_accepted",
          message: `${fromUserData.name} is now a connection.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);
        console.log(
          `Updated current user's notification:`,
          updatedNotification
        );

        // Create a new notification for the other user
        const newNotificationRef = doc(
          collection(firestore, `users/${fromUserId}/notifications`)
        );
        const newNotification = {
          type: "connection_accepted",
          message: `${currentUserData.name} is now a connection.`,
          fromUserName: currentUserData.name,
          fromUserImage: currentUserData.profileImage,
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true, // Add this line to mark the notification as new
        };
        await setDoc(newNotificationRef, newNotification);
        console.log(
          `Created new notification for other user:`,
          newNotification
        );
      } else if (action === "ignore") {
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_declined",
          message: `You declined ${fromUserData.name}'s request.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);
        console.log(
          `Updated current user's notification:`,
          updatedNotification
        );

        // Remove the connection documents
        await deleteDoc(currentUserConnectionRef);
        await deleteDoc(fromUserConnectionRef);
      }

      setNotifications((prev) =>
        prev
          .map((notif) => {
            if (notif.id === notificationId) {
              return {
                ...notif,
                type:
                  action === "accept"
                    ? "connection_accepted"
                    : "connection_declined",
                message:
                  action === "accept"
                    ? `${fromUserData.name} is now a connection.`
                    : `You declined ${fromUserData.name}'s request.`,
                fromUserName: fromUserData.name,
                fromUserImage: fromUserData.profileImage,
                updatedAt: new Date(),
              };
            }
            return notif;
          })
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );

      console.log(`Updated notifications in state`);
    } catch (error) {
      console.error("Error handling connection request:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {}
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const renderNotificationContent = (notification) => {
    const getUserName = () => {
      return (
        notification.fromUserName ||
        notification.commenterName ||
        notification.replierName ||
        "Unknown User"
      );
    };

    switch (notification.type) {
      case "connection_request":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>wants to connect.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "accept"
                  )
                }
                className={classes.button}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "ignore"
                  )
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "connection_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  is now a connection.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "connection_declined":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                  {notification.fromUserName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}> request declined.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.reactorId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reacted</span>
                <span className={classes.emoji}>{notification.emoji}</span>
                <span className={classes.messageText}>to this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div
                className={`${classes.post} ${
                  !notification.postPreview.mediaType &&
                  notification.postPreview.text
                    ? classes.textOnlyPost
                    : ""
                }`}
              >
                <div className={classes.postContent}>
                  {notification.postPreview &&
                    notification.postPreview.mediaType &&
                    renderFilePreview(notification.postPreview)}
                  {notification.postPreview &&
                    notification.postPreview.text && (
                      <p className={classes.textPreview}>
                        {notification.postPreview.text}
                      </p>
                    )}
                </div>
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "repost":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={classes.userName}
                  onClick={() => handleUserClick(notification.reposterId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reposted this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div
                className={`${classes.post} ${
                  !notification.postPreview.mediaType &&
                  notification.postPreview.text
                    ? classes.textOnlyPost
                    : ""
                }`}
              >
                <div className={classes.postContent}>
                  {notification.postPreview &&
                    notification.postPreview.mediaType &&
                    renderFilePreview(notification.postPreview)}
                  {notification.postPreview &&
                    notification.postPreview.text && (
                      <p className={classes.textPreview}>
                        {notification.postPreview.text}
                      </p>
                    )}
                </div>
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "comment":
      case "reply":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() =>
                    handleUserClick(
                      notification.commenterId || notification.replierId
                    )
                  }
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  {notification.type === "comment"
                    ? "commented on this."
                    : "replied to this."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText || notification.replyText}
                </div>
              </div>

              {notification.type === "reply" &&
                notification.parentCommentPreview && (
                  <div className={classes.commentReply}>
                    <div className={classes.parentCommentPreview}>
                      {notification.parentCommentPreview.text}
                    </div>
                  </div>
                )}

              <div className={classes.postContent}>
                {notification.postPreview &&
                  notification.postPreview.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview && notification.postPreview.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "comment_reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.reactorId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reacted</span>
                <span className={classes.emoji}>{notification.emoji}</span>
                <span className={classes.messageText}>to this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText}
                </div>
              </div>
              <div className={classes.postContent}>
                {notification.postPreview &&
                  notification.postPreview.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview && notification.postPreview.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      console.log("Video thumbnail URL:", thumbnailUrl); // Add this for debugging
      return (
        <div className={classes.mediaPreview}>
          {thumbnailUrl ? (
            <img
              src={thumbnailUrl}
              alt="Video preview"
              onError={(e) => {
                console.error("Error loading thumbnail:", e);
                e.target.src = "/path/to/fallback/image.jpg"; // Provide a fallback image
              }}
            />
          ) : (
            <div className={classes.videoPlaceholder}>Video</div>
          )}
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const userImage = userData.profileImage || profileImage;

  const deleteNotification = async (notificationId) => {
    try {
      console.log(`Attempting to delete notification: ${notificationId}`);
      console.log(`Current user: ${currentUser?.uid}`);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );

      // Check if the document exists before attempting to delete
      const docSnap = await getDoc(notificationRef);
      if (!docSnap.exists()) {
        console.log(
          `Notification ${notificationId} already deleted or doesn't exist`
        );
      } else {
        // Attempt to delete the document only if it exists
        await deleteDoc(notificationRef);
        console.log(`Firestore document deleted: ${notificationId}`);
      }

      // Update local state
      setLocalNotifications((prev) =>
        prev.filter((notif) => notif.id !== notificationId)
      );

      // Update localNewStatus
      setLocalNewStatus((prev) => {
        const newStatus = { ...prev };
        delete newStatus[notificationId];
        return newStatus;
      });

      // Add to deletedNotifications set
      setDeletedNotifications((prev) => new Set(prev).add(notificationId));

      console.log(`Local state updated for notification: ${notificationId}`);
    } catch (error) {
      console.error(`Error deleting notification ${notificationId}:`, error);
    }
  };

  const [localNotifications, setLocalNotifications] = useState([]);

  // const markNotificationsAsViewed = async () => {
  //   const batch = writeBatch(firestore);
  //   const notificationsToUpdate = [];

  //   for (const notification of notifications) {
  //     if (notification.isNew) {
  //       const notificationRef = doc(
  //         firestore,
  //         `users/${currentUser.uid}/notifications/${notification.id}`
  //       );
  //       const docSnap = await getDoc(notificationRef);
  //       if (docSnap.exists()) {
  //         batch.update(notificationRef, { isNew: false });
  //         notificationsToUpdate.push(notification.id);
  //       } else {
  //         console.log(`Notification ${notification.id} no longer exists.`);
  //       }
  //     }
  //   }

  //   if (notificationsToUpdate.length > 0) {
  //     await batch.commit();
  //     setNewNotificationsCount(0);
  //     setNotifications((prev) =>
  //       prev.map((notif) =>
  //         notificationsToUpdate.includes(notif.id)
  //           ? { ...notif, isNew: false }
  //           : notif
  //       )
  //     );
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     if (currentUser) {
  //       markNotificationsAsViewed();
  //     }
  //   };
  // }, [currentUser, notifications]);

  const markNotificationsAsOld = useCallback(async () => {
    const batch = writeBatch(firestore);
    let updatedCount = 0;

    for (const notification of localNotifications) {
      if (
        localNewStatus[notification.id] &&
        !deletedNotifications.has(notification.id)
      ) {
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notification.id}`
        );

        // Check if the document still exists before updating
        const docSnap = await getDoc(notificationRef);
        if (docSnap.exists()) {
          batch.update(notificationRef, { isNew: false });
          updatedCount++;
        }
      }
    }

    if (updatedCount > 0) {
      try {
        await batch.commit();
        console.log(`${updatedCount} notifications marked as old`);
        // Reset the notification count in the context
        dispatch({ type: "RESET_COUNT" });
      } catch (error) {
        console.error("Error marking notifications as old:", error);
      }
    }

    // Clear the deletedNotifications set after processing
    setDeletedNotifications(new Set());
  }, [
    localNotifications,
    localNewStatus,
    currentUser,
    dispatch,
    deletedNotifications,
  ]);

  useEffect(() => {
    return () => {
      if (currentUser) {
        markNotificationsAsOld();
      }
    };
  }, [currentUser, markNotificationsAsOld]);

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage || profileImage} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            </div>
          ) : hasLoadedOnce && localNotifications.length > 0 ? (
            <div className={classes.notifications}>
              {localNotifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`${classes.notification} ${
                    notification.type === "connection_accepted" ||
                    notification.type === "connection_declined"
                      ? classes.singleLineNotification
                      : ""
                  }`}
                >
                  <div className={classes.notificationContent}>
                    <div className={classes.profileImageContainer}>
                      <img
                        src={notification.fromUserImage}
                        alt={notification.fromUserName}
                        className={classes.profileImage}
                        onClick={() =>
                          handleUserClick(
                            notification.from ||
                              notification.reactorId ||
                              notification.reposterId ||
                              notification.commenterId
                          )
                        }
                      />
                      {localNewStatus[notification.id] && (
                        <div className={classes.newNotificationIndicator}></div>
                      )}
                    </div>
                    {renderNotificationContent(notification)}
                    <NotificationDropdown
                      onDelete={() => deleteNotification(notification.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : hasLoadedOnce ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No notifications found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <Link to="/dashboard" className={classes.homeButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Home
              </Link>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
