import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import { Link } from "react-router-dom";
import classes from "./Dashboard.module.css";
import { firestore, auth } from "../../../firebase";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "../../../components/Dropdowns/EmojiPicker";
import RepostsModal from "../../../components/Modals/RepostsModal";
import { useUpload } from "../../../contexts/UploadContext";
import { openDB } from "idb";
import {
  doc,
  getDoc,
  collection,
  where,
  query,
  orderBy,
  writeBatch,
  limit,
  setDoc,
  getDocs,
  startAfter,
  deleteDoc,
  addDoc, // Add this import
  updateDoc,
  deleteField,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  onSnapshot,
  collectionGroup,
  increment,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import PostModal from "../../../components/Modals/PostModal";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { convertUrlsToLinks } from "../../../utils/textUtils";
import PostActionPopup from "../../Posts/PostActionPopup";

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

function Dashboard() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [mainEmojiPickerOpen, setMainEmojiPickerOpen] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [successPostMessage, setSuccessPostMessage] = useState(null);
  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();
  const [cancelMessage, setCancelMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [isMobileFocused, setIsMobileFocused] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);

  const POST_HEIGHT = 400; // Estimate of average post height, adjust as needed
  const OVERSCAN_COUNT = 5; // Number of items to render beyond the visible area

  const [itemSizes, setItemSizes] = useState({});
  const listRef = useRef();
  const rowHeights = useRef({});

  const getItemSize = (index) => rowHeights.current[index] || 50; // Default to 50px if height is unknown

  const [isSafari, setIsSafari] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);

  const [commentedPosts, setCommentedPosts] = useState({});

  const [errorPopup, setErrorPopup] = useState(null);

  const [seenPosts, setSeenPosts] = useState({});

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);

  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isHovered, setIsHovered] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [posts, setPosts] = useState({
    directAndInteracted: [],
    indirectSuggested: [],
    extendedSuggested: [],
  });
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [directConnectionIds, setDirectConnectionIds] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);
  const [repostMessage, setRepostMessage] = useState(null);
  const [deleteTimeouts, setDeleteTimeouts] = useState({});

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);

  const [isIOS, setIsIOS] = useState(false);

  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});

  const [activeCommentId, setActiveCommentId] = useState(null);

  const replyInputRef = useRef({});

  const commentInputRef = useRef(null);

  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);

  const [removedPosts, setRemovedPosts] = useState({});

  // const [uploadingPosts, setUploadingPosts] = useState({});
  const uploadingPostsRef = useRef(state.uploadingPosts);
  const [canceledUploads, setCanceledUploads] = useState({});

  const [showDropdown, setShowDropdown] = useState({});

  const [visiblePosts, setVisiblePosts] = useState([]);
  const [page, setPage] = useState(0);
  const loaderRef = useRef(null);
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});

  const [openDropdown, setOpenDropdown] = useState(null);

  const [openUploadDropdown, setOpenUploadDropdown] = useState(null);

  const [postReactions, setPostReactions] = useState({});

  const [replyComments, setReplyComments] = useState({});
  const [replyingTo, setReplyingTo] = useState(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [activePostId, setActivePostId] = useState(null);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [comments, setComments] = useState({});

  const handleReplyInputChange = (e, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [replyId]: textarea.value,
    }));

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = "auto";
    // Set the height to the scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const groupedPosts = Object.values(posts).reduce((acc, postGroup) => {
    postGroup.forEach((post) => {
      if (!post || typeof post !== "object") {
        console.error("Invalid post object:", post);
        return acc;
      }

      if (!post.createdAt && !post.latestActivity) {
        console.warn("Post with invalid timestamps:", post.id);
        return acc;
      }

      if (!acc[post.id]) {
        acc[post.id] = {
          ...post,
          reactions: [],
          reposts: [],
        };
      }

      Object.entries(post.reactions || {}).forEach(([userId, emoji]) => {
        if (
          directConnectionIds.includes(userId) &&
          userId !== currentUser.uid
        ) {
          acc[post.id].reactions.push({ userId, emoji });
        }
      });

      Object.entries(post.reposts || {}).forEach(([userId]) => {
        if (
          directConnectionIds.includes(userId) &&
          userId !== currentUser.uid
        ) {
          acc[post.id].reposts.push({ userId });
        }
      });
    });

    return acc;
  }, {});

  const getValidTimestamp = (post) => {
    if (!post || post.isRemovedMessage) {
      return Date.now(); // Return current timestamp for removed messages
    }

    const getFieldTimestamp = (field) => {
      if (!field) return 0;
      if (typeof field.toMillis === "function") return field.toMillis();
      if (typeof field.seconds === "number") return field.seconds * 1000;
      if (field instanceof Date) return field.getTime();
      return 0;
    };

    const latestActivityTime = getFieldTimestamp(post.latestActivity);
    const createdAtTime = getFieldTimestamp(post.createdAt);

    return Math.max(latestActivityTime, createdAtTime, 0);
  };

  const sortDirectPosts = (posts) => {
    return posts.sort((a, b) => {
      const timeA = getPostSortTime(a);
      const timeB = getPostSortTime(b);
      return timeB - timeA;
    });
  };

  const sortedPosts = Object.values(groupedPosts).sort((a, b) => {
    return getValidTimestamp(b) - getValidTimestamp(a);
  });

  const validSortedPosts = sortedPosts.filter(
    (post) => getValidTimestamp(post) > 0
  );

  const loadMorePosts = useCallback(() => {
    const newPosts = validSortedPosts.slice(page * 10, (page + 1) * 10);
    setVisiblePosts((prevPosts) => [...prevPosts, ...newPosts]);
    setPage((prevPage) => prevPage + 1);
  }, [validSortedPosts, page]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        loadMorePosts();
      }
    },
    [loadMorePosts, hasMore]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleReplyButton = (commentId) => {
    setReplyingTo((prevReplyingTo) => {
      if (prevReplyingTo === commentId) {
        // If the reply input is already open for this comment, close it
        setActiveCommentId(null);
        setIsMobileFocused(false);
        return null;
      } else {
        // Open the reply input for this comment
        setActiveCommentId(commentId);
        setIsMobileFocused(true);

        // Add a slight delay before focusing
        setTimeout(() => {
          if (replyInputRef.current[commentId]) {
            const inputElement = replyInputRef.current[commentId];
            inputElement.focus();
            inputElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });

            if (isIOS) {
              forceKeyboardOpen(inputElement);
            }
          }
        }, 100);

        return commentId;
      }
    });

    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    setShowEditModal(true);
    setPostModalScrollPosition(window.scrollY);
    setIsPostModalOpen(true);
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const makeLinksClickable = (text) => {
    const urlRegex =
      /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part.startsWith("http") ? part : `https://${part}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "orangered", fontWeight: 900 }}
          onClick={(e) => e.stopPropagation()}
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (isMobileFocused) {
      const timer = setTimeout(() => {
        if (replyInputRef.current[activeCommentId]) {
          openMobileKeyboard(replyInputRef.current[activeCommentId]);
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isMobileFocused, activeCommentId]);

  const handleCommentInputChange = (e) => {
    const textarea = e.target;
    setNewComment(textarea.value);

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = "auto";
    // Set the height to the scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const checkDirectInteraction = async (post) => {
    if (!post || !currentUser) return false;

    const directConnections = await fetchDirectConnections(currentUser.uid);

    const hasDirectReaction =
      post.reactions &&
      Object.keys(post.reactions).some((reactorId) =>
        directConnections.includes(reactorId)
      );

    const hasDirectRepost =
      post.reposts &&
      Object.keys(post.reposts).some((reposterId) =>
        directConnections.includes(reposterId)
      );

    const hasDirectComment =
      post.comments &&
      Object.keys(post.comments).some((commenterId) =>
        directConnections.includes(commenterId)
      );

    return hasDirectReaction || hasDirectRepost || hasDirectComment;
  };

  const checkConnectionType = useCallback(async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  }, []);

  const [activeCommentPostId, setActiveCommentPostId] = useState(null);
  const [newComment, setNewComment] = useState("");

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown((prev) => (prev === commentId ? null : commentId));
  };

  const handleDeleteComment = async (postId, commentId) => {
    console.log(
      `Attempting to delete comment: ${commentId} from post: ${postId}`
    );

    if (!currentUser) {
      console.error("No current user");
      return;
    }
    console.log(`Current user ID: ${currentUser.uid}`);

    try {
      const post = findPostById(postId);
      if (!post) {
        console.error(`Post not found for comment: ${commentId}`);
        return;
      }
      console.log(`Post owner ID: ${post.user.id}`);

      const commentsRef = collection(
        firestore,
        `users/${post.user.id}/posts/${post.id}/comments`
      );
      console.log(`Attempting to read comment: ${commentId}`);
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        console.error("Comment does not exist");
        return;
      }
      console.log("Successfully read comment");

      const commentData = commentSnap.data();
      console.log(`Comment owner ID: ${commentData.userId}`);
      const canDelete =
        currentUser.uid === commentData.userId ||
        currentUser.uid === post.user.id;

      if (!canDelete) {
        console.error("User not authorized to delete this comment");
        return;
      }
      console.log("User is authorized to delete this comment");

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();

      const deleteCommentAndReplies = async (commentRef) => {
        console.log(`Deleting comment: ${commentRef.id}`);
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) {
          console.log(`Comment ${commentRef.id} does not exist`);
          return;
        }

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);
        affectedUserIds.add(commentData.userId);

        // Delete notifications
        try {
          if (commentData.userId !== post.user.id) {
            await deleteDoc(
              doc(
                firestore,
                `users/${post.user.id}/notifications/comment_${commentRef.id}`
              )
            );
          }
          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);
            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId !== commentData.userId) {
                await deleteDoc(
                  doc(
                    firestore,
                    `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                  )
                );
              }
            }
          }
        } catch (error) {
          console.error(
            `Error deleting notifications for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete reaction notifications
        try {
          const reactionNotificationsQuery = query(
            collection(firestore, `users/${commentData.userId}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const reactionNotificationsSnapshot = await getDocs(
            reactionNotificationsQuery
          );
          for (const doc of reactionNotificationsSnapshot.docs) {
            await deleteDoc(doc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting reaction notifications for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete replies
        try {
          const repliesQuery = query(
            collection(
              firestore,
              `users/${post.user.id}/posts/${post.id}/comments`
            ),
            where("parentCommentId", "==", commentRef.id)
          );
          const repliesSnapshot = await getDocs(repliesQuery);
          for (const replyDoc of repliesSnapshot.docs) {
            await deleteCommentAndReplies(replyDoc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting replies for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete the comment itself
        try {
          console.log(`Deleting the comment itself: ${commentRef.id}`);
          await deleteDoc(commentRef);
        } catch (error) {
          console.error(`Error deleting comment ${commentRef.id}:`, error);
          throw error;
        }
      };

      // Start the deletion process
      await deleteCommentAndReplies(commentToDeleteRef);

      // Update post document and commentedPosts for all affected users
      const batch = writeBatch(firestore);

      // Update post document
      console.log("Updating post document");
      const postRef = doc(firestore, `users/${post.user.id}/posts/${postId}`);
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      // Update comments map in post document
      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }

      // Update commentedPosts for all affected users
      console.log("Updating commentedPosts for affected users");
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${postId}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Commit all updates
      await batch.commit();

      console.log(
        `Deleted ${deletedCount} comments/replies from ${affectedUserIds.size} users`
      );

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setPosts((prevPosts) => {
        const updatePostInCategory = (category) => {
          return prevPosts[category].map((p) => {
            if (p.id === postId) {
              const updatedPost = {
                ...p,
                commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
              };
              const updatedComments = { ...updatedPost.comments };
              affectedUserIds.forEach((userId) => {
                delete updatedComments[userId];
              });
              updatedPost.comments = updatedComments;
              return updatedPost;
            }
            return p;
          });
        };

        return {
          directAndInteracted: updatePostInCategory("directAndInteracted"),
          indirectSuggested: updatePostInCategory("indirectSuggested"),
          extendedSuggested: updatePostInCategory("extendedSuggested"),
        };
      });

      setOpenCommentDropdown(null);
      console.log("Comment deletion completed successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      console.error("Error details:", error.code, error.message);
      console.error("Error stack:", error.stack);
    }
  };

  const organizeComments = (comments) => {
    const commentMap = {};
    const rootComments = [];

    comments.forEach((comment) => {
      commentMap[comment.id] = { ...comment, replies: [] };
    });

    comments.forEach((comment) => {
      if (comment.parentCommentId) {
        const parentComment = commentMap[comment.parentCommentId];
        if (parentComment) {
          parentComment.replies.push(commentMap[comment.id]);
        } else {
          // If parent comment is not found, treat it as a root comment
          rootComments.push(commentMap[comment.id]);
        }
      } else {
        rootComments.push(commentMap[comment.id]);
      }
    });

    return rootComments;
  };

  // Helper function to calculate total comment count including replies
  const calculateTotalCommentCount = (comments) => {
    return comments.reduce((total, comment) => {
      // Count the comment itself
      let count = 1;
      // If the comment has replies, recursively count them
      if (comment.replies && comment.replies.length > 0) {
        count += calculateTotalCommentCount(comment.replies);
      }
      return total + count;
    }, 0);
  };

  // Add this function near your other handler functions
  const handleCommentEmojiSelect = async (commentId, emoji) => {
    if (commentId && currentUser) {
      try {
        // Find the post that contains this comment
        let post = null;
        let postId = null;

        for (const category of [
          "directAndInteracted",
          "indirectSuggested",
          "extendedSuggested",
        ]) {
          for (const p of posts[category]) {
            if (
              comments[p.id] &&
              comments[p.id].some(
                (c) =>
                  c.id === commentId ||
                  c.replies?.some((r) => r.id === commentId)
              )
            ) {
              post = p;
              postId = p.id;
              break;
            }
          }
          if (post) break;
        }

        if (!post) {
          throw new Error("Post not found");
        }

        let commentRef;
        let commentToUpdate;
        let isReply = false;

        const postComments = comments[postId] || [];
        for (const comment of postComments) {
          if (comment.id === commentId) {
            commentRef = doc(
              firestore,
              `users/${post.user.id}/posts/${postId}/comments/${commentId}`
            );
            commentToUpdate = comment;
            break;
          }
          if (comment.replies) {
            const reply = comment.replies.find((r) => r.id === commentId);
            if (reply) {
              commentRef = doc(
                firestore,
                `users/${post.user.id}/posts/${postId}/comments/${comment.id}/replies/${commentId}`
              );
              commentToUpdate = reply;
              isReply = true;
              break;
            }
          }
        }

        if (!commentRef || !commentToUpdate) {
          throw new Error("Comment not found");
        }

        let updatedReactions = { ...(commentToUpdate.reactions || {}) };

        if (updatedReactions[currentUser.uid] === emoji) {
          delete updatedReactions[currentUser.uid];

          // Delete the reaction notification
          const notificationRef = doc(
            firestore,
            `users/${commentToUpdate.userId}/notifications`,
            `comment_reaction_${commentId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);
        } else {
          updatedReactions[currentUser.uid] = emoji;

          // Create or update notification for comment owner
          if (commentToUpdate.userId !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              `users/${commentToUpdate.userId}/notifications`,
              `comment_reaction_${commentId}_${currentUser.uid}`
            );

            await setDoc(notificationRef, {
              type: "comment_reaction",
              postId: postId,
              commentId: commentId,
              reactorId: currentUser.uid,
              reactorName: `${userData.firstName} ${userData.lastName}`,
              reactorImage: userData.profileImage || profileImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              commentText: commentToUpdate.text,
              isReply: isReply,
              postPreview: {
                text: post.text || "",
                mediaType: post.content?.type || null,
                mediaUrl: post.content?.url || null,
                fileName: post.content?.fileName || null,
              },
            });
          }
        }

        await updateDoc(commentRef, { reactions: updatedReactions });

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: updateCommentsState(
            prevComments[postId],
            commentId,
            updatedReactions
          ),
        }));
        setActiveEmojiPicker(null);
        setTimeout(() => {
          window.scrollTo(0, commentEmojiPickerScrollPosition);
        }, 0);
      } catch (error) {
        console.error("Error updating comment/reply reaction:", error);
        if (error.message === "Post not found") {
          setErrorPopup({
            message: "The post containing this comment has been deleted.",
            isError: true,
          });
        } else if (error.message === "Comment not found") {
          setErrorPopup({
            message: "This comment has been deleted.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: "An error occurred while updating the reaction.",
            isError: true,
          });
        }
      }
    }
  };

  const updateCommentVisibility = async (postId, commenterId, commentData) => {
    const postRef = doc(
      firestore,
      `users/${commentData.originalPosterId}/posts/${postId}`
    );
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const postData = postDoc.data();
      const currentUserConnectionType = await checkConnectionType(
        currentUser.uid,
        postData.userId
      );
      const commenterConnectionType = await checkConnectionType(
        currentUser.uid,
        commenterId
      );

      if (
        (currentUserConnectionType === "Indirect" ||
          currentUserConnectionType === "Extended") &&
        commenterConnectionType === "Direct"
      ) {
        await updateDoc(postRef, {
          visibleCommentedBy: {
            id: commenterId,
            firstName: commentData.firstName,
            lastName: commentData.lastName,
            commentedAt: commentData.commentedAt,
            commentText: commentData.commentText,
          },
          originalDirectCommentTime:
            postData.originalDirectCommentTime || commentData.commentedAt,
        });
      }
    }
  };

  // Helper function to update the comments state
  const updateCommentsState = (comments, commentId, updatedReactions) => {
    return comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, reactions: updatedReactions };
      }
      if (comment.replies) {
        const updatedReplies = comment.replies.map((reply) =>
          reply.id === commentId
            ? { ...reply, reactions: updatedReactions }
            : reply
        );
        return { ...comment, replies: updatedReplies };
      }
      return comment;
    });
  };

  const fetchComments = useCallback(
    async (postId, postUserId) => {
      if (!postId || !postUserId || !currentUser) {
        console.log("Missing data for fetching comments", {
          postId,
          postUserId,
          currentUser,
        });
        return;
      }

      const commentsRef = collection(
        firestore,
        `users/${postUserId}/posts/${postId}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));

      try {
        const snapshot = await getDocs(q);
        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            try {
              const commentData = docSnapshot.data();
              const userDocRef = doc(firestore, "users", commentData.userId);
              const userDocSnapshot = await getDoc(userDocRef);
              const userData = userDocSnapshot.data();
              let connectionType = "Unknown";
              try {
                connectionType = await checkConnectionType(
                  currentUser.uid,
                  commentData.userId
                );
              } catch (error) {
                console.error("Error checking connection type:", error);
              }
              return {
                id: docSnapshot.id,
                ...commentData,
                userName: `${userData.firstName} ${userData.lastName}`,
                userImage: userData.profileImage || profileImage,
                connectionType,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                postId,
                postUserId,
                rootCommentId: commentData.rootCommentId,
                isRootComment: commentData.isRootComment,
              };
            } catch (error) {
              console.error("Error processing comment:", error);
              return null;
            }
          })
        );

        // const validComments = fetchedComments.filter(
        //   (comment) => comment !== null
        // );

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: fetchedComments.map((comment) => ({ ...comment, postId })),
        }));

        setVisibleComments((prev) => ({
          ...prev,
          [postId]: 10,
        }));

        setPosts((prevPosts) => {
          const updateCategory = (category) => {
            return prevPosts[category].map((post) =>
              post.id === postId
                ? { ...post, commentCount: fetchedComments.length }
                : post
            );
          };

          return {
            directAndInteracted: updateCategory("directAndInteracted"),
            indirectSuggested: updateCategory("indirectSuggested"),
            extendedSuggested: updateCategory("extendedSuggested"),
          };
        });
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },
    [currentUser, checkConnectionType, firestore, profileImage]
  );

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const fetchIndirectConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Indirect"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  const fetchExtendedConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Extended"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  const fetchIndirectAndExtendedConnections = async (userId) => {
    const directConnections = await fetchDirectConnections(userId);
    const allUsers = await fetchAllUsers();

    const indirectConnections = new Set();
    const extendedConnections = new Set();

    for (const user of allUsers) {
      if (user.id === userId || directConnections.includes(user.id)) continue;

      let isIndirect = false;
      for (const directConnectionId of directConnections) {
        const isConnected = await checkIfConnected(directConnectionId, user.id);
        if (isConnected) {
          isIndirect = true;
          break;
        }
      }

      if (isIndirect) {
        indirectConnections.add(user.id);
      } else {
        extendedConnections.add(user.id);
      }
    }

    return {
      indirectConnections: Array.from(indirectConnections),
      extendedConnections: Array.from(extendedConnections),
    };
  };

  const fetchAllUsers = async () => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    return usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const checkIfConnected = async (userId1, userId2) => {
    const connectionRef = doc(
      firestore,
      `users/${userId1}/connections/${userId2}`
    );
    const connectionDoc = await getDoc(connectionRef);
    return (
      connectionDoc.exists() && connectionDoc.data().status === "Connected"
    );
  };

  const fetchAndSubscribeToPosts = async (loadMore = false) => {
    if (currentUser && !loading && hasMore) {
      setLoading(true);

      try {
        const directConnections = await fetchDirectConnections(currentUser.uid);
        let allPosts = [];

        // Fetch posts for current user and direct connections
        const fetchPosts = async (fetchFunction, connectionId = null) => {
          const posts = await fetchFunction(connectionId || currentUser.uid);
          allPosts = [...allPosts, ...posts];
        };

        await fetchPosts(fetchUserPosts);
        for (const connectionId of directConnections) {
          await fetchPosts(fetchUserPosts, connectionId);
          await fetchPosts(fetchReposts, connectionId);
          await fetchPosts(fetchCommentedPosts, connectionId);
          await fetchPosts(fetchReactedPosts, connectionId);
        }

        // Fetch indirect and extended connections
        const { indirectConnections, extendedConnections } =
          await fetchIndirectAndExtendedConnections(currentUser.uid);

        // Fetch posts for indirect and extended connections
        for (const connectionId of [
          ...indirectConnections,
          ...extendedConnections,
        ]) {
          const posts = await fetchUserPosts(connectionId);
          allPosts = [...allPosts, ...posts];
        }

        // Remove duplicates
        allPosts = Array.from(
          new Set(allPosts.filter(Boolean).map((post) => post.id))
        )
          .map((id) => allPosts.find((post) => post && post.id === id))
          .filter(Boolean);

        const processedPosts = await processPosts(allPosts);

        setPosts(processedPosts);

        setLastVisible(
          processedPosts.directAndInteracted[
            processedPosts.directAndInteracted.length - 1
          ]?.createdAt
        );
        setHasMore(allPosts.length === 20);

        await cachePosts(allPosts);
      } catch (error) {
        console.error("Error in fetchAndSubscribeToPosts:", error);
      } finally {
        setLoading(false);
        setHasAttemptedLoad(true); // Set this flag to true after attempt
      }
    }
  };

  const debouncedFetchAndSubscribeToPosts = debounce(
    fetchAndSubscribeToPosts,
    300
  );

  useEffect(() => {
    if (currentUser && !loading && hasMore) {
      fetchAndSubscribeToPosts();
    }
  }, [currentUser, loading, hasMore]);

  const dbPromise = openDB("postsDB", 1, {
    upgrade(db) {
      db.createObjectStore("posts");
    },
  });

  async function cachePosts(posts) {
    const db = await dbPromise;
    const tx = db.transaction("posts", "readwrite");
    posts.forEach((post) => tx.store.put(post, post.id));
    await tx.done;
  }

  async function getCachedPosts() {
    const db = await dbPromise;
    return db.getAll("posts");
  }

  useEffect(() => {
    console.log("Posts in useEffect:", posts);
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        console.log("Checking post:", post);
        if (
          post &&
          typeof post === "object" &&
          "id" in post &&
          !post.isRemovedMessage &&
          !comments[post.id]
        ) {
          console.log("Fetching comments for post:", post.id);
          fetchComments(post.id, post.user?.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, comments]);

  const itemCount = hasMore
    ? validSortedPosts.length + 1
    : validSortedPosts.length;

  const isItemLoaded = (index) => !hasMore || index < validSortedPosts.length;

  const loadMoreItems = loading ? () => {} : loadMorePosts;

  const renderComments = (postId, postOwnerId) => {
    const postComments = comments[postId] || [];

    // Ensure postComments is an array
    const commentsArray = Array.isArray(postComments)
      ? postComments
      : Object.values(postComments);

    const rootComments = commentsArray.filter(
      (comment) => comment && comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = commentsArray.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        •{" "}
                        {comment.connectionType ||
                          (comment.userId === currentUser.uid
                            ? "You"
                            : "Unknown")}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {comment.userId === currentUser.uid && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(postId, comment.id)}
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}
                      {currentUser.uid !== postOwnerId &&
                        comment.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className={
                                "w-6 h-6 text-gray-800 dark:text-white"
                              }
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {currentUser.uid === postOwnerId &&
                        comment.userId !== currentUser.uid && (
                          <button
                            className={classes.reportUserCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className={
                                "w-6 h-6 text-gray-800 dark:text-white"
                              }
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {(currentUser.uid === postOwnerId ||
                        comment.userId === currentUser.uid) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === `comment_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {renderReplies(postId, comment.id, postOwnerId)}

              {activeCommentId === comment.id && (
                <div className={classes.replyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={userImage || defaultImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => navigate("/profile")}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[comment.id] = el)}
                      value={replyComments[comment.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, comment.id)}
                      onTouchStart={handleTouchStart}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>

                  <div
                    onClick={() =>
                      replyComments[comment.id]?.trim() &&
                      handleAddComment(comment.postId, comment.id)
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[comment.id]?.trim() ? classes.disabled : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const findPostById = useCallback(
    (postId) => {
      for (const category of [
        "directAndInteracted",
        "indirectSuggested",
        "extendedSuggested",
      ]) {
        const post = posts[category].find((p) => p && p.id === postId);
        if (post) return post;
      }
      return null;
    },
    [posts]
  );

  const processPosts = async (allPosts) => {
    const directAndInteracted = [];
    const indirectSuggested = [];
    const extendedSuggested = [];
    const processedIds = new Set();

    for (const post of allPosts) {
      if (processedIds.has(post.id)) continue;

      const connectionType = await checkConnectionType(
        currentUser.uid,
        post.user.id
      );
      const hasDirectInteraction = await checkDirectInteraction(post);

      if (
        connectionType === "Direct" ||
        connectionType === "You" ||
        hasDirectInteraction
      ) {
        directAndInteracted.push(post);
      } else if (connectionType === "Indirect") {
        indirectSuggested.push(post);
      } else if (connectionType === "Extended") {
        extendedSuggested.push(post);
      }

      processedIds.add(post.id);
    }

    return {
      directAndInteracted: sortDirectPosts(directAndInteracted),
      indirectSuggested: sortByEngagement(indirectSuggested),
      extendedSuggested: sortByEngagement(extendedSuggested),
    };
  };

  const sortByEngagement = (posts) => {
    return posts.sort((a, b) => {
      const engagementA = calculateEngagement(a);
      const engagementB = calculateEngagement(b);
      return engagementB - engagementA;
    });
  };

  const calculateEngagement = (post) => {
    const reactionCount = Object.keys(post.reactions || {}).length;
    const commentCount = post.commentCount || 0;
    const repostCount = Object.keys(post.reposts || {}).length;
    return reactionCount + commentCount + repostCount;
  };

  const handleAddComment = async (postId, parentCommentId = null) => {
    let commentText;
    if (parentCommentId) {
      commentText = replyComments[parentCommentId] || "";
    } else {
      commentText = newComment;
    }

    if (!commentText.trim()) return;

    const processedCommentText = convertUrlsToLinks(commentText);

    try {
      const post = findPostById(postId);
      if (!post) throw new Error("Post not found");

      const postRef = doc(firestore, `users/${post.user.id}/posts/${postId}`);
      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      const rootCommentId = parentCommentId
        ? await getRootCommentId(post.user.id, postId, parentCommentId)
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: currentUser.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        userImage: userData.profileImage || profileImage,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: userData.bio || "",
        connectionType: "You",
        link: userData.link || null,
        linkText: userData.linkText || null,
        postUserId: post.user.id,
      };

      const commentsRef = collection(
        firestore,
        `users/${post.user.id}/posts/${postId}/comments`
      );
      const newCommentRef = await addDoc(commentsRef, commentData);

      // Update the comment with the server timestamp
      await updateDoc(newCommentRef, { createdAt: serverTimestamp() });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${currentUser.uid}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId,
        postUserId: post.user.id,
      };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [...(prevComments[postId] || []), newCommentObject],
      }));

      setPosts((prevPosts) => {
        const updatePostInCategory = (category) => {
          return prevPosts[category].map((p) =>
            p.id === postId
              ? {
                  ...p,
                  commentCount: (p.commentCount || 0) + 1,
                  comments: {
                    ...(p.comments || {}),
                    [currentUser.uid]: new Date(),
                  },
                  originalDirectCommentTime: new Date(),
                }
              : p
          );
        };

        return {
          directAndInteracted: updatePostInCategory("directAndInteracted"),
          indirectSuggested: updatePostInCategory("indirectSuggested"),
          extendedSuggested: updatePostInCategory("extendedSuggested"),
        };
      });

      if (parentCommentId) {
        setReplyComments((prev) => ({ ...prev, [parentCommentId]: "" }));
      } else {
        setNewComment("");
        if (commentInputRef.current) {
          commentInputRef.current.style.height = "auto";
        }
      }
      setReplyingTo(null);
      setActiveCommentId(null);

      const commentedPostRef = doc(
        firestore,
        `users/${currentUser.uid}/commentedPosts/${postId}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        firstName: userData.firstName,
        lastName: userData.lastName,
        originalPosterId: post.user.id,
      };

      if (commentedPostDoc.exists()) {
        const existingData = commentedPostDoc.data();
        await updateDoc(commentedPostRef, {
          comments: {
            ...(existingData.comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      if (post.user.id !== currentUser.uid && !parentCommentId) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );
        batch.set(postOwnerNotificationRef, {
          type: "comment",
          postId: postId,
          commentId: newCommentRef.id,
          commenterId: currentUser.uid,
          commenterName: `${userData.firstName} ${userData.lastName}`,
          commenterImage: userData.profileImage || profileImage,
          commentText: commentText,
          createdAt: now,
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
        });
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        if (parentCommentData && parentCommentData.userId !== currentUser.uid) {
          const replyNotificationRef = doc(
            firestore,
            `users/${parentCommentData.userId}/notifications`,
            `reply_${newCommentRef.id}`
          );
          batch.set(replyNotificationRef, {
            type: "reply",
            postId: postId,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: currentUser.uid,
            replierName: `${userData.firstName} ${userData.lastName}`,
            replierImage: userData.profileImage || profileImage,
            replyText: commentText,
            createdAt: now,
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
          });
        }
      }

      await batch.commit();

      await updateCommentVisibility(postId, currentUser.uid, newCommentData);

      if (post.user.id !== currentUser.uid) {
        const connectionType = await checkConnectionType(
          currentUser.uid,
          post.user.id
        );
        if (connectionType === "Indirect" || connectionType === "Extended") {
          const now = serverTimestamp();
          await updateDoc(postRef, {
            originalDirectCommentTime:
              postData.originalDirectCommentTime || now,
          });
        }
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while adding the comment.",
          isError: true,
        });
      }
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderReplies = (postId, rootCommentId, postOwnerId) => {
    const postComments = comments[postId] || [];
    const replies = postComments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || defaultImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        •{" "}
                        {reply.connectionType ||
                          (reply.userId === currentUser.uid
                            ? "You"
                            : "Unknown")}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {(currentUser.uid === postOwnerId ||
                        reply.userId === currentUser.uid) && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() =>
                            handleEditComment(reply.postId, reply.id, true)
                          }
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== postOwnerId &&
                        reply.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className={
                                "w-6 h-6 text-gray-800 dark:text-white"
                              }
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {currentUser.uid === postOwnerId &&
                        reply.userId !== currentUser.uid && (
                          <button
                            className={classes.reportUserCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className={
                                "w-6 h-6 text-gray-800 dark:text-white"
                              }
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {reply.userId === currentUser.uid && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(reply.postId, reply.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleReplyReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}

              {activeEmojiPicker === `reply_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={userImage || defaultImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => navigate("/profile")}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[reply.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, reply.id)}
                      onTouchStart={handleTouchStart}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>

                  <div
                    onClick={() =>
                      replyComments[reply.id]?.trim() &&
                      handleAddComment(reply.postId, reply.id)
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[reply.id]?.trim() ? classes.disabled : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      const commentRef = doc(
        firestore,
        `users/${postUserId}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment) {
        return commentId;
      } else {
        return commentData.rootCommentId;
      }
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      setErrorPopup({
        message: "This comment has been deleted.",
        isError: true,
      });
      throw error;
    }
  };

  const toggleCommentInput = useCallback(
    (postId) => {
      setActiveCommentPostId((prevId) => (prevId === postId ? null : postId));
      if (!comments[postId]) {
        const post = findPostById(postId);
        if (post) {
          fetchComments(postId, post.user.id);
        }
      }
    },
    [comments, findPostById, fetchComments]
  );

  const handleCommentsCountClick = (postId) => {
    toggleCommentInput(postId);
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: 10, // Show initial 10 comments
    }));
  };

  const CancelPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const SuccessPostPopup = ({ message, onClose, postId }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          <Link to={`/post/${postId}`} className={classes.viewPostLink}>
            View post
          </Link>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleNavigate = async (userId) => {
    if (userId === currentUser.uid) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const handleReactionClick = async (postId) => {
    try {
      const post = findPostById(postId);

      if (!post) {
        throw new Error("Post not found");
      }

      if (post.reactions && post.reactions[currentUser.uid]) {
        // User has already reacted, so remove the reaction
        try {
          const postRef = doc(
            firestore,
            "users",
            post.user.id,
            "posts",
            postId
          );
          await updateDoc(postRef, {
            [`reactions.${currentUser.uid}`]: deleteField(),
          });

          // Remove from reactedPosts
          const reactedPostRef = doc(
            firestore,
            "users",
            currentUser.uid,
            "reactedPosts",
            postId
          );
          await deleteDoc(reactedPostRef);

          // Remove notification
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);

          // Remove reaction
          const updatedReactions = { ...post.reactions };
          delete updatedReactions[currentUser.uid];

          setPosts((prevPosts) => {
            const updateCategory = (category) =>
              prevPosts[category].map((p) =>
                p.id === postId
                  ? {
                      ...p,
                      reactions: updatedReactions,
                      originalDirectReactionTime:
                        Object.keys(updatedReactions).length === 0
                          ? null
                          : p.originalDirectReactionTime,
                    }
                  : p
              );

            return {
              directAndInteracted: updateCategory("directAndInteracted"),
              indirectSuggested: updateCategory("indirectSuggested"),
              extendedSuggested: updateCategory("extendedSuggested"),
            };
          });
        } catch (error) {
          console.error("Error removing reaction:", error);
          throw error;
        }
      } else {
        // Toggle EmojiPicker
        setEmojiPickerScrollPosition(window.scrollY);
        setMainEmojiPickerOpen((prev) =>
          prev === `post_${postId}` ? null : `post_${postId}`
        );
      }
    } catch (error) {
      console.error("Error in handleReactionClick:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const handleCommentReactionClick = (commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `comment_${commentId}` ? null : `comment_${commentId}`
    );
  };

  const handleReplyReactionClick = (replyId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `reply_${replyId}` ? null : `reply_${replyId}`
    );
  };

  const fetchAllConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(connectionsRef);

    const connections = {
      direct: [],
      indirect: [],
      extended: [],
    };

    for (const doc of connectionsSnapshot.docs) {
      const connectionData = doc.data();
      if (connectionData.status === "Connected") {
        connections.direct.push(doc.id);
      } else if (connectionData.status === "Indirect") {
        connections.indirect.push(doc.id);
      } else if (connectionData.status === "Extended") {
        connections.extended.push(doc.id);
      }
    }

    return connections;
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        setUserData(userDoc.data());

        const connectionsRef = collection(
          firestore,
          "users",
          userId,
          "connections"
        );
        const connectionsSnapshot = await getDocs(
          query(connectionsRef, where("status", "==", "Connected"))
        );
        setDirectConnectionIds(connectionsSnapshot.docs.map((doc) => doc.id));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleReportPost = (postId) => {
    // Implement report post functionality
    console.log(`Reporting post with ID: ${postId}`);
    // You might want to open a modal or make an API call here
    // For now, we'll just log to the console
  };

  const hasInteractions = (post) => {
    if (!post) return false;
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0) ||
      (comments[post.id] && comments[post.id].length > 0)
    );
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const handleEmojiSelect = async (postId, emoji) => {
    if (postId && currentUser) {
      try {
        const post = findPostById(postId);
        if (!post || !post.user || !post.user.id) {
          throw new Error("Post not found");
        }

        const postRef = doc(firestore, "users", post.user.id, "posts", postId);
        const reactedPostRef = doc(
          firestore,
          "users",
          currentUser.uid,
          "reactedPosts",
          postId
        );

        let updatedReactions = { ...(post.reactions || {}) };
        const now = serverTimestamp();

        if (updatedReactions[currentUser.uid] === emoji) {
          // Remove reaction
          await updateDoc(postRef, {
            [`reactions.${currentUser.uid}`]: deleteField(),
          });
          delete updatedReactions[currentUser.uid];
          await deleteDoc(reactedPostRef);

          // Remove notification
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);
        } else {
          // Add or change reaction
          await updateDoc(postRef, {
            [`reactions.${currentUser.uid}`]: emoji,
          });
          updatedReactions[currentUser.uid] = emoji;

          await setDoc(
            reactedPostRef,
            {
              originalPosterId: post.user.id,
              postId: postId,
              reactedAt: now,
              emoji: emoji,
              originalDirectReactionTime:
                post.originalDirectReactionTime || now,
            },
            { merge: true }
          );

          if (!post.originalDirectReactionTime) {
            await updateDoc(postRef, { originalDirectReactionTime: now });
          }

          if (post.user.id !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              "users",
              post.user.id,
              "notifications",
              `reaction_${postId}_${currentUser.uid}`
            );

            const totalReactions = Object.keys(updatedReactions).length;

            await setDoc(notificationRef, {
              type: "reaction",
              postId: postId,
              reactorId: currentUser.uid,
              reactorName: `${userData.firstName} ${userData.lastName}`,
              reactorImage: userData.profileImage || profileImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              postPreview: {
                text: post.text || "",
                mediaType: post.content?.type || null,
                mediaUrl: post.content?.url || null,
                fileName: post.content?.fileName || null,
                reactionCount: totalReactions,
              },
            });
          }
        }

        setPosts((prevPosts) => {
          const updateCategory = (category) =>
            prevPosts[category].map((p) =>
              p.id === postId
                ? {
                    ...p,
                    reactions: updatedReactions,
                    originalDirectReactionTime:
                      p.user.connectionType === "You" ||
                      p.user.connectionType === "Direct"
                        ? p.originalDirectReactionTime
                        : Object.keys(updatedReactions).length > 0
                        ? now
                        : null,
                  }
                : p
            );

          return {
            directAndInteracted: updateCategory("directAndInteracted"),
            indirectSuggested: updateCategory("indirectSuggested"),
            extendedSuggested: updateCategory("extendedSuggested"),
          };
        });

        setMainEmojiPickerOpen(null);
        setTimeout(() => {
          window.scrollTo(0, emojiPickerScrollPosition);
        }, 0);
      } catch (error) {
        console.error("Error in handleEmojiSelect:", error);
        setErrorPopup({
          message:
            error.message === "Post not found"
              ? "This post has been deleted."
              : "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  useEffect(() => {
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        if (!comments[post.id]) {
          fetchComments(post.id, post.user.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, comments]);

  const sortPosts = (posts) => {
    return posts.sort((a, b) => {
      if (
        a.user.connectionType === "You" ||
        a.user.connectionType === "Direct"
      ) {
        return getValidTimestamp(b) - getValidTimestamp(a);
      } else {
        const aTime = seenPosts[a.id]?.firstSeenAt || getValidTimestamp(a);
        const bTime = seenPosts[b.id]?.firstSeenAt || getValidTimestamp(b);
        if (aTime === bTime) {
          return (
            (seenPosts[a.id]?.originalPosition || 0) -
            (seenPosts[b.id]?.originalPosition || 0)
          );
        }
        return bTime - aTime;
      }
    });
  };

  const debouncedSetPosts = useCallback(
    debounce((updateFunction) => {
      setPosts((prevPosts) => {
        const updatedPosts = updateFunction(prevPosts);
        console.log("Setting new posts:", updatedPosts);
        return Array.isArray(updatedPosts)
          ? updatedPosts.filter(
              (post) => post && typeof post === "object" && "id" in post
            )
          : prevPosts; // Return previous posts if update resulted in non-array
      });
    }, 100),
    []
  );

  const getPostSortTime = (post) => {
    if (!post || post.isRemovedMessage || !post.user) {
      console.log("Invalid post in getPostSortTime:", post);
      return 0;
    }
    const connectionType = post.user.connectionType;
    if (connectionType === "You" || connectionType === "Direct") {
      return getValidTimestamp(post);
    } else {
      const reactionTime = getValidTimestamp({
        createdAt: post.originalDirectReactionTime,
      });
      const repostTime = getValidTimestamp({
        createdAt: post.originalDirectRepostTime,
      });
      const commentTime = getValidTimestamp({
        createdAt: post.originalDirectCommentTime,
      });
      return Math.max(
        reactionTime,
        repostTime,
        commentTime,
        getValidTimestamp(post)
      );
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const videoRefs = useRef({});
  const [videoVolumes, setVideoVolumes] = useState({});

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  const toggleVolume = (postId) => {
    setVideoVolumes((prev) => {
      const newVolumes = { ...prev };
      newVolumes[postId] = !prev[postId];
      return newVolumes;
    });

    const video = videoRefs.current[postId];
    if (video) {
      video.muted = !video.muted;
    }
  };

  const toggleDropdown = (postId) => {
    setShowDropdown((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handleCloseReactionsModal = () => {
    setShowReactionsModal(false);
    setIsReactionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, reactionsModalScrollPosition);
    }, 0);
  };

  const handleCloseRepostsModal = () => {
    setShowRepostsModal(false);
    setIsRepostsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, repostsModalScrollPosition);
    }, 0);
  };

  const fetchNewPost = async (postId) => {
    try {
      const postDoc = await getDoc(
        doc(firestore, `users/${currentUser.uid}/posts/${postId}`)
      );
      if (postDoc.exists()) {
        const newPost = await createPostObject(
          currentUser.uid,
          postId,
          postDoc.data()
        );
        setPosts((prevPosts) => {
          const updateCategory = (category) => [
            newPost,
            ...prevPosts[category],
          ];

          return {
            directAndInteracted: updateCategory("directAndInteracted"),
            indirectSuggested: prevPosts.indirectSuggested,
            extendedSuggested: prevPosts.extendedSuggested,
          };
        });

        setSuccessPostMessage({
          text: "Post successful.",
          postId: postId,
        });
      }
    } catch (error) {
      console.error("Error fetching new post:", error);
    }
  };

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClosePostModal = useCallback(
    (success, newPostId, isUploading, progress) => {
      setShowPostModal(false);
      setIsPostModalOpen(false);
      setSelectedFile(null);

      if (success && newPostId) {
        if (isUploading) {
          updateUploadProgress(newPostId, progress, "uploading");
        } else {
          removeUploadingPost(newPostId);
          fetchNewPost(newPostId);
        }
      }
    },
    [updateUploadProgress, removeUploadingPost, fetchNewPost]
  );

  const handleCancelUpload = useCallback(
    async (postId) => {
      console.log(`Cancelling upload for post ${postId}`);
      cancelUpload(postId);
      updateUploadProgress(postId, 0, "cancelling");

      try {
        // Delete from Firestore
        const postRef = doc(
          firestore,
          `users/${currentUser?.uid}/posts/${postId}`
        );
        await deleteDoc(postRef);

        // Delete from Storage
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${currentUser?.uid}/${postId}`
        );
        const items = await listAll(storageRef);
        await Promise.all(items.items.map((itemRef) => deleteObject(itemRef)));

        console.log(`Successfully cancelled and cleaned up post ${postId}`);

        // Set the cancel message
        setCancelMessage("Post cancelled.");
      } catch (error) {
        console.error(`Error cleaning up cancelled post ${postId}:`, error);
      } finally {
        removeUploadingPost(postId);
      }
    },
    [currentUser, cancelUpload, updateUploadProgress, removeUploadingPost]
  );

  const handlePostComplete = useCallback(
    (postId) => {
      removeUploadingPost(postId);
      // Fetch and add the new post to the beginning of the posts list
      fetchNewPost(postId);
    },
    [removeUploadingPost, fetchNewPost]
  );

  const UploadingPost = React.memo(
    ({ postId, progress, status, onCancelUpload }) => {
      const handleCancelUpload = useCallback(() => {
        onCancelUpload(postId);
      }, [onCancelUpload, postId]);

      const roundedProgress = Math.round(progress);

      if (status === "cancelling") {
        return null; // Don't render anything if cancelling
      }

      return (
        <div className={classes.uploadingPost}>
          <div className={classes.uploadingHeader}>
            <span>Posting...</span>
            <div onClick={handleCancelUpload} className={classes.cancelButton}>
              Cancel
            </div>
          </div>
          <div className={classes.barAndText}>
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFill}
                style={{ width: `${roundedProgress}%` }}
              ></div>
            </div>
            <div className={classes.progressText}>{roundedProgress}%</div>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.status === nextProps.status &&
        Math.floor(prevProps.progress) === Math.floor(nextProps.progress) &&
        prevProps.postId === nextProps.postId
      );
    }
  );

  UploadingPost.displayName = "UploadingPost";

  const onCancelUpload = useCallback(
    (postId) => {
      handleCancelUpload(postId);
    },
    [handleCancelUpload]
  );

  // Memoize the UploadingPost component
  const MemoizedUploadingPost = useMemo(() => React.memo(UploadingPost), []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (currentUser && !loading && hasMore) {
        setLoading(true);
        try {
          const fetchedPosts = await fetchAndSubscribeToPosts();
          if (isMounted) {
            setPosts(fetchedPosts);
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [currentUser, fetchAndSubscribeToPosts, loading, hasMore]);

  const setRowHeight = useCallback((index, size) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
  }, []);

  const PostActionPopup = ({ message, onClose, isDelete, isSuccess }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill={isSuccess ? "orangered" : "red"}
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d={
                isSuccess
                  ? "M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  : "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              }
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleDeletePost = async (postId) => {
    console.log(`Attempting to delete post: ${postId}`);

    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const postRef = doc(firestore, "users", currentUser.uid, "posts", postId);
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        console.error("Post not found");
        return;
      }

      const postData = postSnap.data();

      // Start a new batch
      let batch = writeBatch(firestore);
      let operationCount = 0;

      // Helper function to commit batch and start a new one if needed
      const commitBatchIfNeeded = async () => {
        if (operationCount >= 500) {
          // Firestore limit is 500 operations per batch
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      // Helper function to recursively delete comments, replies, and their notifications
      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnap = await getDoc(commentRef);
        if (!commentSnap.exists()) return;

        const commentData = commentSnap.data();

        // Delete comment
        batch.delete(commentRef);
        operationCount++;

        // Delete comment notification
        if (commentData.userId !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${currentUser.uid}/notifications/comment_${commentRef.id}`
          );
          batch.delete(notificationRef);
          operationCount++;
        }

        // Delete reaction notifications for this comment
        const reactionNotificationsQuery = query(
          collection(firestore, `users/${commentData.userId}/notifications`),
          where("type", "==", "comment_reaction"),
          where("commentId", "==", commentRef.id)
        );
        const reactionNotificationsSnapshot = await getDocs(
          reactionNotificationsQuery
        );
        reactionNotificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
          operationCount++;
        });

        // Delete reply notification
        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentRef.parent,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (parentCommentData.userId !== commentData.userId) {
              const replyNotificationRef = doc(
                firestore,
                `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
              );
              batch.delete(replyNotificationRef);
              operationCount++;
            }
          }
        }

        await commitBatchIfNeeded();

        // Recursively delete replies
        const repliesQuery = query(
          collection(commentRef.parent, commentRef.id, "replies")
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        for (const replyDoc of repliesSnapshot.docs) {
          await deleteCommentsAndReplies(replyDoc.ref);
        }
      };

      // Delete comments, replies, and their notifications
      const commentsRef = collection(
        firestore,
        `users/${currentUser.uid}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);
      for (const commentDoc of commentsSnapshot.docs) {
        await deleteCommentsAndReplies(commentDoc.ref);
      }

      // Delete reactions and their notifications
      if (postData.reactions) {
        for (const [userId, reaction] of Object.entries(postData.reactions)) {
          // Delete reactedPosts entry
          const reactedPostRef = doc(
            firestore,
            `users/${userId}/reactedPosts/${postId}`
          );
          batch.delete(reactedPostRef);
          operationCount++;

          // Delete reaction notification
          if (userId !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              `users/${currentUser.uid}/notifications/reaction_${postId}_${userId}`
            );
            batch.delete(notificationRef);
            operationCount++;
          }

          await commitBatchIfNeeded();
        }
      }

      // Delete reposts and their notifications
      if (postData.reposts) {
        for (const [userId, repostTime] of Object.entries(postData.reposts)) {
          // Delete repost entry
          const repostRef = doc(firestore, `users/${userId}/reposts/${postId}`);
          batch.delete(repostRef);
          operationCount++;

          // Delete repost notification
          if (userId !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              `users/${currentUser.uid}/notifications/repost_${postId}_${userId}`
            );
            batch.delete(notificationRef);
            operationCount++;
          }

          await commitBatchIfNeeded();
        }
      }

      // Delete commentedPosts entries
      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${postId}`
          );
          batch.delete(commentedPostRef);
          operationCount++;

          await commitBatchIfNeeded();
        }
      }

      // Delete associated files from Firebase Storage
      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${currentUser.uid}/${postId}`
        );

        if (
          postData.content.type === "image" &&
          Array.isArray(postData.content.url)
        ) {
          // Multiple images
          for (const imageUrl of postData.content.url) {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          }
        } else if (
          postData.content.type === "image" ||
          postData.content.type === "video" ||
          postData.content.type === "pdf"
        ) {
          // Single image, video, or PDF
          const fileRef = ref(storage, postData.content.url);
          await deleteObject(fileRef);
        }

        // Delete the entire folder for the post
        const folderContents = await listAll(storageRef);
        await Promise.all(
          folderContents.items.map((item) => deleteObject(item))
        );
      }

      // Delete the post itself
      batch.delete(postRef);
      operationCount++;

      // Commit the final batch
      await batch.commit();

      console.log(
        `Successfully deleted post ${postId} and all associated data`
      );

      // Update local state
      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].filter((p) => p.id !== postId);

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setOpenDropdown(null);
      setOpenUploadDropdown(null);

      setDeleteMessage({
        text: "Post deleted.",
        postId,
        isSuccess: true,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setDeleteMessage({
        text: "Failed to delete post.",
        postId,
        isSuccess: false,
      });
    }
  };

  useEffect(() => {
    uploadingPostsRef.current = state.uploadingPosts;
  }, [state.uploadingPosts]);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      togglePostModal();
      event.target.value = "";
    }
  };

  const fileInputRef = useRef(null);

  const handleAddPost = () => {
    Object.values(deleteTimeouts).forEach(clearTimeout);
    setDeleteTimeouts({});
    togglePostModal();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());

          const connectionsRef = collection(
            firestore,
            "users",
            userId,
            "connections"
          );
          const connectionsSnapshot = await getDocs(
            query(connectionsRef, where("status", "==", "Connected"))
          );
          setDirectConnectionIds(connectionsSnapshot.docs.map((doc) => doc.id));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setDirectConnectionIds([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const userImage = userData.profileImage || profileImage;

  const shouldKeepPostVisible = async (post, reactions, reposts) => {
    if (!post || !reactions || !reposts) {
      console.error(
        "Invalid post or reactions or reposts:",
        post,
        reactions,
        reposts
      );
      return false;
    }

    const postUserId = post.user?.id || post.userId;
    if (!postUserId) {
      console.error("Invalid post user ID:", post);
      return false;
    }

    const connectionType = await checkConnectionType(
      currentUser.uid,
      postUserId
    );

    if (connectionType === "You" || connectionType === "Direct") {
      return true;
    }

    if (reactions[currentUser.uid]) {
      return true;
    }

    if (reposts[currentUser.uid]) {
      return true;
    }

    const directConnections = await fetchDirectConnections(currentUser.uid);
    for (const connectionId of directConnections) {
      if (reactions[connectionId]) {
        return true;
      }
      if (reposts[connectionId]) {
        return true;
      }
    }

    return false;
  };

  const fetchDirectConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Connected"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  const fetchUserPosts = async (userId) => {
    const postsRef = collection(firestore, "users", userId, "posts");
    const postsSnapshot = await getDocs(
      query(postsRef, orderBy("createdAt", "desc"), limit(20))
    );
    return Promise.all(
      postsSnapshot.docs.map(async (doc) => {
        const postData = doc.data();
        return createPostObject(userId, doc.id, {
          ...postData,
          originalDirectReactionTime: postData.originalDirectReactionTime,
          originalDirectRepostTime: postData.originalDirectRepostTime,
        });
      })
    );
  };

  const fetchReactedPosts = async (userId) => {
    const reactedPostsRef = collection(
      firestore,
      "users",
      userId,
      "reactedPosts"
    );
    const reactedPostsSnapshot = await getDocs(
      query(reactedPostsRef, orderBy("reactedAt", "desc"), limit(20))
    );
    const reactedPosts = [];
    for (const docSnapshot of reactedPostsSnapshot.docs) {
      const reactedPostData = docSnapshot.data();
      const originalPostRef = doc(
        firestore,
        "users",
        reactedPostData.originalPosterId,
        "posts",
        reactedPostData.postId
      );
      const originalPostSnapshot = await getDoc(originalPostRef);
      if (originalPostSnapshot.exists()) {
        const postData = originalPostSnapshot.data();
        const reactorData = await getUserData(userId);
        const post = await createPostObject(
          reactedPostData.originalPosterId,
          reactedPostData.postId,
          {
            ...postData,
            originalDirectReactionTime: postData.originalDirectReactionTime,
          },
          {
            id: userId,
            firstName: reactorData.firstName,
            lastName: reactorData.lastName,
            reactedAt: reactedPostData.reactedAt,
            emoji: reactedPostData.emoji,
          }
        );
        reactedPosts.push(post);
      }
    }
    return reactedPosts;
  };

  const fetchReposts = async (userId) => {
    const repostsRef = collection(firestore, "users", userId, "reposts");
    const repostsSnapshot = await getDocs(
      query(repostsRef, orderBy("repostedAt", "desc"), limit(20))
    );
    const reposts = [];
    for (const docSnapshot of repostsSnapshot.docs) {
      const repostsData = docSnapshot.data();
      const originalPostRef = doc(
        firestore,
        "users",
        repostsData.originalPosterId,
        "posts",
        repostsData.postId
      );
      const originalPostSnapshot = await getDoc(originalPostRef);
      if (originalPostSnapshot.exists()) {
        const postData = originalPostSnapshot.data();
        const reposterData = await getUserData(userId);
        const post = await createPostObject(
          repostsData.originalPosterId,
          repostsData.postId,
          {
            ...postData,
            originalDirectRepostTime: postData.originalDirectRepostTime,
          },
          null,
          {
            id: userId,
            firstName: reposterData.firstName,
            lastName: reposterData.lastName,
            repostedAt: repostsData.repostedAt,
          }
        );
        reposts.push(post);
      }
    }
    return reposts;
  };

  const getUserData = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    return userDocSnapshot.data();
  };

  const getLatestActivityTimestamp = (post) => {
    if (post.visibleReactedBy && post.visibleReactedBy.reactedAt) {
      return post.visibleReactedBy.reactedAt.toMillis();
    }
    if (post.visibleRepostedBy && post.visibleRepostedBy.repostedAt) {
      return post.visibleRepostedBy.repostedAt.toMillis();
    }
    return post.createdAt.toMillis();
  };

  const fetchCommentedPosts = async (userId) => {
    const commentedPostsRef = collection(
      firestore,
      "users",
      userId,
      "commentedPosts"
    );
    const commentedPostsSnapshot = await getDocs(
      query(commentedPostsRef, orderBy("lastCommentedAt", "desc"), limit(20))
    );
    const commentedPosts = [];
    for (const docSnapshot of commentedPostsSnapshot.docs) {
      const commentedPostData = docSnapshot.data();
      const originalPostRef = doc(
        firestore,
        "users",
        commentedPostData.originalPosterId,
        "posts",
        commentedPostData.postId
      );
      const originalPostSnapshot = await getDoc(originalPostRef);
      if (originalPostSnapshot.exists()) {
        const postData = originalPostSnapshot.data();
        const commenterData = await getUserData(userId);
        const mostRecentComment = Object.values(commentedPostData.comments)
          .sort((a, b) => b.commentedAt.toMillis() - a.commentedAt.toMillis())
          .shift();
        const post = await createPostObject(
          commentedPostData.originalPosterId,
          commentedPostData.postId,
          {
            ...postData,
            originalDirectCommentTime: postData.originalDirectCommentTime,
          },
          null,
          null,
          {
            id: userId,
            firstName: commenterData.firstName,
            lastName: commenterData.lastName,
            commentedAt: mostRecentComment.commentedAt,
            commentText: mostRecentComment.commentText,
          }
        );
        commentedPosts.push(post);
      }
    }
    return commentedPosts;
  };

  const handleCommentedPostUpdates = async (snapshot) => {
    const updatedCommentedPosts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const commentedPostData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          commentedPostData.originalPosterId,
          "posts",
          commentedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const commenterId = change.doc.ref.parent.parent.id;
          const commenterData = await getUserData(commenterId);

          let mostRecentComment = null;
          if (
            commentedPostData.comments &&
            typeof commentedPostData.comments === "object"
          ) {
            mostRecentComment = Object.values(commentedPostData.comments)
              .filter((comment) => comment.commentedAt)
              .sort((a, b) => {
                const aTime = a.commentedAt ? a.commentedAt.toMillis() : 0;
                const bTime = b.commentedAt ? b.commentedAt.toMillis() : 0;
                return bTime - aTime;
              })
              .shift();
          }

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === commentedPostData.postId
            ) ||
            posts.indirectSuggested.find(
              (p) => p.id === commentedPostData.postId
            ) ||
            posts.extendedSuggested.find(
              (p) => p.id === commentedPostData.postId
            );

          const updatedPost = await createPostObject(
            commentedPostData.originalPosterId,
            commentedPostData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              originalDirectRepostTime: existingPost?.originalDirectRepostTime,
              originalDirectCommentTime:
                existingPost?.originalDirectCommentTime,
            },
            null,
            null,
            change.type !== "removed" && mostRecentComment
              ? {
                  id: commenterId,
                  firstName: commenterData.firstName,
                  lastName: commenterData.lastName,
                  commentedAt: mostRecentComment.commentedAt,
                  commentText: mostRecentComment.commentText,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: commentedPostData.postId, removed: true };
        } else {
          return { id: commentedPostData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedCommentedPosts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleCommentedBy:
                  up.visibleCommentedBy || newPosts[index].visibleCommentedBy,
                originalDirectCommentTime:
                  up.originalDirectCommentTime ||
                  newPosts[index].originalDirectCommentTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  useEffect(() => {
    const handlePostUpdates = async (snapshot) => {
      const updatedPosts = await Promise.all(
        snapshot.docChanges().map(async (change) => {
          if (change.type === "added" || change.type === "modified") {
            const updatedPost = await createPostObject(
              change.doc.ref.parent.parent.id,
              change.doc.id,
              change.doc.data()
            );
            const existingPost = findPostById(change.doc.id);
            if (existingPost) {
              updatedPost.visibleReactedBy = existingPost.visibleReactedBy;
              updatedPost.originalDirectReactionTime =
                existingPost.originalDirectReactionTime;
              updatedPost.visibleRepostedBy = existingPost.visibleRepostedBy;
              updatedPost.originalDirectRepostTime =
                existingPost.originalDirectRepostTime;
              updatedPost.sortTime =
                existingPost.sortTime || getPostSortTime(updatedPost);
            } else {
              updatedPost.sortTime = getPostSortTime(updatedPost);
            }
            return updatedPost;
          } else if (change.type === "removed") {
            return { id: change.doc.id, removed: true };
          }
        })
      );

      setPosts((prevPosts) => {
        const updateCategory = (category) => {
          let newPosts = [...prevPosts[category]];
          updatedPosts.forEach((up) => {
            if (up && up.removed) {
              newPosts = newPosts.filter((p) => p.id !== up.id);
            } else if (up) {
              const index = newPosts.findIndex((p) => p.id === up.id);
              if (index !== -1) {
                newPosts[index] = {
                  ...newPosts[index],
                  ...up,
                  sortTime: newPosts[index].sortTime || up.sortTime,
                };
              } else {
                newPosts.push(up);
              }
            }
          });
          return sortPosts(newPosts.filter(Boolean));
        };

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });
    };
  }, [posts, currentUser, checkConnectionType, firestore, profileImage]);

  const handleReactedPostUpdates = async (snapshot) => {
    const updatedReactedPosts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const reactedPostData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          reactedPostData.originalPosterId,
          "posts",
          reactedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const reactorId = change.doc.ref.parent.parent.id;
          const reactorData = await getUserData(reactorId);

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === reactedPostData.postId
            ) ||
            posts.indirectSuggested.find(
              (p) => p.id === reactedPostData.postId
            ) ||
            posts.extendedSuggested.find(
              (p) => p.id === reactedPostData.postId
            );

          const updatedPost = await createPostObject(
            reactedPostData.originalPosterId,
            reactedPostData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              originalDirectRepostTime: existingPost?.originalDirectRepostTime,
              originalDirectCommentTime:
                existingPost?.originalDirectCommentTime,
            },
            change.type !== "removed"
              ? {
                  id: reactorId,
                  firstName: reactorData.firstName,
                  lastName: reactorData.lastName,
                  reactedAt: reactedPostData.reactedAt,
                  emoji: reactedPostData.emoji,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: reactedPostData.postId, removed: true };
        } else {
          return { id: reactedPostData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedReactedPosts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleReactedBy:
                  up.visibleReactedBy || newPosts[index].visibleReactedBy,
                originalDirectReactionTime:
                  up.originalDirectReactionTime ||
                  newPosts[index].originalDirectReactionTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  const handleRepostsUpdates = async (snapshot) => {
    const updatedReposts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const repostsData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          repostsData.originalPosterId,
          "posts",
          repostsData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const reposterId = change.doc.ref.parent.parent.id;
          const reposterData = await getUserData(reposterId);

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === repostsData.postId
            ) ||
            posts.indirectSuggested.find((p) => p.id === repostsData.postId) ||
            posts.extendedSuggested.find((p) => p.id === repostsData.postId);

          const updatedPost = await createPostObject(
            repostsData.originalPosterId,
            repostsData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
            },
            null,
            change.type !== "removed"
              ? {
                  id: reposterId,
                  firstName: reposterData.firstName,
                  lastName: reposterData.lastName,
                  repostedAt: repostsData.repostedAt,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: repostsData.postId, removed: true };
        } else {
          return { id: repostsData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedReposts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleRepostedBy:
                  up.visibleRepostedBy || newPosts[index].visibleRepostedBy,
                originalDirectRepostTime:
                  up.originalDirectRepostTime ||
                  newPosts[index].originalDirectRepostTime,
                visibleReactedBy:
                  newPosts[index].visibleReactedBy || up.visibleReactedBy,
                originalDirectReactionTime:
                  newPosts[index].originalDirectReactionTime ||
                  up.originalDirectReactionTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  const createPostObject = async (
    userId,
    postId,
    postData,
    reactedBy = null,
    repostedBy = null,
    commentedBy = null
  ) => {
    const userDocRef = doc(firestore, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();
    let connectionType = "You";
    if (userId !== currentUser.uid) {
      connectionType = await checkConnectionType(currentUser.uid, userId);
    }
    let visibleReactedBy = null;
    let visibleRepostedBy = null;
    let visibleCommentedBy = null;
    let originalDirectReactionTime = null;
    let originalDirectRepostTime = null;
    let originalDirectCommentTime = null;
    if (connectionType === "Indirect" || connectionType === "Extended") {
      if (!seenPosts[postId]) {
        if (repostedBy && repostedBy.id !== currentUser.uid) {
          const reposterConnectionType = await checkConnectionType(
            currentUser.uid,
            repostedBy.id
          );
          if (reposterConnectionType === "Direct") {
            const reposterData = await getUserData(repostedBy.id);
            visibleRepostedBy = {
              ...repostedBy,
              profileImage: reposterData.profileImage || profileImage,
            };
            originalDirectRepostTime = repostedBy.repostedAt;
          }
        }
        if (
          !visibleRepostedBy &&
          commentedBy &&
          commentedBy.id !== currentUser.uid
        ) {
          const commenterConnectionType = await checkConnectionType(
            currentUser.uid,
            commentedBy.id
          );
          if (commenterConnectionType === "Direct") {
            const commenterData = await getUserData(commentedBy.id);
            visibleCommentedBy = {
              ...commentedBy,
              profileImage: commenterData.profileImage || profileImage,
            };
            originalDirectCommentTime = commentedBy.commentedAt;
          }
        }
        if (
          !visibleRepostedBy &&
          !visibleCommentedBy &&
          reactedBy &&
          reactedBy.id !== currentUser.uid
        ) {
          const reactorConnectionType = await checkConnectionType(
            currentUser.uid,
            reactedBy.id
          );
          if (reactorConnectionType === "Direct") {
            const reactorData = await getUserData(reactedBy.id);
            visibleReactedBy = {
              ...reactedBy,
              profileImage: reactorData.profileImage || profileImage,
              emoji: reactedBy.emoji,
            };
            originalDirectReactionTime = reactedBy.reactedAt;
          }
        }
      }
    }
    const postObject = {
      id: postId,
      ...postData,
      user: {
        id: userId,
        firstName: userData.firstName || "Unknown",
        lastName: userData.lastName || "User",
        profileImage: userData.profileImage || profileImage,
        bio: userData.bio || "",
        connectionType: connectionType,
        link: userData.link || null,
        linkText: userData.linkText || null,
      },
      reactions: postData.reactions || {},
      reposts: postData.reposts || {},
      comments: postData.comments || {},
      visibleReactedBy,
      visibleRepostedBy,
      visibleCommentedBy,
      originalDirectReactionTime,
      originalDirectRepostTime,
      originalDirectCommentTime,
      createdAt: postData.createdAt || new Date(),
      commentCount: postData.commentCount || 0,
      firstSeenAt: seenPosts[postId]
        ? seenPosts[postId].firstSeenAt
        : new Date(),
    };
    if (!seenPosts[postId]) {
      setSeenPosts((prev) => ({
        ...prev,
        [postId]: {
          firstSeenAt: new Date(),
          originalPosition: Object.keys(prev).length,
          visibleReactedBy,
          visibleRepostedBy,
          visibleCommentedBy,
        },
      }));
    }
    return postObject;
  };

  const shouldPostBeVisible = async (post) => {
    if (!post || !post.user || !post.user.id) return false;
    const connectionType = post.user.connectionType;
    if (connectionType === "You" || connectionType === "Direct") return true;
    if (post.reactions && post.reactions[currentUser.uid]) return true;
    if (post.reposts && post.reposts[currentUser.uid]) return true;
    if (post.comments && post.comments[currentUser.uid]) return true;
    const directConnections = await fetchDirectConnections(currentUser.uid);
    return (
      Object.keys(post.reactions || {}).some((reactorId) =>
        directConnections.includes(reactorId)
      ) ||
      Object.keys(post.reposts || {}).some((reposterId) =>
        directConnections.includes(reposterId)
      ) ||
      Object.keys(post.comments || {}).some((commenterId) =>
        directConnections.includes(commenterId)
      ) ||
      (post.visibleReactedBy &&
        directConnections.includes(post.visibleReactedBy.id)) ||
      (post.visibleCommentedBy &&
        directConnections.includes(post.visibleCommentedBy.id))
    );
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  useEffect(() => {
    const fetchInitialPosts = async () => {
      if (currentUser) {
        setLoading(true);
        try {
          await fetchAndSubscribeToPosts();
        } catch (error) {
          console.error("Error fetching initial posts:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchInitialPosts();
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchAndSubscribeToPosts();
    }
  }, [currentUser, fetchAndSubscribeToPosts]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore) {
        fetchAndSubscribeToPosts(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  const togglePostModal = () => {
    if (!showPostModal) {
      setPostModalScrollPosition(window.scrollY);
    }
    setShowPostModal(!showPostModal);
    setIsPostModalOpen(!isPostModalOpen);
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}y`;
    }
  };

  const renderPostText = (post) => {
    if (!post || !post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    const isSuggestedPost =
      post.user.connectionType === "Indirect" ||
      post.user.connectionType === "Extended";

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              togglePostExpansion(post.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const togglePostExpansion = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handlePrevImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + totalImages) % totalImages,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const renderPostContent = (post) => {
    if (!post || typeof post !== "object") {
      console.log("Invalid post object:", post);
      return null;
    }

    console.log("Rendering post content for post", post.id);
    console.log("Post content:", post.content);
    console.log(
      "Current image index for post",
      post.id,
      ":",
      currentImageIndex[post.id]
    );

    if (!post.content) {
      console.log("No content for post", post.id);
      return null;
    }

    // Preload images when rendering post content
    if (
      post.content &&
      post.content.type === "image" &&
      Array.isArray(post.content.url)
    ) {
      preloadImages(post.content.url);
    }

    const hasNoInteractions =
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    return (
      <div className={classes.postContentWrapper}>
        {post.content && post.content.type === "image" && (
          <div className={classes.imageCarousel}>
            {Array.isArray(post.content.url) ? (
              <>
                <img
                  key={currentImageIndex[post.id] || 0}
                  src={post.content.url[currentImageIndex[post.id] || 0]}
                  alt={""}
                  className={classes.postContentImage}
                  onClick={() =>
                    handleNextImage(post.id, post.content.url.length)
                  }
                />
                {post.content.url.length > 1 && (
                  <div className={classes.dotNavigation}>
                    {post.content.url.map((_, index) => (
                      <span
                        key={index}
                        className={`${classes.dotNav} ${
                          index === (currentImageIndex[post.id] || 0)
                            ? classes.activeDot
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentImageIndex((prev) => ({
                            ...prev,
                            [post.id]: index,
                          }));
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <img
                src={post.content.url}
                alt=""
                className={classes.postContentImage}
              />
            )}
          </div>
        )}

        {post.content.type === "video" && (
          <div className={classes.videoContainer}>
            <video
              ref={(el) => (videoRefs.current[post.id] = el)}
              className={classes.postContentVideo}
              playsInline
              muted={videoVolumes[post.id] !== true}
              loop
              autoPlay
              preload="metadata"
              poster={post.content.thumbnail || ""}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
            >
              <source src={post.content.url} type="video/mp4" />
            </video>
            <div className={classes.videoControls}>
              <button
                className={classes.volumeControl}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVolume(post.id);
                }}
              >
                {videoVolumes[post.id] !== true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        )}
        {post.content.type === "pdf" && (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              hasNoInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log("currentImageIndex state updated:", currentImageIndex);
  }, [currentImageIndex]);

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleRepost = async (postId) => {
    try {
      if (!postId || !currentUser) return;

      const post = findPostById(postId);
      if (!post || !post.user || !post.user.id) {
        throw new Error("Post not found");
      }

      const postRef = doc(firestore, "users", post.user.id, "posts", postId);
      const repostedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reposts",
        postId
      );

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[currentUser.uid]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReposts[currentUser.uid];
        await deleteDoc(repostedPostRef);

        // Remove notification
        const notificationRef = doc(
          firestore,
          "users",
          post.user.id,
          "notifications",
          `repost_${postId}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Removed repost.",
          postId,
          isRepost: false,
        });
      } else {
        // Repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: now,
        });
        updatedReposts[currentUser.uid] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        // Add notification
        if (post.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `repost_${postId}_${currentUser.uid}`
          );
          await setDoc(notificationRef, {
            type: "repost",
            postId: postId,
            reposterId: currentUser.uid,
            reposterName: `${userData.firstName} ${userData.lastName}`,
            reposterImage: userData.profileImage || profileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
          });
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((p) =>
            p.id === postId
              ? {
                  ...p,
                  reposts: updatedReposts,
                  originalDirectRepostTime: p.originalDirectRepostTime || now,
                }
              : p
          );

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });
    } catch (error) {
      console.error("Error in handleRepost:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the repost.",
          isError: true,
        });
      }
    }
  };

  const determineVisibleAction = (post) => {
    if (
      post.user.connectionType === "You" ||
      post.user.connectionType === "Direct"
    ) {
      return null;
    }

    const storedPost = seenPosts[post.id];
    if (storedPost) {
      if (storedPost.visibleRepostedBy) {
        return {
          type: "repost",
          user: storedPost.visibleRepostedBy,
          message: "reposted this",
        };
      } else if (storedPost.visibleCommentedBy) {
        return {
          type: "comment",
          user: storedPost.visibleCommentedBy,
          message: "commented on this",
        };
      } else if (storedPost.visibleReactedBy) {
        return {
          type: "reaction",
          user: storedPost.visibleReactedBy,
          message: "reacted",
          emoji: storedPost.visibleReactedBy.emoji,
        };
      }
    }

    if (
      post.visibleRepostedBy &&
      post.visibleRepostedBy.id !== currentUser.uid
    ) {
      return {
        type: "repost",
        user: post.visibleRepostedBy,
        message: "reposted this",
      };
    }

    if (
      post.visibleCommentedBy &&
      post.visibleCommentedBy.id !== currentUser.uid
    ) {
      return {
        type: "comment",
        user: post.visibleCommentedBy,
        message: "commented on this",
      };
    }

    if (post.visibleReactedBy && post.visibleReactedBy.id !== currentUser.uid) {
      return {
        type: "reaction",
        user: post.visibleReactedBy,
        message: "reacted",
        emoji: post.visibleReactedBy.emoji,
      };
    }

    return null;
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const handleEditComment = (postId, commentId, isReply = false) => {
    const postComments = comments[postId];
    if (!postComments) {
      console.error(`No comments found for post ${postId}`);
      return;
    }

    const commentToEdit = postComments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
      console.error(`Comment ${commentId} not found in post ${postId}`);
    }
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const post = findPostById(postId);
      if (!post) throw new Error("Post not found");
      const commentRef = doc(
        firestore,
        `users/${post.user.id}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");

      console.log("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message: "Error updating comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${editingPost.user.id}/posts/${editingPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((post) =>
            post.id === editingPost.id
              ? { ...post, text: updatedText, isEdited: isEdited }
              : post
          );

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        activeEmojiPicker !== null ||
        mainEmojiPickerOpen !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : activeEmojiPicker !== null
          ? { top: `-${commentEmojiPickerScrollPosition}px` }
          : mainEmojiPickerOpen !== null
          ? { top: `-${emojiPickerScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}
      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}
      {showPostModal && (
        <PostModal
          onClose={handleClosePostModal}
          onManualClose={() => {
            setShowPostModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialFile={selectedFile}
          canceledUploads={canceledUploads}
        />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={handleCloseReactionsModal}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser.uid}
        />
      )}
      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={handleCloseRepostsModal}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser.uid}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {cancelMessage && (
        <CancelPopup
          message={cancelMessage}
          onClose={() => setCancelMessage(null)}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.topCard}>
            <div className={classes.postContent}>
              <div>
                <img
                  className={classes.postImage}
                  src={userImage || defaultImage}
                  alt="Profile"
                  onClick={() => navigate("/profile")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                className={`${classes.postButton} ${
                  loading ? classes.disabled : ""
                }`}
                onClick={loading ? null : handleAddPost}
              >
                Add a post
              </div>
            </div>
          </div>

          {Object.entries(state.uploadingPosts).map(
            ([postId, postData]) =>
              !state.cancelledPosts[postId] && (
                <UploadingPost
                  key={postId}
                  postId={postId}
                  progress={postData.progress}
                  status={postData.status}
                  onCancelUpload={handleCancelUpload}
                />
              )
          )}

          {posts.directAndInteracted &&
            posts.directAndInteracted.length > 0 && (
              <div className={classes.postSection}>
                {posts.directAndInteracted.map((post) => (
                  <div key={post.id} className={classes.postCard}>
                    {(() => {
                      const visibleAction = determineVisibleAction(post);
                      if (visibleAction) {
                        let highestPriorityAction = null;
                        if (post.visibleRepostedBy) {
                          highestPriorityAction = {
                            type: "repost",
                            user: post.visibleRepostedBy,
                            message: "reposted this",
                          };
                        } else if (post.visibleCommentedBy) {
                          highestPriorityAction = {
                            type: "comment",
                            user: post.visibleCommentedBy,
                            message: "commented on this",
                          };
                        } else if (post.visibleReactedBy) {
                          highestPriorityAction = {
                            type: "reaction",
                            user: post.visibleReactedBy,
                            message: "reacted",
                            emoji: post.visibleReactedBy.emoji,
                          };
                        }

                        if (highestPriorityAction) {
                          return (
                            <div className={classes.reactionHeader}>
                              <img
                                src={
                                  highestPriorityAction.user.profileImage ||
                                  defaultImage
                                }
                                alt={`${highestPriorityAction.user.firstName} ${highestPriorityAction.user.lastName}`}
                                className={classes.reactionImage}
                                onClick={() =>
                                  handleNavigate(highestPriorityAction.user.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <span className={classes.reactionText}>
                                <span
                                  className={classes.reactorName}
                                  onClick={() =>
                                    handleNavigate(
                                      highestPriorityAction.user.id
                                    )
                                  }
                                >
                                  {highestPriorityAction.user.firstName}{" "}
                                  {highestPriorityAction.user.lastName}
                                </span>{" "}
                                {highestPriorityAction.message}
                                {highestPriorityAction.type === "reaction" &&
                                  highestPriorityAction.emoji && (
                                    <span className={classes.reactorEmoji}>
                                      {" "}
                                      {highestPriorityAction.emoji}
                                    </span>
                                  )}
                                {highestPriorityAction.type === "reaction"
                                  ? " to this"
                                  : ""}
                              </span>
                            </div>
                          );
                        }
                      }
                      return null;
                    })()}
                    <div className={classes.postHeader}>
                      <div className={classes.userInfo}>
                        <img
                          className={classes.postImage}
                          src={post.user.profileImage || defaultImage}
                          alt=""
                          onClick={() => handleNavigate(post.user.id)}
                        />
                        <div className={classes.userDetails}>
                          <div className={classes.userNameContainer}>
                            <p
                              className={classes.userName}
                              onClick={() => handleNavigate(post.user.id)}
                            >
                              <span className={classes.nameText}>
                                {`${post.user.firstName} ${post.user.lastName}`}
                              </span>
                            </p>
                            {post.user.connectionType && (
                              <span className={classes.connectionType}>
                                • {post.user.connectionType}
                              </span>
                            )}
                          </div>
                          {post.user.bio && (
                            <p className={classes.bio}>{post.user.bio}</p>
                          )}
                          {post.user.link && (
                            <div className={classes.userLinkContainer}>
                              <a
                                href={post.user.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                className={classes.userLink}
                              >
                                <span className={classes.userLinkText}>
                                  {post.user.linkText || post.user.link}
                                </span>
                                <svg
                                  className={classes.userLinkIcon}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="orangered"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            </div>
                          )}
                          <div className={classes.timeAndEditDetails}>
                            {post.createdAt && (
                              <p className={classes.time}>
                                {formatTimestamp(post.createdAt)}
                              </p>
                            )}
                            {post.isEdited && (
                              <div className={classes.editDot}>
                                •
                                <span className={classes.editedPostText}>
                                  Edited
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.postOptions}>
                        <svg
                          className={`${classes.optionsIcon} ${
                            showDropdown[post.id]
                              ? classes.optionsIconActive
                              : ""
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(post.id);
                          }}
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeWidth="3"
                            d="M6 12h.01m6 0h.01m5.99 0h.01"
                          />
                        </svg>
                        {showDropdown[post.id] && (
                          <div className={classes.optionsDropdown}>
                            {post.user.id === currentUser.uid ? (
                              <>
                                <button
                                  onClick={() => handleEditPost(post)}
                                  className={classes.editPost}
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Edit
                                </button>
                                <button
                                  onClick={() => handleDeletePost(post.id)}
                                  className={classes.deletePost}
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="gray"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Delete
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={() => handleReportPost(post.id)}
                                className={classes.reportPost}
                              >
                                <svg
                                  className={
                                    "w-6 h-6 text-gray-800 dark:text-white"
                                  }
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="gray"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                  />
                                </svg>
                                Report
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {post.text && renderPostText(post)}
                    {renderPostContent(post)}
                    {hasInteractions(post) && (
                      <div className={classes.postInteractionsInfo}>
                        {post.reactions &&
                          Object.keys(post.reactions).length > 0 && (
                            <div
                              className={classes.reactionsSummary}
                              onClick={() =>
                                handleReactionsClick(post.id, post.reactions)
                              }
                            >
                              <div className={classes.reactionStack}>
                                {getTopThreeEmojis(post.reactions)
                                  .reverse()
                                  .map((emoji, index) => (
                                    <div
                                      key={index}
                                      className={classes.reactionEmoji}
                                      style={{
                                        zIndex:
                                          getTopThreeEmojis(post.reactions)
                                            .length - index,
                                        fontSize: "var(--emoji-font-size)",
                                        marginLeft: "var(--emoji-margin-left)",
                                        marginBottom:
                                          "var(--emoji-margin-bottom)",
                                        marginTop: "var(--emoji-margin-top)",
                                      }}
                                    >
                                      {emoji}
                                    </div>
                                  ))}
                              </div>
                              <span
                                className={classes.reactionCount}
                                style={{
                                  marginLeft: "var(--count-margin-left)",
                                }}
                              >
                                {Object.keys(post.reactions).length}
                              </span>
                            </div>
                          )}
                        <div className={classes.rightInteractions}>
                          {post.commentCount > 0 && (
                            <span
                              className={classes.commentCount}
                              onClick={() => handleCommentsCountClick(post.id)}
                            >
                              {post.commentCount} comment
                              {post.commentCount !== 1 ? "s" : ""}
                            </span>
                          )}
                          {post.commentCount > 0 &&
                            post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span className={classes.dot}>•</span>
                            )}
                          {post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span
                                className={classes.repostCount}
                                onClick={() =>
                                  handleRepostsClick(post.id, post.reposts)
                                }
                              >
                                {Object.keys(post.reposts).length} repost
                                {Object.keys(post.reposts).length !== 1
                                  ? "s"
                                  : ""}
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                    <div className={classes.postInteractions}>
                      <button
                        className={`${classes.interactionButton} ${
                          post.reactions?.[currentUser.uid]
                            ? classes.emojiButton
                            : ""
                        }`}
                        onClick={() => handleReactionClick(post.id)}
                      >
                        {post.reactions && post.reactions[currentUser.uid] ? (
                          <span>{post.reactions[currentUser.uid]}</span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )}
                      </button>
                      <button
                        className={classes.interactionButton}
                        onClick={() => toggleCommentInput(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                          />
                        </svg>
                      </button>
                      <button
                        className={`${classes.interactionButton} ${
                          post.reposts?.[currentUser.uid]
                            ? classes.repostedButton
                            : ""
                        }`}
                        onClick={() => handleRepost(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                          />
                        </svg>
                      </button>
                      <button className={classes.interactionButton}>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                          />
                        </svg>
                      </button>
                    </div>
                    {mainEmojiPickerOpen === `post_${post.id}` && (
                      <EmojiPicker
                        onEmojiClick={(emoji) =>
                          handleEmojiSelect(post.id, emoji)
                        }
                        onClose={() => {
                          setMainEmojiPickerOpen(null);
                          setTimeout(() => {
                            window.scrollTo(0, emojiPickerScrollPosition);
                          }, 0);
                        }}
                      />
                    )}
                    <div className={classes.commentsSection}>
                      {activeCommentPostId === post.id && (
                        <>
                          <div className={classes.commentInputSection}>
                            <div className={classes.commentInputWrapper}>
                              <div>
                                <img
                                  src={userImage || defaultImage}
                                  alt="Your profile"
                                  className={classes.commentUserImage}
                                  onClick={() => navigate("/profile")}
                                />
                              </div>
                              <textarea
                                ref={commentInputRef}
                                value={newComment}
                                onChange={handleCommentInputChange}
                                placeholder="Say something..."
                                className={classes.commentInput}
                              />
                            </div>

                            <div
                              onClick={() =>
                                newComment.trim() && handleAddComment(post.id)
                              }
                              className={`${classes.addCommentButton} ${
                                !newComment.trim() ? classes.disabled : ""
                              }`}
                            >
                              Comment
                            </div>
                          </div>
                          {comments[post.id] &&
                            comments[post.id].length > 0 && (
                              <div className={classes.commentsList}>
                                {renderComments(post.id, post.user.id)}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          {posts.indirectSuggested && posts.indirectSuggested.length > 0 && (
            <div>
              {posts.indirectSuggested.map((post) => (
                <div key={post.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>Suggested</div>
                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={post.user.profileImage || defaultImage}
                        alt=""
                        onClick={() => handleNavigate(post.user.id)}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() => handleNavigate(post.user.id)}
                          >
                            <span className={classes.nameText}>
                              {`${post.user.firstName} ${post.user.lastName}`}
                            </span>
                          </p>
                          {post.user.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.user.connectionType}
                            </span>
                          )}
                        </div>
                        {post.user.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(post.id);
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && (
                        <div className={classes.optionsDropdown}>
                          {post.user.id === currentUser.uid ? (
                            <>
                              <button
                                onClick={() => handleEditPost(post)}
                                className={classes.editPost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeletePost(post.id)}
                                className={classes.deletePost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleReportPost(post.id)}
                              className={classes.reportPost}
                            >
                              <svg
                                className={
                                  "w-6 h-6 text-gray-800 dark:text-white"
                                }
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}
                  {hasInteractions(post) && (
                    <div className={classes.postInteractionsInfo}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(post.reactions)
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(post.reactions)
                                          .length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                      marginBottom:
                                        "var(--emoji-margin-bottom)",
                                      marginTop: "var(--emoji-margin-top)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            <span
                              className={classes.reactionCount}
                              style={{ marginLeft: "var(--count-margin-left)" }}
                            >
                              {Object.keys(post.reactions).length}
                            </span>
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {post.commentCount > 0 && (
                          <span
                            className={classes.commentCount}
                            onClick={() => handleCommentsCountClick(post.id)}
                          >
                            {post.commentCount} comment
                            {post.commentCount !== 1 ? "s" : ""}
                          </span>
                        )}
                        {post.commentCount > 0 &&
                          post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span className={classes.dot}>•</span>
                          )}
                        {post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(post.id, post.reposts)
                              }
                            >
                              {Object.keys(post.reposts).length} repost
                              {Object.keys(post.reposts).length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  <div className={classes.postInteractions}>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reactions?.[currentUser.uid]
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() => handleReactionClick(post.id)}
                    >
                      {post.reactions && post.reactions[currentUser.uid] ? (
                        <span>{post.reactions[currentUser.uid]}</span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={classes.interactionButton}
                      onClick={() => toggleCommentInput(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reposts?.[currentUser.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>
                  {mainEmojiPickerOpen === `post_${post.id}` && (
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(post.id, emoji)
                      }
                      onClose={() => {
                        setMainEmojiPickerOpen(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  <div className={classes.commentsSection}>
                    {activeCommentPostId === post.id && (
                      <>
                        <div className={classes.commentInputSection}>
                          <div className={classes.commentInputWrapper}>
                            <div>
                              <img
                                src={userImage || defaultImage}
                                alt="Your profile"
                                className={classes.commentUserImage}
                                onClick={() => navigate("/profile")}
                              />
                            </div>
                            <textarea
                              ref={commentInputRef}
                              value={newComment}
                              onChange={handleCommentInputChange}
                              placeholder="Say something..."
                              className={classes.commentInput}
                            />
                          </div>

                          <div
                            onClick={() =>
                              newComment.trim() && handleAddComment(post.id)
                            }
                            className={`${classes.addCommentButton} ${
                              !newComment.trim() ? classes.disabled : ""
                            }`}
                          >
                            Comment
                          </div>
                        </div>
                        {comments[post.id] && comments[post.id].length > 0 && (
                          <div className={classes.commentsList}>
                            {renderComments(post.id, post.user.id)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {posts.extendedSuggested && posts.extendedSuggested.length > 0 && (
            <div>
              {posts.extendedSuggested.map((post) => (
                <div key={post.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>Suggested</div>

                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={post.user.profileImage || defaultImage}
                        alt=""
                        onClick={() => handleNavigate(post.user.id)}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() => handleNavigate(post.user.id)}
                          >
                            <span className={classes.nameText}>
                              {`${post.user.firstName} ${post.user.lastName}`}
                            </span>
                          </p>
                          {post.user.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.user.connectionType}
                            </span>
                          )}
                        </div>
                        {post.user.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(post.id);
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && (
                        <div className={classes.optionsDropdown}>
                          {post.user.id === currentUser.uid ? (
                            <>
                              <button
                                onClick={() => handleEditPost(post)}
                                className={classes.editPost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeletePost(post.id)}
                                className={classes.deletePost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleReportPost(post.id)}
                              className={classes.reportPost}
                            >
                              <svg
                                className={
                                  "w-6 h-6 text-gray-800 dark:text-white"
                                }
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}
                  {hasInteractions(post) && (
                    <div className={classes.postInteractionsInfo}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(post.reactions)
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(post.reactions)
                                          .length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                      marginBottom:
                                        "var(--emoji-margin-bottom)",
                                      marginTop: "var(--emoji-margin-top)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            <span
                              className={classes.reactionCount}
                              style={{ marginLeft: "var(--count-margin-left)" }}
                            >
                              {Object.keys(post.reactions).length}
                            </span>
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {post.commentCount > 0 && (
                          <span
                            className={classes.commentCount}
                            onClick={() => handleCommentsCountClick(post.id)}
                          >
                            {post.commentCount} comment
                            {post.commentCount !== 1 ? "s" : ""}
                          </span>
                        )}
                        {post.commentCount > 0 &&
                          post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span className={classes.dot}>•</span>
                          )}
                        {post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(post.id, post.reposts)
                              }
                            >
                              {Object.keys(post.reposts).length} repost
                              {Object.keys(post.reposts).length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  <div className={classes.postInteractions}>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reactions?.[currentUser.uid]
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() => handleReactionClick(post.id)}
                    >
                      {post.reactions && post.reactions[currentUser.uid] ? (
                        <span>{post.reactions[currentUser.uid]}</span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={classes.interactionButton}
                      onClick={() => toggleCommentInput(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reposts?.[currentUser.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>
                  {mainEmojiPickerOpen === `post_${post.id}` && (
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(post.id, emoji)
                      }
                      onClose={() => {
                        setMainEmojiPickerOpen(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  <div className={classes.commentsSection}>
                    {activeCommentPostId === post.id && (
                      <>
                        <div className={classes.commentInputSection}>
                          <div className={classes.commentInputWrapper}>
                            <div>
                              <img
                                src={userImage || defaultImage}
                                alt="Your profile"
                                className={classes.commentUserImage}
                                onClick={() => navigate("/profile")}
                              />
                            </div>
                            <textarea
                              ref={commentInputRef}
                              value={newComment}
                              onChange={handleCommentInputChange}
                              placeholder="Say something..."
                              className={classes.commentInput}
                            />
                          </div>

                          <div
                            onClick={() =>
                              newComment.trim() && handleAddComment(post.id)
                            }
                            className={`${classes.addCommentButton} ${
                              !newComment.trim() ? classes.disabled : ""
                            }`}
                          >
                            Comment
                          </div>
                        </div>
                        {comments[post.id] && comments[post.id].length > 0 && (
                          <div className={classes.commentsList}>
                            {renderComments(post.id, post.user.id)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {loading && (
            <div className={classes.loadingIndicator}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          )}
          {hasAttemptedLoad &&
            posts.directAndInteracted.length === 0 &&
            posts.indirectSuggested.length === 0 &&
            posts.extendedSuggested.length === 0 && (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No posts found</div>
                <div className={classes.text}>
                  Please check your internet connection.
                </div>
                <div
                  className={classes.homeButton}
                  onClick={() => window.location.reload()}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )}
          {/* {!hasMore && (
            <div className={classes.noMorePosts}>
              Whoa, you've reached the end!
            </div>
          )} */}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
