import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./IntroModal.module.css";
import { convertUrlsToLinks } from "../../utils/textUtils";

const IntroModal = ({ onClose, currentUser, onProfileUpdate }) => {
  const [intro, setIntro] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const formRef = useRef(null);
  const [websiteLink, setWebsiteLink] = useState("");
  const textAreaRef = useRef(null);
  const modalContentRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setInitialLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setInitialData(userData);
          setIntro(userData.intro || "");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser.uid]);

  useEffect(() => {
    const dataChanged = intro !== initialData.intro;
    setIsChanged(dataChanged);
  }, [intro, initialData]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleIntroChange = (e) => {
    if (e.target.value.length <= 2500) {
      setIntro(e.target.value);
    }
  };
  useEffect(() => {
    const adjustHeight = () => {
      if (textAreaRef.current && modalContentRef.current) {
        // Reset height to auto to get the correct scrollHeight
        textAreaRef.current.style.height = "auto";

        // Set the height to the scrollHeight
        const newHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = `${newHeight}px`;

        // Adjust modalContent height
        const headerHeight = document.querySelector(
          `.${classes.cardHeader}`
        ).offsetHeight;
        const footerHeight = document.querySelector(
          `.${classes.cardFooter}`
        ).offsetHeight;
        const maxHeight = window.innerHeight - 4 * 16; // 4rem in pixels
        const contentHeight = Math.min(
          newHeight + headerHeight + footerHeight,
          maxHeight
        );

        modalContentRef.current.style.height = `${contentHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  }, [intro]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const currentData = userDoc.data();
        const trimmedIntro = trimTrailingWhitespace(intro);
        const updatedProfile = {
          ...currentData,
          intro: trimmedIntro,
          introHtml: convertUrlsToLinks(trimmedIntro),
        };

        await updateDoc(userDocRef, updatedProfile);

        onProfileUpdate(updatedProfile);
        // Don't call onClose() here
      }
    } catch (error) {
      setError("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors
      if (!error) {
        onClose(true);
      }
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const trimTrailingWhitespace = (text) => {
    return text.replace(/\s+$/g, "");
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        ref={modalContentRef}
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit intro
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)} // Pass false for regular close
              onTouchStart={(e) => handleCloseTouch(e, false)} // Pass false for regular close
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        {initialLoading ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <form
            className={classes.interactions}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <textarea
              ref={textAreaRef}
              id="intro"
              value={intro}
              onChange={handleIntroChange}
              className={classes.textArea}
              placeholder="Tell us your story. What drives you? What are you working on or interested in? Give others a quick snapshot of who you are."
            />
          </form>
        )}
        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.charCount}>{intro.length}/2500</div>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isChanged || loading || initialLoading}
            onClick={() => {
              handleSaveButtonClick();
              // Only close the modal if there are no errors
              if (!error) {
                onClose(true);
              }
            }}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroModal;
