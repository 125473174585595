import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditEducationModal.module.css";
import monthsList from "../../utils/monthsList";

const EditEducationModal = ({
  onClose,
  currentUser,
  onEducationUpdated,
  onEditSingle,
}) => {
  const [educations, setEducations] = useState([]);
  const [hoverStates, setHoverStates] = useState({});
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleDropdown = (educationId) => {
    setOpenDropdown(openDropdown === educationId ? null : educationId);
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchEducations = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const educationsCollectionRef = collection(userDocRef, "education");
        const educationsSnapshot = await getDocs(educationsCollectionRef);
        let educationsList = educationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        educationsList.sort((a, b) => {
          const now = new Date();
          const currentYear = now.getFullYear();
          const currentMonth = monthsList.indexOf(
            now.toLocaleString("default", { month: "long" })
          );

          const getEndDate = (edu) => {
            if (edu.isCurrentlyStudying) {
              return new Date(currentYear, currentMonth);
            }
            return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
          };

          const aEnd = getEndDate(a);
          const bEnd = getEndDate(b);

          if (aEnd > bEnd) return -1;
          if (aEnd < bEnd) return 1;

          const aStart = new Date(`${a.startMonth} 1, ${a.startYear}`);
          const bStart = new Date(`${b.startMonth} 1, ${b.startYear}`);

          if (aStart > bStart) return -1;
          if (aStart < bStart) return 1;

          return new Date(b.dateAdded) - new Date(a.dateAdded);
        });

        setEducations(educationsList);
        setHoverStates(
          educationsList.reduce(
            (acc, edu) => ({
              ...acc,
              [edu.id]: { edit: false, delete: false },
            }),
            {}
          )
        );
      } catch (error) {
        console.error("Error fetching educations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEducations();
  }, [currentUser.uid]);

  const handleEditClick = (education) => {
    onEditSingle(education);
  };

  const handleDeleteEducation = async (educationId) => {
    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const educationDocRef = doc(userDocRef, "education", educationId);
      await deleteDoc(educationDocRef);
      const updatedEducations = educations.filter(
        (edu) => edu.id !== educationId
      );
      setEducations(updatedEducations);
      onEducationUpdated({ education: updatedEducations });
      setOpenDropdown(null);
    } catch (error) {
      console.error("Error deleting education:", error);
    }
  };

  const handleMouseEnter = (id, type) => {
    setHoverStates((prev) => ({
      ...prev,
      [id]: { ...prev[id], [type]: true },
    }));
  };

  const handleMouseLeave = (id, type) => {
    setHoverStates((prev) => ({
      ...prev,
      [id]: { ...prev[id], [type]: false },
    }));
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit Education
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.educationsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : educations.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No education found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            educations.map((education) => (
              <div key={education.id} className={classes.educationContainer}>
                <div className={classes.educationContent}>
                  <div className={classes.educationInfo}>
                    <h3 className={classes.school}>{education.school}</h3>
                    <p className={classes.degree}>{education.degree}</p>
                    <p className={classes.subject}>{education.subject}</p>
                    {education.grade && (
                      <p className={classes.grade}>{education.grade}</p>
                    )}
                    {education.description && (
                      <p className={classes.subject}>{education.description}</p>
                    )}
                    <p className={classes.dates}>{`${education.startMonth} ${
                      education.startYear
                    } - ${
                      education.isCurrentlyStudying
                        ? "Current"
                        : `${education.endMonth} ${education.endYear}`
                    }`}</p>
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === education.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(education.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === education.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(education);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteEducation(education.id);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEducationModal;
