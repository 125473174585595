import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  writeBatch,
  serverTimestamp,
  orderBy,
  addDoc,
  increment,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { onAuthStateChanged } from "firebase/auth";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./SingleReaction.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import RepostsModal from "../../components/Modals/RepostsModal";
import PostModal from "../../components/Modals/PostModal";

function SingleReaction() {
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [post, setPost] = useState(null);
  const [errorPopup, setErrorPopup] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const { reactionId } = useParams();
  const [reaction, setReaction] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [expandedPost, setExpandedPost] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [videoVolume, setVideoVolume] = useState(false);
  const videoRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [reactionMessage, setReactionMessage] = useState(null);
  const [isCurrentUserReaction, setIsCurrentUserReaction] = useState(false);
  const location = useLocation();
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [repostMessage, setRepostMessage] = useState(null);
  const [currentReactionEmoji, setCurrentReactionEmoji] = useState(null);
  const [isSafari, setIsSafari] = useState(false);
  const [reactionDisplayData, setReactionDisplayData] = useState(null);
  const [currentUserReaction, setCurrentUserReaction] = useState(null);

  // New state variables for comments
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [visibleComments, setVisibleComments] = useState(10);
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const commentInputRef = useRef(null);
  const replyInputRef = useRef({});

  const handleEditPost = () => {
    setPostModalScrollPosition(window.scrollY);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  // Function to fetch comments
  const fetchComments = useCallback(async () => {
    if (!reaction || !reaction.originalPost || !reaction.originalPost.id) {
      console.log("Missing data for fetching comments", { reaction });
      return;
    }

    console.log("Fetching comments for post:", reaction.originalPost.id);

    const commentsRef = collection(
      firestore,
      `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
    );
    const q = query(commentsRef, orderBy("createdAt", "asc"));

    try {
      const snapshot = await getDocs(q);
      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const userDocRef = doc(firestore, "users", commentData.userId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = userDocSnapshot.data();
          let connectionType = "Unknown";
          try {
            connectionType = await checkConnectionType(
              currentUser.uid,
              commentData.userId
            );
          } catch (error) {
            console.error("Error checking connection type:", error);
          }
          return {
            id: docSnapshot.id,
            ...commentData,
            userName: `${userData.firstName} ${userData.lastName}`,
            userImage: userData.profileImage || profileImage,
            connectionType,
            bio: userData.bio || "",
            link: userData.link || null,
            linkText: userData.linkText || null,
            postId: reaction.originalPost.id,
            postUserId: reaction.originalPost.user.id,
            rootCommentId: commentData.rootCommentId,
            isRootComment: commentData.isRootComment,
          };
        })
      );

      console.log("Fetched comments:", fetchedComments);

      setComments(fetchedComments);

      console.log("Updated comments state:", fetchedComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }, [reaction, currentUser, checkConnectionType]);

  useEffect(() => {
    if (reaction && currentUser) {
      fetchComments();
    }
  }, [reaction, currentUser]);

  // Function to add a comment
  const handleAddComment = async (parentCommentId = null) => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[parentCommentId] || "";
    } else {
      commentText = newComment;
    }

    if (!commentText.trim()) return;

    const processedCommentText = convertUrlsToLinks(commentText);

    try {
      const postRef = doc(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
      );
      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      const rootCommentId = parentCommentId
        ? await getRootCommentId(
            reaction.originalPost.user.id,
            reaction.originalPost.id,
            parentCommentId
          )
        : null;

      // Fetch current user's data to ensure we have the most up-to-date information
      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();

      if (!currentUserData) {
        throw new Error("Current user data not found");
      }

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: currentUser.uid,
        userName:
          `${currentUserData.firstName || ""} ${
            currentUserData.lastName || ""
          }`.trim() || "Unknown User",
        userImage: currentUserData.profileImage || profileImage,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: currentUserData.bio || "",
        connectionType: "You",
        link: currentUserData.link || null,
        linkText: currentUserData.linkText || null,
        postUserId: reaction.originalPost.user.id,
      };

      const commentsRef = collection(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
      );
      const newCommentRef = await addDoc(commentsRef, commentData);

      // Update the comment with the server timestamp
      await updateDoc(newCommentRef, { createdAt: serverTimestamp() });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${currentUser.uid}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId: reaction.originalPost.id,
        postUserId: reaction.originalPost.user.id,
      };

      setComments((prevComments) => [...prevComments, newCommentObject]);

      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          commentCount: (prevReaction.originalPost.commentCount || 0) + 1,
          comments: {
            ...(prevReaction.originalPost.comments || {}),
            [currentUser.uid]: now,
          },
          originalDirectCommentTime: now,
        },
      }));

      if (parentCommentId) {
        setReplyComments((prev) => ({ ...prev, [parentCommentId]: "" }));
      } else {
        setNewComment("");
        if (commentInputRef.current) {
          commentInputRef.current.style.height = "auto";
        }
      }
      setActiveCommentId(null);

      const commentedPostRef = doc(
        firestore,
        `users/${currentUser.uid}/commentedPosts/${reaction.originalPost.id}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        firstName: currentUserData.firstName || "",
        lastName: currentUserData.lastName || "",
        originalPosterId: reaction.originalPost.user.id,
      };

      if (commentedPostDoc.exists()) {
        const existingData = commentedPostDoc.data();
        await updateDoc(commentedPostRef, {
          comments: {
            ...(existingData.comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId: reaction.originalPost.user.id,
          postId: reaction.originalPost.id,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      if (
        reaction.originalPost.user.id !== currentUser.uid &&
        !parentCommentId
      ) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${reaction.originalPost.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );
        batch.set(postOwnerNotificationRef, {
          type: "comment",
          postId: reaction.originalPost.id,
          commentId: newCommentRef.id,
          commenterId: currentUser.uid,
          commenterName:
            `${currentUserData.firstName || ""} ${
              currentUserData.lastName || ""
            }`.trim() || "Unknown User",
          commenterImage: currentUserData.profileImage || profileImage,
          commentText: commentText,
          createdAt: now,
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
        });
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        if (parentCommentData && parentCommentData.userId !== currentUser.uid) {
          const replyNotificationRef = doc(
            firestore,
            `users/${parentCommentData.userId}/notifications`,
            `reply_${newCommentRef.id}`
          );
          batch.set(replyNotificationRef, {
            type: "reply",
            postId: reaction.originalPost.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: currentUser.uid,
            replierName:
              `${currentUserData.firstName || ""} ${
                currentUserData.lastName || ""
              }`.trim() || "Unknown User",
            replierImage: currentUserData.profileImage || profileImage,
            replyText: commentText,
            createdAt: now,
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
          });
        }
      }

      await batch.commit();

      if (reaction.originalPost.user.id !== currentUser.uid) {
        const connectionType = await checkConnectionType(
          currentUser.uid,
          reaction.originalPost.user.id
        );
        if (connectionType === "Indirect" || connectionType === "Extended") {
          const now = serverTimestamp();
          await updateDoc(postRef, {
            originalDirectCommentTime:
              postData.originalDirectCommentTime || now,
          });
        }
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else if (error.message === "Current user data not found") {
        setErrorPopup({
          message: "Unable to retrieve your user data. Please try again later.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while adding the comment.",
          isError: true,
        });
      }
    }
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      const commentRef = doc(
        firestore,
        `users/${postUserId}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment) {
        return commentId;
      } else {
        return commentData.rootCommentId;
      }
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const handleCommentInputChange = (e) => {
    const textarea = e.target;
    setNewComment(textarea.value);

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyInputChange = (e, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [replyId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = (commentId) => {
    const commentExists = comments.some(
      (c) => c.id === commentId || c.replies?.some((r) => r.id === commentId)
    );

    if (!commentExists) {
      setErrorPopup({
        message: "Cannot reply to a non-existent comment.",
        isError: true,
      });
      return;
    }

    setActiveCommentId((prevActiveCommentId) =>
      prevActiveCommentId === commentId ? null : commentId
    );
    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));

    // Add a slight delay before focusing
    setTimeout(() => {
      if (replyInputRef.current[commentId]) {
        const inputElement = replyInputRef.current[commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleLoadMoreComments = () => {
    setVisibleComments((prev) => prev + 10);
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const handleCommentReactionClick = (commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker(activeEmojiPicker === commentId ? null : commentId);
  };

  const handleCommentEmojiSelect = async (commentId, emoji) => {
    if (commentId && currentUser) {
      try {
        let commentRef;
        let commentToUpdate;
        let isReply = false;

        const comment = comments.find(
          (c) =>
            c.id === commentId || c.replies?.some((r) => r.id === commentId)
        );

        if (!comment) {
          throw new Error("Comment not found");
        }

        if (comment.id === commentId) {
          commentRef = doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments/${commentId}`
          );
          commentToUpdate = comment;
        } else {
          const reply = comment.replies.find((r) => r.id === commentId);
          if (reply) {
            commentRef = doc(
              firestore,
              `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments/${comment.id}/replies/${commentId}`
            );
            commentToUpdate = reply;
            isReply = true;
          } else {
            throw new Error("Reply not found");
          }
        }

        if (!commentRef || !commentToUpdate) {
          throw new Error("Comment not found");
        }

        let updatedReactions = { ...(commentToUpdate.reactions || {}) };

        if (updatedReactions[currentUser.uid] === emoji) {
          delete updatedReactions[currentUser.uid];

          const notificationRef = doc(
            firestore,
            `users/${commentToUpdate.userId}/notifications`,
            `comment_reaction_${commentId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);
        } else {
          updatedReactions[currentUser.uid] = emoji;

          if (commentToUpdate.userId !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              `users/${commentToUpdate.userId}/notifications`,
              `comment_reaction_${commentId}_${currentUser.uid}`
            );

            const userDoc = await getDoc(
              doc(firestore, "users", currentUser.uid)
            );
            const userData = userDoc.data();

            await setDoc(notificationRef, {
              type: "comment_reaction",
              postId: reaction.originalPost.id,
              commentId: commentId,
              reactorId: currentUser.uid,
              reactorName: `${userData.firstName} ${userData.lastName}`,
              reactorImage: currentUserProfileImage || profileImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              commentText: commentToUpdate.text,
              isReply: isReply,
              postPreview: {
                text: reaction.originalPost.text || "",
                mediaType: reaction.originalPost.content?.type || null,
                mediaUrl: reaction.originalPost.content?.url || null,
                fileName: reaction.originalPost.content?.fileName || null,
              },
            });
          }
        }

        await updateDoc(commentRef, { reactions: updatedReactions });

        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? { ...comment, reactions: updatedReactions }
              : comment.replies
              ? {
                  ...comment,
                  replies: comment.replies.map((reply) =>
                    reply.id === commentId
                      ? { ...reply, reactions: updatedReactions }
                      : reply
                  ),
                }
              : comment
          )
        );

        setActiveEmojiPicker(null);
        setTimeout(() => {
          window.scrollTo(0, commentEmojiPickerScrollPosition);
        }, 0);
      } catch (error) {
        console.error("Error updating comment/reply reaction:", error);
        if (error.message === "Comment not found") {
          setErrorPopup({
            message: "This comment has been deleted.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: "An error occurred while updating the reaction.",
            isError: true,
          });
        }
      }
    }
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown(
      openCommentDropdown === commentId ? null : commentId
    );
  };

  const handleDeleteComment = async (commentId) => {
    console.log(`Attempting to delete comment: ${commentId}`);

    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      if (!reaction || !reaction.originalPost) {
        console.error(`Post not found for comment: ${commentId}`);
        return;
      }
      console.log(`Post owner ID: ${reaction.originalPost.user.id}`);

      const commentsRef = collection(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        console.error("Comment does not exist");
        return;
      }

      const commentData = commentSnap.data();
      console.log(`Comment owner ID: ${commentData.userId}`);
      const canDelete =
        currentUser.uid === commentData.userId ||
        currentUser.uid === reaction.originalPost.user.id;

      if (!canDelete) {
        console.error("User not authorized to delete this comment");
        return;
      }
      console.log("User is authorized to delete this comment");

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();

      const deleteCommentAndReplies = async (commentRef) => {
        console.log(`Deleting comment: ${commentRef.id}`);
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) {
          console.log(`Comment ${commentRef.id} does not exist`);
          return;
        }

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);
        affectedUserIds.add(commentData.userId);

        // Delete notifications
        try {
          if (commentData.userId !== reaction.originalPost.user.id) {
            await deleteDoc(
              doc(
                firestore,
                `users/${reaction.originalPost.user.id}/notifications/comment_${commentRef.id}`
              )
            );
          }
          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);
            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId !== commentData.userId) {
                await deleteDoc(
                  doc(
                    firestore,
                    `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                  )
                );
              }
            }
          }
        } catch (error) {
          console.error(
            `Error deleting notifications for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete reaction notifications
        try {
          const reactionNotificationsQuery = query(
            collection(firestore, `users/${commentData.userId}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const reactionNotificationsSnapshot = await getDocs(
            reactionNotificationsQuery
          );
          for (const doc of reactionNotificationsSnapshot.docs) {
            await deleteDoc(doc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting reaction notifications for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete replies
        try {
          const repliesQuery = query(
            collection(
              firestore,
              `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
            ),
            where("parentCommentId", "==", commentRef.id)
          );
          const repliesSnapshot = await getDocs(repliesQuery);
          for (const replyDoc of repliesSnapshot.docs) {
            await deleteCommentAndReplies(replyDoc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting replies for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete the comment itself
        try {
          console.log(`Deleting the comment itself: ${commentRef.id}`);
          await deleteDoc(commentRef);
        } catch (error) {
          console.error(`Error deleting comment ${commentRef.id}:`, error);
          throw error;
        }
      };

      // Start the deletion process
      await deleteCommentAndReplies(commentToDeleteRef);

      // Update post document and commentedPosts for all affected users
      const batch = writeBatch(firestore);

      // Update post document
      console.log("Updating post document");
      const postRef = doc(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
      );
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      // Update comments map in post document
      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }

      // Update commentedPosts for all affected users
      console.log("Updating commentedPosts for affected users");
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${reaction.originalPost.id}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Commit all updates
      await batch.commit();

      console.log(
        `Deleted ${deletedCount} comments/replies from ${affectedUserIds.size} users`
      );

      // Update local state
      setComments((prevComments) => {
        return prevComments.filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        );
      });

      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          commentCount: Math.max(
            (prevReaction.originalPost.commentCount || 0) - deletedCount,
            0
          ),
          comments: Object.fromEntries(
            Object.entries(prevReaction.originalPost.comments || {}).filter(
              ([userId]) => !affectedUserIds.has(userId)
            )
          ),
        },
      }));

      setOpenCommentDropdown(null);
      console.log("Comment deletion completed successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      console.error("Error details:", error.code, error.message);
      console.error("Error stack:", error.stack);
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  useEffect(() => {
    if (reaction && reaction.reactions && currentUser) {
      setCurrentUserReaction(reaction.reactions[currentUser.uid] || null);
    }
  }, [reaction, currentUser]);

  const hasInteractions = (reaction) => {
    return (
      reaction &&
      reaction.originalPost &&
      ((reaction.originalPost.reactions &&
        Object.keys(reaction.originalPost.reactions || {}).length > 0) ||
        (reaction.originalPost.reposts &&
          Object.keys(reaction.originalPost.reposts || {}).length > 0) ||
        (reaction.originalPost.commentCount &&
          reaction.originalPost.commentCount > 0))
    );
  };

  useEffect(() => {
    const { state } = location;
    if (state) {
      const { fromUserProfile, userData, reactionEmoji } = state;
      if (fromUserProfile && userData) {
        setReactionDisplayData({
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
          reactionEmoji: reactionEmoji,
        });
      } else {
        // This is from the current user's profile
        setReactionDisplayData({
          isCurrentUser: true,
          reactionEmoji: reactionEmoji,
        });
      }
    }
  }, [location]);

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  useEffect(() => {
    if (reaction && reaction.reactions && currentUser) {
      setCurrentReactionEmoji(reaction.reactions[currentUser.uid] || null);
    }
  }, [reaction, currentUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderComments = () => {
    console.log("Entering renderComments");
    console.log("Current reaction:", reaction);
    console.log("Current comments state:", comments);

    if (!reaction || !reaction.originalPost || !reaction.originalPost.id) {
      console.log("No valid post to render comments for");
      return null;
    }

    if (!comments || !Array.isArray(comments)) {
      console.log("Comments is not a valid array");
      return null;
    }

    const rootComments = comments.filter((comment) => comment.isRootComment);
    console.log("Root comments:", rootComments);

    const visibleRootComments = rootComments.slice(0, visibleComments);

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = comments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment?.reactions ?? {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {comment.userId === currentUser.uid && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(comment.id)}
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}
                      {currentUser.uid !== reaction.originalPost.user.id &&
                        comment.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {currentUser.uid === reaction.originalPost.user.id &&
                        comment.userId !== currentUser.uid && (
                          <button
                            className={classes.reportUserCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {(currentUser.uid === reaction.originalPost.user.id ||
                        comment.userId === currentUser.uid) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === comment.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {renderReplies(comment.id)}

              {activeCommentId === comment.id && (
                <div className={classes.replyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={currentUserProfileImage || profileImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => handleNavigate(currentUser.uid)}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[comment.id] = el)}
                      value={replyComments[comment.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, comment.id)}
                      onTouchStart={handleTouchStart}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(comment.id)}
                    className={`${classes.addReplyButton} ${
                      !replyComments[comment.id]?.trim() ? classes.disabled : ""
                    }`}
                    disabled={!replyComments[comment.id]?.trim()}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={handleLoadMoreComments}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (rootCommentId) => {
    if (!reaction || !reaction.originalPost) {
      console.log("No comments available for this post");
      return null;
    }

    const replies = comments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {reply.userId === currentUser.uid && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(reply.id, true)}
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== reaction.originalPost.user.id &&
                        reply.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {currentUser.uid === reaction.originalPost.user.id &&
                        reply.userId !== currentUser.uid && (
                          <button
                            className={classes.reportUserCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}
                      {(currentUser.uid === reaction.originalPost.user.id ||
                        reply.userId === currentUser.uid) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === reply.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={currentUserProfileImage || profileImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => handleNavigate(currentUser.uid)}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[reply.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, reply.id)}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(reply.id)}
                    className={`${classes.addReplyButton} ${
                      !replyComments[reply.id]?.trim() ? classes.disabled : ""
                    }`}
                    disabled={!replyComments[reply.id]?.trim()}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchReaction = async () => {
      try {
        setLoading(true);
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        let reactionData = null;
        let userData = null;
        let originalPostData = null;
        let originalPostUserData = null;

        for (const userDoc of usersSnapshot.docs) {
          const userId = userDoc.id;
          const reactionRef = doc(
            firestore,
            "users",
            userId,
            "reactedPosts",
            reactionId
          );
          const reactionSnapshot = await getDoc(reactionRef);

          if (reactionSnapshot.exists()) {
            reactionData = reactionSnapshot.data();
            userData = userDoc.data();
            userData.id = userId;

            // Fetch original post data
            const originalPostRef = doc(
              firestore,
              "users",
              reactionData.originalPosterId,
              "posts",
              reactionData.postId
            );
            const originalPostSnapshot = await getDoc(originalPostRef);
            if (originalPostSnapshot.exists()) {
              originalPostData = originalPostSnapshot.data();
              const originalPostUserDoc = await getDoc(
                doc(firestore, "users", reactionData.originalPosterId)
              );
              originalPostUserData = originalPostUserDoc.data();
              originalPostUserData.id = reactionData.originalPosterId;
            }
            break;
          }
        }

        if (
          reactionData &&
          userData &&
          originalPostData &&
          originalPostUserData
        ) {
          const connectionType = await checkConnectionType(
            currentUser.uid,
            originalPostUserData.id
          );

          originalPostUserData.connectionType = connectionType;

          const userConnectionType = await checkConnectionType(
            currentUser.uid,
            userData.id
          );

          setReaction({
            ...reactionData,
            id: reactionId,
            user: {
              id: userData.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              profileImage: userData.profileImage || profileImage,
              bio: userData.bio,
              connectionType: userConnectionType,
            },
            originalPost: {
              ...originalPostData,
              id: reactionData.postId,
              user: {
                id: originalPostUserData.id,
                firstName: originalPostUserData.firstName,
                lastName: originalPostUserData.lastName,
                profileImage: originalPostUserData.profileImage || profileImage,
                bio: originalPostUserData.bio,
                connectionType: connectionType,
                link: originalPostUserData.link || null,
                linkText: originalPostUserData.linkText || null,
              },
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          });

          // Set whether this is the current user's reaction
          setIsCurrentUserReaction(
            userData.id === currentUser.uid || location.state?.fromUserReactions
          );
        } else {
          console.log("Reaction not found");
        }
      } catch (error) {
        console.error("Error fetching reaction:", error);
      } finally {
        setLoading(false);
        setHasAttemptedLoad(true); // Add this line
      }
    };

    if (currentUser) {
      fetchReaction();
    }
  }, [reactionId, currentUser, location.state]);

  useEffect(() => {
    console.log("Current user ID:", currentUser?.uid);
    console.log("Original post user ID:", reaction?.originalPost?.user?.id);
  }, [currentUser, reaction]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== "function") return "";
    const now = new Date();
    const postDate = timestamp.toDate();
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (userId) => {
    console.log("handleNavigate called with userId:", userId);
    console.log("Current user ID:", currentUser?.uid);

    if (!userId) {
      console.error("handleNavigate called with no userId");
      return;
    }

    if (userId === currentUser?.uid) {
      console.log("Navigating to own profile");
      navigate("/profile");
    } else {
      try {
        console.log("Fetching user data for ID:", userId);
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User data:", userData);
          if (userData.urlSlug) {
            console.log("Navigating to urlSlug:", userData.urlSlug);
            navigate(`/${userData.urlSlug}`);
          } else {
            console.log("User does not have a urlSlug, using userId");
            navigate(`/user/${userId}`);
          }
        } else {
          console.error("User not found for ID:", userId);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const renderPostText = (post) => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPost
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html: convertUrlsToLinks(displayText) + (expandedPost ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPost && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPost(true);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.content.url.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = (totalImages) => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const renderPostContent = (post) => {
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex]}
                    alt=""
                    className={classes.postContentImage}
                    onClick={() => handleNextImage(post.content.url.length)}
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === currentImageIndex ? classes.activeDot : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex(index);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );

      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={videoRef}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume(!videoVolume);
                  }}
                >
                  {!videoVolume ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        const hasNoInteractions =
          (!post.reactions || Object.keys(post.reactions).length === 0) &&
          (!post.reposts || Object.keys(post.reposts).length === 0) &&
          (!post.commentCount || post.commentCount === 0);

        return (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              hasNoInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [reaction]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleEmojiSelect = async (emoji) => {
    if (!currentUser) return;

    try {
      const postRef = doc(
        firestore,
        "users",
        reaction.originalPost.user.id,
        "posts",
        reaction.originalPost.id
      );
      const reactedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reactedPosts",
        reaction.originalPost.id
      );

      let updatedReactions = { ...(reaction.reactions || {}) };

      if (updatedReactions[currentUser.uid] === emoji) {
        // User is removing their reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReactions[currentUser.uid];
        await deleteDoc(reactedPostRef);

        // Remove the notification if it exists
        const notificationRef = doc(
          firestore,
          "users",
          reaction.originalPost.user.id,
          "notifications",
          `reaction_${reaction.originalPost.id}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);
      } else {
        // User is adding or changing their reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: emoji,
        });
        updatedReactions[currentUser.uid] = emoji;

        await setDoc(
          reactedPostRef,
          {
            originalPosterId: reaction.originalPost.user.id,
            postId: reaction.originalPost.id,
            reactedAt: serverTimestamp(),
            emoji: emoji,
          },
          { merge: true }
        );

        // Add notification if the post is not from the current user
        if (reaction.originalPost.user.id !== currentUser.uid) {
          // Fetch the current user's data
          const userDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const userData = userDoc.data();

          const notificationRef = doc(
            firestore,
            "users",
            reaction.originalPost.user.id,
            "notifications",
            `reaction_${reaction.originalPost.id}_${currentUser.uid}`
          );
          await setDoc(notificationRef, {
            type: "reaction",
            postId: reaction.originalPost.id,
            reactorId: currentUser.uid,
            reactorName: `${userData.firstName} ${userData.lastName}`,
            reactorImage: currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
          });
        }
      }

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        reactions: updatedReactions,
        originalPost: {
          ...prevReaction.originalPost,
          reactions: updatedReactions,
        },
      }));

      setCurrentReactionEmoji(updatedReactions[currentUser.uid] || null);

      setShowEmojiPicker(false);
      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, emojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  // Update this function
  const handleReactionClick = () => {
    if (currentUserReaction) {
      handleEmojiSelect(currentUserReaction);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(activeEmojiPicker === "post" ? null : "post");
    }
  };

  const handleReactionsClick = () => {
    if (!reaction || !reaction.reactions) return;
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({
      postId: reaction.originalPost?.id,
      reactions: reaction.reactions,
    });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleDeletePost = async () => {
    console.log("Starting handleDeletePost");
    console.log("Current user:", currentUser);
    console.log("Reaction:", reaction);
    console.log("Original post:", reaction?.originalPost);

    if (!currentUser || !reaction || !reaction.originalPost) {
      console.error("No current user or reaction data");
      setErrorPopup({
        message: "Unable to delete post due to missing data.",
        isError: true,
      });
      return;
    }

    const postId = reaction.originalPost.id;
    const postUserId = reaction.originalPost.user.id;

    console.log(`Attempting to delete post: ${postId}`);
    console.log(`Post owner ID: ${postUserId}`);

    try {
      const postRef = doc(firestore, "users", postUserId, "posts", postId);
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        console.error("Post does not exist");
        return;
      }

      const postData = postSnap.data();
      console.log("Post data:", postData);

      // Start a new batch
      let batch = writeBatch(firestore);
      let operationCount = 0;

      // Helper function to commit batch and start a new one if needed
      const commitBatchIfNeeded = async () => {
        if (operationCount >= 500) {
          console.log("Committing batch");
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      // Helper function to recursively delete comments, replies, and their notifications
      const deleteCommentsAndReplies = async (commentRef) => {
        console.log(`Deleting comment: ${commentRef.id}`);
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) {
          console.log(`Comment ${commentRef.id} does not exist`);
          return;
        }

        const commentData = commentSnapshot.data();

        // Delete comment
        batch.delete(commentRef);
        operationCount++;

        // Delete comment notification
        if (commentData.userId !== reaction.originalPost.user.id) {
          await deleteDoc(
            doc(
              firestore,
              `users/${reaction.originalPost.user.id}/notifications/comment_${commentRef.id}`
            )
          );
        }

        // Delete reply notifications for all users
        console.log("Deleting reply notifications for all users");
        const usersWithInteractions = new Set([
          ...Object.keys(postData.reactions || {}),
          ...Object.keys(postData.comments || {}),
          reaction.originalPost.user.id,
        ]);

        for (const userId of usersWithInteractions) {
          const userReplyNotificationsQuery = query(
            collection(firestore, `users/${userId}/notifications`),
            where("type", "==", "reply"),
            where("postId", "==", reaction.originalPost.id)
          );
          const userReplyNotificationsSnapshot = await getDocs(
            userReplyNotificationsQuery
          );
          for (const doc of userReplyNotificationsSnapshot.docs) {
            batch.delete(doc.ref);
            operationCount++;
            await commitBatchIfNeeded();
          }
        }

        // Delete reaction notifications for this comment
        try {
          const reactionNotificationsQuery = query(
            collection(firestore, `users/${commentData.userId}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const reactionNotificationsSnapshot = await getDocs(
            reactionNotificationsQuery
          );
          for (const doc of reactionNotificationsSnapshot.docs) {
            await deleteDoc(doc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting reaction notifications for comment ${commentRef.id}:`,
            error
          );
        }

        // Delete replies
        try {
          const repliesQuery = query(
            collection(
              firestore,
              `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
            ),
            where("parentCommentId", "==", commentRef.id)
          );
          const repliesSnapshot = await getDocs(repliesQuery);
          for (const replyDoc of repliesSnapshot.docs) {
            await deleteCommentsAndReplies(replyDoc.ref);
          }
        } catch (error) {
          console.error(
            `Error deleting replies for comment ${commentRef.id}:`,
            error
          );
        }

        await commitBatchIfNeeded();
      };

      // Delete comments, replies, and their notifications
      console.log("Deleting comments and replies");
      const commentsRef = collection(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);
      for (const commentDoc of commentsSnapshot.docs) {
        await deleteCommentsAndReplies(commentDoc.ref);
      }

      // Delete reactions and their notifications
      console.log("Deleting reactions");
      if (postData.reactions) {
        for (const [userId, postReaction] of Object.entries(
          postData.reactions
        )) {
          console.log(`Deleting reaction for user: ${userId}`);
          // Delete reactedPosts entry
          const reactedPostRef = doc(
            firestore,
            `users/${userId}/reactedPosts/${reaction.originalPost.id}`
          );
          batch.delete(reactedPostRef);
          operationCount++;

          // Delete reaction notification
          if (userId !== reaction.originalPost.user.id) {
            const notificationRef = doc(
              firestore,
              `users/${reaction.originalPost.user.id}/notifications/reaction_${reaction.originalPost.id}_${userId}`
            );
            batch.delete(notificationRef);
            operationCount++;
          }

          await commitBatchIfNeeded();
        }
      }

      // Delete reposts and their notifications
      if (postData.reposts) {
        for (const [userId, repostTime] of Object.entries(postData.reposts)) {
          // Delete repost entry
          const repostRef = doc(
            firestore,
            `users/${userId}/reposts/${reaction.originalPost.id}`
          );
          batch.delete(repostRef);
          operationCount++;

          // Delete repost notification
          if (userId !== reaction.originalPost.user.id) {
            const notificationRef = doc(
              firestore,
              `users/${reaction.originalPost.user.id}/notifications/repost_${reaction.originalPost.id}_${userId}`
            );
            batch.delete(notificationRef);
            operationCount++;
          }

          await commitBatchIfNeeded();
        }
      }

      // Delete commentedPosts entries
      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${reaction.originalPost.id}`
          );
          batch.delete(commentedPostRef);
          operationCount++;

          await commitBatchIfNeeded();
        }
      }

      // Delete associated files from Firebase Storage
      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${reaction.originalPost.user.id}/${reaction.originalPost.id}`
        );

        if (
          postData.content.type === "image" &&
          Array.isArray(postData.content.url)
        ) {
          // Multiple images
          for (const imageUrl of postData.content.url) {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          }
        } else if (
          postData.content.type === "image" ||
          postData.content.type === "video" ||
          postData.content.type === "pdf"
        ) {
          // Single image, video, or PDF
          const fileRef = ref(storage, postData.content.url);
          await deleteObject(fileRef);
        }

        // Delete the entire folder for the post
        const folderContents = await listAll(storageRef);
        await Promise.all(
          folderContents.items.map((item) => deleteObject(item))
        );
      }

      // Delete the post itself
      console.log("Deleting the post itself");
      batch.delete(postRef);
      operationCount++;

      // Commit the final batch
      console.log("Committing final batch");
      await batch.commit();

      console.log(
        `Successfully deleted post ${reaction.originalPost.id} and all associated data`
      );

      // Navigate back to the profile or dashboard
      navigate("/profile");
    } catch (error) {
      console.error("Error deleting post:", error);
      console.error("Error details:", error.code, error.message);
      console.error("Error stack:", error.stack);
      setErrorPopup({
        message: "An error occurred while deleting the post.",
        isError: true,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = () => {
    // Implement report post functionality
    console.log("Report post clicked");
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(
      reaction?.originalPost?.reposts || {}
    ).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const handleRepost = async () => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    const postId = reaction.originalPost.id;
    const postUserId = reaction.originalPost.user.id;

    try {
      const postRef = doc(firestore, "users", postUserId, "posts", postId);
      const repostedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reposts",
        postId
      );

      let updatedReposts = { ...(reaction.reposts || {}) };
      const now = serverTimestamp();

      // Fetch current user's data
      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const currentUserFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      if (updatedReposts[currentUser.uid]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReposts[currentUser.uid];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          "users",
          postUserId,
          "notifications",
          `repost_${postId}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: now,
        });
        updatedReposts[currentUser.uid] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: postUserId,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: reaction.originalDirectRepostTime || now,
        });

        if (postUserId !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            postUserId,
            "notifications",
            `repost_${postId}_${currentUser.uid}`
          );
          await setDoc(notificationRef, {
            type: "repost",
            postId: postId,
            reposterId: currentUser.uid,
            reposterName: currentUserFullName,
            reposterImage: currentUserProfileImage || profileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
          });
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        reposts: updatedReposts,
        originalPost: {
          ...prevReaction.originalPost,
          reposts: updatedReposts,
        },
      }));
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const handleRepostsClick = () => {
    if (!reaction || !reaction.reposts) return;
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({
      postId: reaction.originalPost?.id,
      reposts: reaction.reposts,
    });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleEditComment = (commentId, isReply = false) => {
    const commentToEdit = comments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    }
  };

  const handleSaveCommentChanges = async (commentId, isReply = false) => {
    if (!currentUser || !reaction) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const commentRef = doc(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        )
      );

      setEditingCommentId(null);
      setEditCommentText("");

      console.log("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message: "Error updating comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderEditButtons = (commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          text: updatedText,
          isEdited: isEdited,
        },
      }));

      setIsEditingComplete(true);
      setShowEditModal(false);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : activeEmojiPicker !== null
          ? {
              top: `-${
                activeEmojiPicker === "post"
                  ? emojiPickerScrollPosition
                  : commentEmojiPickerScrollPosition
              }px`,
            }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserProfileImage} />
      ) : (
        <MainNavbar userImage={currentUserProfileImage} />
      )}

      {showEditModal && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={reaction.originalPost}
          onEditComplete={handleEditComplete}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser?.uid}
        />
      )}

      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={() => {
            setShowRepostsModal(false);
            setIsRepostsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, repostsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser?.uid}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : reaction ? (
            <div className={classes.postCard}>
              <div className={classes.reactionHeader}>
                <img
                  src={
                    reactionDisplayData?.isCurrentUser
                      ? currentUserProfileImage
                      : reactionDisplayData?.profileImage
                  }
                  alt="Profile"
                  className={classes.reactionImage}
                  onClick={() =>
                    reactionDisplayData?.isCurrentUser
                      ? handleNavigate(currentUser.uid)
                      : handleNavigate(reaction.user.id)
                  }
                  style={{ cursor: "pointer" }}
                />
                <div className={classes.reactionTextWrapper}>
                  <span className={classes.reactionText}>
                    <span
                      className={classes.reactionUsername}
                      onClick={() =>
                        reactionDisplayData?.isCurrentUser
                          ? handleNavigate(currentUser.uid)
                          : handleNavigate(reaction.user.id)
                      }
                    >
                      {reactionDisplayData?.isCurrentUser
                        ? "You"
                        : `${reactionDisplayData?.firstName} ${reactionDisplayData?.lastName}`}{" "}
                    </span>{" "}
                    <span className={classes.reactionMessage}>
                      {" "}
                      reacted
                      <span className={classes.textEmoji}>
                        {reactionDisplayData?.isCurrentUser
                          ? currentUserReaction ||
                            reactionDisplayData?.reactionEmoji ||
                            reaction.emoji
                          : reactionDisplayData?.reactionEmoji ||
                            reaction.emoji}{" "}
                      </span>
                      to this
                    </span>
                  </span>
                </div>
              </div>
              <div className={classes.postHeader}>
                <div className={classes.userInfo}>
                  <img
                    className={classes.postImage}
                    src={
                      reaction.originalPost.user.profileImage || profileImage
                    }
                    alt=""
                    onClick={() => {
                      console.log(
                        "Clicking original post user image, user ID:",
                        reaction.originalPost.user.id
                      );
                      handleNavigate(reaction.originalPost.user.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <div className={classes.userDetails}>
                    <div className={classes.userNameContainer}>
                      <p
                        className={classes.userName}
                        onClick={() => {
                          console.log(
                            "Clicking original post user name, user ID:",
                            reaction.originalPost.user.id
                          );
                          handleNavigate(reaction.originalPost.user.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className={classes.nameText}>
                          {`${reaction.originalPost.user.firstName} ${reaction.originalPost.user.lastName}`}
                        </span>
                      </p>
                      {reaction.originalPost.user.connectionType && (
                        <span className={classes.connectionType}>
                          • {reaction.originalPost.user.connectionType}
                        </span>
                      )}
                    </div>
                    {reaction.originalPost.user.bio && (
                      <p className={classes.bio}>
                        {reaction.originalPost.user.bio}
                      </p>
                    )}
                    {reaction.originalPost.user.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={reaction.originalPost.user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {reaction.originalPost.user.linkText ||
                              reaction.originalPost.user.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.timeAndEditDetails}>
                      {reaction.originalPost.createdAt && (
                        <p className={classes.time}>
                          {formatTimestamp(reaction.originalPost.createdAt)}
                        </p>
                      )}
                      {reaction.originalPost.isEdited && (
                        <div className={classes.editDot}>
                          •
                          <span className={classes.editedPostText}>Edited</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.postOptions}>
                  <svg
                    className={`${classes.optionsIcon} ${
                      showDropdown ? classes.optionsIconActive : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {showDropdown && (
                    <div className={classes.optionsDropdown}>
                      {reaction.originalPost.user.id === currentUser.uid ? (
                        <>
                          <button
                            onClick={handleEditPost}
                            className={classes.editPost}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.182c.782-.738 1.793-1.182 2.89-1.182 1.089 0 2.136.44 2.898 1.214.758.77 1.212 1.799 1.212 2.889 0 1.092-.438 2.135-1.194 2.904l-1.274 1.283-5.816-5.71 1.285-1.398Zm-2.722 2.77L6.186 12.12l2.187 2.153 5.069-5.224-2.164-2.098Zm-6.299 6.914-1.966 5.812a1.015 1.015 0 0 0 .248 1.018 1.043 1.043 0 0 0 1.04.246l5.876-1.934-5.198-5.142Zm6.984 4.02 5.092-5.182-2.215-2.18-5.068 5.223 2.191 2.139Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Edit
                          </button>
                          <button
                            onClick={handleDeletePost}
                            className={classes.deletePost}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Delete
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={handleReportPost}
                          className={classes.reportPost}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                            />
                          </svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {reaction.originalPost.text &&
                renderPostText(reaction.originalPost)}
              {renderPostContent(reaction.originalPost)}
              {(hasInteractions(reaction) ||
                reaction?.originalPost?.commentCount > 0) && (
                <div className={classes.postInteractionsInfo}>
                  {reaction?.originalPost?.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0 && (
                      <div
                        className={classes.reactionsSummary}
                        onClick={handleReactionsClick}
                      >
                        <div className={classes.reactionStack}>
                          {getTopThreeEmojis(reaction.originalPost.reactions)
                            .reverse()
                            .map((emoji, index) => (
                              <div
                                key={index}
                                className={classes.reactionEmoji}
                                style={{
                                  zIndex:
                                    getTopThreeEmojis(reaction.reactions)
                                      .length - index,
                                  fontSize: "var(--emoji-font-size)",
                                  marginLeft: "var(--emoji-margin-left)",
                                }}
                              >
                                {emoji}
                              </div>
                            ))}
                        </div>
                        <span
                          className={classes.reactionCount}
                          style={{ marginLeft: "var(--count-margin-left)" }}
                        >
                          {Object.keys(reaction.originalPost.reactions).length}
                        </span>
                      </div>
                    )}
                  <div className={classes.rightInteractions}>
                    {reaction?.originalPost?.commentCount > 0 && (
                      <span
                        className={`${classes.commentCount} ${
                          showComments ? classes.activeCommentCount : ""
                        }`}
                        onClick={() =>
                          setShowComments((prevState) => !prevState)
                        }
                      >
                        {reaction.originalPost.commentCount} comment
                        {reaction.originalPost.commentCount !== 1 ? "s" : ""}
                      </span>
                    )}
                    {reaction?.originalPost?.commentCount > 0 &&
                      reaction?.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0 && (
                        <span className={classes.dot}>•</span>
                      )}
                    {reaction?.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0 && (
                        <span
                          className={classes.repostCount}
                          onClick={handleRepostsClick}
                        >
                          {Object.keys(reaction.originalPost.reposts).length}{" "}
                          repost
                          {Object.keys(reaction.originalPost.reposts).length !==
                          1
                            ? "s"
                            : ""}
                        </span>
                      )}
                  </div>
                </div>
              )}

              <div className={classes.postInteractions}>
                <button
                  className={`${classes.interactionButton} ${
                    currentUserReaction ? classes.emojiButton : ""
                  }`}
                  onClick={handleReactionClick}
                >
                  {currentUserReaction ? (
                    <span>{currentUserReaction}</span>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={classes.interactionButton}
                  onClick={() => setShowComments(!showComments)}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                    />
                  </svg>
                </button>
                <button
                  className={`${classes.interactionButton} ${
                    reaction.reposts?.[currentUser?.uid]
                      ? classes.repostedButton
                      : ""
                  }`}
                  onClick={handleRepost}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                    />
                  </svg>
                </button>
                <button className={classes.interactionButton}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                    />
                  </svg>
                </button>
              </div>
              {activeEmojiPicker === "post" && (
                <EmojiPicker
                  onEmojiClick={handleEmojiSelect}
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, emojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {showComments && (
                <div className={classes.commentsSection}>
                  <div className={classes.commentInputSection}>
                    <div className={classes.commentInputWrapper}>
                      <div>
                        <img
                          src={currentUserProfileImage || profileImage}
                          alt="Your profile"
                          className={classes.commentUserImage}
                          onClick={() => handleNavigate(currentUser.uid)}
                        />
                      </div>
                      <textarea
                        ref={commentInputRef}
                        value={newComment}
                        onChange={handleCommentInputChange}
                        placeholder="Say something..."
                        className={classes.commentInput}
                      />
                    </div>
                    <div
                      onClick={() => newComment.trim() && handleAddComment()}
                      className={`${classes.addCommentButton} ${
                        !newComment.trim() ? classes.disabled : ""
                      }`}
                    >
                      Comment
                    </div>
                  </div>
                  <div className={classes.commentsList}>{renderComments()}</div>
                </div>
              )}
            </div>
          ) : hasAttemptedLoad ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No reaction found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default SingleReaction;
