import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditSingleExperienceModal.module.css";
import CompanyDropdown from "../Dropdowns/CompanyDropdown";
import RoleDropdown from "../Dropdowns/RoleDropdown";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import monthsList from "../../utils/monthsList";

const EditSingleExperienceModal = ({
  experience,
  onClose,
  onUpdate,
  currentUser,
}) => {
  const initialState = {
    company: {
      object: { name: experience.organisation, id: experience.organisation },
      inputValue: experience.organisation,
    },
    role: {
      object: { name: experience.role, id: experience.role },
      inputValue: experience.role,
    },
    description: experience.description || "",
    startMonth: monthsList.indexOf(experience.startMonth) + 1,
    startYear: experience.startYear.toString(),
    endMonth: experience.endMonth
      ? monthsList.indexOf(experience.endMonth) + 1
      : null,
    endYear: experience.endYear ? experience.endYear.toString() : "",
    isCurrentlyWorking: experience.isCurrentlyWorking,
  };

  const [company, setCompany] = useState(initialState.company);
  const [role, setRole] = useState(initialState.role);
  const [description, setDescription] = useState(initialState.description);
  const [startMonth, setStartMonth] = useState(initialState.startMonth);
  const [startYear, setStartYear] = useState(initialState.startYear);
  const [endMonth, setEndMonth] = useState(initialState.endMonth);
  const [endYear, setEndYear] = useState(initialState.endYear);
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(
    initialState.isCurrentlyWorking
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [companyCharCount, setCompanyCharCount] = useState(
    experience.organisation.length
  );
  const [roleCharCount, setRoleCharCount] = useState(experience.role.length);
  const [descriptionCharCount, setDescriptionCharCount] = useState(
    (experience.description || "").length
  );

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const isAllFieldsFilled =
      company.inputValue.trim() !== "" &&
      role.inputValue.trim() !== "" &&
      startMonth !== null &&
      startYear !== "" &&
      (isCurrentlyWorking || (endMonth !== null && endYear !== ""));

    setIsFormValid(isAllFieldsFilled);
  }, [
    company.inputValue,
    role.inputValue,
    startMonth,
    startYear,
    endMonth,
    endYear,
    isCurrentlyWorking,
  ]);

  useEffect(() => {
    const hasChanged =
      company.inputValue !== initialState.company.inputValue ||
      role.inputValue !== initialState.role.inputValue ||
      description !== initialState.description ||
      startMonth !== initialState.startMonth ||
      startYear !== initialState.startYear ||
      endMonth !== initialState.endMonth ||
      endYear !== initialState.endYear ||
      isCurrentlyWorking !== initialState.isCurrentlyWorking;

    setIsChanged(hasChanged);
  }, [
    company.inputValue,
    role.inputValue,
    description,
    startMonth,
    startYear,
    endMonth,
    endYear,
    isCurrentlyWorking,
  ]);

  const handleCompanyChange = (newCompany) => {
    setCompany({
      object: newCompany,
      inputValue: newCompany ? newCompany.name : "",
    });
  };

  const handleRoleChange = (newRole) => {
    setRole({
      object: newRole,
      inputValue: newRole ? newRole.name : "",
    });
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 500) {
      setDescription(inputValue);
      setDescriptionCharCount(inputValue.length);
    }
  };

  const handleStartMonthChange = (newMonth) => {
    setStartMonth(newMonth);
    if (parseInt(startYear) === currentYear && newMonth > currentMonth) {
      setStartMonth(null);
    }
    if (parseInt(startYear) === parseInt(endYear) && endMonth < newMonth) {
      setEndMonth(null);
    }
  };

  const handleStartYearChange = (newYear) => {
    setStartYear(newYear);
    if (endYear && parseInt(endYear) < parseInt(newYear)) {
      setEndYear("");
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(newYear) &&
      endMonth < startMonth
    ) {
      setEndMonth(null);
    }
    if (parseInt(newYear) === currentYear && startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleCurrentYearSelected = () => {
    if (startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleEndMonthChange = (newMonth) => {
    if (parseInt(endYear) === currentYear && newMonth > currentMonth) {
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(startYear) &&
      newMonth < startMonth
    ) {
      setEndMonth(null);
    } else {
      setEndMonth(newMonth);
    }
  };

  const handleEndYearChange = (newYear) => {
    setEndYear(newYear);
    if (parseInt(newYear) === parseInt(startYear) && endMonth < startMonth) {
      setEndMonth(null);
    } else if (parseInt(newYear) === currentYear && endMonth > currentMonth) {
      setEndMonth(null);
    }
  };

  const handleEndCurrentYearSelected = () => {
    if (endMonth > currentMonth) {
      setEndMonth(null);
    }
  };

  const handleCurrentlyWorkingChange = (e) => {
    setIsCurrentlyWorking(e.target.checked);
    if (e.target.checked) {
      setEndMonth(null);
      setEndYear("");
    }
  };

  const handleCompanyInputChange = (inputValue) => {
    if (inputValue.length <= 50) {
      setCompany((prev) => ({ ...prev, inputValue }));
      setCompanyCharCount(inputValue.length);
    }
  };

  const handleRoleInputChange = (inputValue) => {
    if (inputValue.length <= 50) {
      setRole((prev) => ({ ...prev, inputValue }));
      setRoleCharCount(inputValue.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const experienceDocRef = doc(userDocRef, "experiences", experience.id);

      const updatedExperience = {
        organisation: company.inputValue.trim(),
        role: role.inputValue.trim(),
        description: description.trim() || null, // Allow empty description
        startMonth: monthsList[startMonth - 1],
        startYear: Number(startYear),
        endMonth: isCurrentlyWorking ? null : monthsList[endMonth - 1],
        endYear: isCurrentlyWorking ? null : Number(endYear),
        isCurrentlyWorking,
      };

      await updateDoc(experienceDocRef, updatedExperience);

      onUpdate({ id: experience.id, ...updatedExperience });

      onClose();
    } catch (error) {
      setError("Failed to update experience. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
            onTouchStart={handleCloseTouch}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h14M5 12l4-4m-4 4 4 4"
            />
          </svg>
          <span className={classes.cardTitle}> Go back</span>
        </div>
        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <label className={classes.label}>Organisation*</label>
          <CompanyDropdown
            label="Company*"
            selectedCompany={company.object}
            onCompanyChange={handleCompanyChange}
            onInputChange={handleCompanyInputChange}
            inputValue={company.inputValue}
            disabled={loading}
            placeholder="Ex: Apple"
          />
          <div className={classes.charCount}>{companyCharCount}/50</div>
          <label className={classes.label}>Role*</label>

          <RoleDropdown
            label="Role*"
            selectedRole={role.object}
            onRoleChange={handleRoleChange}
            onInputChange={handleRoleInputChange}
            inputValue={role.inputValue}
            disabled={loading}
            placeholder="Ex: Software Engineer"
          />
          <div className={classes.charCount}>{roleCharCount}/50</div>

          <label className={classes.label}>Description</label>
          <textarea
            className={classes.textArea}
            value={description}
            onChange={handleDescriptionChange}
            disabled={loading}
            placeholder="Describe your role and responsibilities"
            maxLength={500}
          />
          <div className={classes.charCount}>{descriptionCharCount}/500</div>

          <div className={classes.checkboxContainer}>
            <input
              type="checkbox"
              id="currentlyWorking"
              className={classes.customCheckbox}
              checked={isCurrentlyWorking}
              onChange={handleCurrentlyWorkingChange}
              disabled={loading}
            />
            <label htmlFor="currentlyWorking">
              I'm currently working in this role
            </label>
          </div>
          <label className={classes.label}>Start date*</label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={startMonth}
              onMonthChange={handleStartMonthChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={parseInt(startYear) === currentYear ? currentMonth : 12}
            />
            <YearDropdown
              selectedYear={startYear}
              onYearChange={handleStartYearChange}
              disabled={loading}
              placeholder="Year"
              onCurrentYearSelected={handleCurrentYearSelected}
            />
          </div>
          {!isCurrentlyWorking && (
            <>
              <label className={classes.label} htmlFor="currentlyWorking">
                End date*
              </label>
              <div className={classes.date}>
                <MonthDropdown
                  selectedMonth={endMonth}
                  onMonthChange={handleEndMonthChange}
                  disabled={loading}
                  placeholder="Month"
                  maxMonth={
                    parseInt(endYear) === currentYear ? currentMonth : 12
                  }
                  minMonth={
                    parseInt(endYear) === parseInt(startYear) ? startMonth : 1
                  }
                />
                <YearDropdown
                  selectedYear={endYear}
                  onYearChange={handleEndYearChange}
                  disabled={loading}
                  minYear={startYear ? parseInt(startYear) : undefined}
                  onCurrentYearSelected={handleEndCurrentYearSelected}
                  placeholder="Year"
                />
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              className={classes.infoIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                clipRule="evenodd"
              />
            </svg>
            <p>*Required information</p>
          </div>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isFormValid || !isChanged || loading}
            onClick={handleSaveButtonClick}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSingleExperienceModal;
