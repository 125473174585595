import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditSingleEducationModal.module.css";
import SchoolDropdown from "../Dropdowns/SchoolDropdown";
import DegreeDropdown from "../Dropdowns/DegreeDropdown";
import SubjectDropdown from "../Dropdowns/SubjectDropdown";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import EducationYearDropdown from "../Dropdowns/EducationYearDropdown";
import monthsList from "../../utils/monthsList";

const EditSingleEducationModal = ({
  education,
  onClose,
  onUpdate,
  currentUser,
}) => {
  const initialState = {
    school: {
      object: { name: education.school, id: education.school },
      inputValue: education.school,
    },
    degree: {
      object: { name: education.degree, id: education.degree },
      inputValue: education.degree,
    },
    subject: {
      object: { name: education.subject, id: education.subject },
      inputValue: education.subject,
    },
    startMonth: monthsList.indexOf(education.startMonth) + 1,
    startYear: education.startYear.toString(),
    endMonth: education.endMonth
      ? monthsList.indexOf(education.endMonth) + 1
      : null,
    endYear: education.endYear ? education.endYear.toString() : "",
    grade: education.grade || "",
    description: education.description || "", // Add description to initial state
  };

  const [school, setSchool] = useState(initialState.school);
  const [degree, setDegree] = useState(initialState.degree);
  const [subject, setSubject] = useState(initialState.subject);
  const [startMonth, setStartMonth] = useState(initialState.startMonth);
  const [startYear, setStartYear] = useState(initialState.startYear);
  const [endMonth, setEndMonth] = useState(initialState.endMonth);
  const [endYear, setEndYear] = useState(initialState.endYear);
  const [grade, setGrade] = useState(initialState.grade);
  const [description, setDescription] = useState(initialState.description); // Add description state
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [descriptionCharCount, setDescriptionCharCount] = useState(
    initialState.description.length
  );

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const isAllFieldsFilled =
      school.inputValue.trim() !== "" &&
      degree.inputValue.trim() !== "" &&
      subject.inputValue.trim() !== "" &&
      startMonth !== null &&
      startYear !== "" &&
      endMonth !== null &&
      endYear !== "";

    setIsFormValid(isAllFieldsFilled);
  }, [
    school.inputValue,
    degree.inputValue,
    subject.inputValue,
    startMonth,
    startYear,
    endMonth,
    endYear,
  ]);

  useEffect(() => {
    const hasChanged =
      school.inputValue !== initialState.school.inputValue ||
      degree.inputValue !== initialState.degree.inputValue ||
      subject.inputValue !== initialState.subject.inputValue ||
      startMonth !== initialState.startMonth ||
      startYear !== initialState.startYear ||
      endMonth !== initialState.endMonth ||
      endYear !== initialState.endYear ||
      grade !== initialState.grade ||
      description !== initialState.description; // Add description to change detection

    setIsChanged(hasChanged);
  }, [
    school.inputValue,
    degree.inputValue,
    subject.inputValue,
    startMonth,
    startYear,
    endMonth,
    endYear,
    grade,
    description, // Add description to dependencies
  ]);

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 1000) {
      setDescription(inputValue);
      setDescriptionCharCount(inputValue.length);
    }
  };

  const handleSchoolChange = (newSchool) => {
    setSchool({
      object: newSchool,
      inputValue: newSchool ? newSchool.name : "",
    });
  };

  const handleDegreeChange = (newDegree) => {
    setDegree({
      object: newDegree,
      inputValue: newDegree ? newDegree.name : "",
    });
  };

  const handleSubjectChange = (newSubject) => {
    setSubject({
      object: newSubject,
      inputValue: newSubject ? newSubject.name : "",
    });
  };

  const handleStartMonthChange = (newMonth) => {
    setStartMonth(newMonth);
    if (parseInt(startYear) === currentYear && newMonth > currentMonth) {
      setStartMonth(null);
    }
    if (parseInt(startYear) === parseInt(endYear) && endMonth < newMonth) {
      setEndMonth(null);
    }
  };

  const handleStartYearChange = (newYear) => {
    setStartYear(newYear);
    if (endYear && parseInt(endYear) < parseInt(newYear)) {
      setEndYear("");
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(newYear) &&
      endMonth < startMonth
    ) {
      setEndMonth(null);
    }
    if (parseInt(newYear) === currentYear && startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleCurrentYearSelected = () => {
    if (startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleEndMonthChange = (newMonth) => {
    if (parseInt(endYear) === parseInt(startYear) && newMonth < startMonth) {
      setEndMonth(null);
    } else {
      setEndMonth(newMonth);
    }
    setIsChanged(true);
  };

  const handleEndYearChange = (newYear) => {
    setEndYear(newYear);
    if (parseInt(newYear) === parseInt(startYear) && endMonth < startMonth) {
      setEndMonth(null);
    }
  };

  const handleGradeChange = (e) => {
    const input = e.target.value;
    if (input.length <= 50) {
      setGrade(input);
    }
  };

  const handleSchoolInputChange = (inputValue) => {
    setSchool((prev) => ({ ...prev, inputValue }));
  };

  const handleDegreeInputChange = (inputValue) => {
    setDegree((prev) => ({ ...prev, inputValue }));
  };

  const handleSubjectInputChange = (inputValue) => {
    setSubject((prev) => ({ ...prev, inputValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const educationDocRef = doc(userDocRef, "education", education.id);

      const updatedEducation = {
        school: school.inputValue.trim(),
        degree: degree.inputValue.trim(),
        subject: subject.inputValue.trim(),
        grade: grade.trim(),
        description: description.trim() || null, // Allow empty description
        startMonth: monthsList[startMonth - 1],
        startYear: Number(startYear),
        endMonth: monthsList[endMonth - 1],
        endYear: Number(endYear),
      };

      await updateDoc(educationDocRef, updatedEducation);

      onUpdate({ id: education.id, ...updatedEducation });

      onClose();
    } catch (error) {
      setError("Failed to update education. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
            onTouchStart={handleCloseTouch}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h14M5 12l4-4m-4 4 4 4"
            />
          </svg>
          <span className={classes.cardTitle}> Go back</span>
        </div>
        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <label className={classes.label}>School*</label>
          <SchoolDropdown
            selectedSchool={school.object}
            onSchoolChange={handleSchoolChange}
            onInputChange={handleSchoolInputChange}
            inputValue={school.inputValue}
            disabled={loading}
            placeholder="Ex: London South Bank University"
          />
          <label className={classes.label}>Qualification*</label>
          <DegreeDropdown
            selectedDegree={degree.object}
            onDegreeChange={handleDegreeChange}
            onInputChange={handleDegreeInputChange}
            inputValue={degree.inputValue}
            disabled={loading}
            placeholder="Ex: Bachelor of Science (BSc)"
          />
          <label className={classes.label}>Subject*</label>
          <SubjectDropdown
            selectedSubject={subject.object}
            onSubjectChange={handleSubjectChange}
            onInputChange={handleSubjectInputChange}
            inputValue={subject.inputValue}
            disabled={loading}
            placeholder="Ex: Product Design"
          />
          <label className={classes.label} htmlFor="grade">
            Grade
          </label>
          <input
            type="text"
            id="grade"
            value={grade}
            onChange={handleGradeChange}
            maxLength={50}
            className={classes.userInput}
            disabled={loading}
            placeholder="Ex: First Class Honours"
          />
          <div className={classes.charCount}>{grade.length}/50</div>
          {/* Add Description textarea */}
          <label className={classes.label}>Description</label>
          <textarea
            className={classes.textArea}
            value={description}
            onChange={handleDescriptionChange}
            disabled={loading}
            placeholder="Describe your educational experience"
            maxLength={1000}
          />
          <div className={classes.charCount}>{descriptionCharCount}/1000</div>
          <label className={classes.label} htmlFor="currentlyStudying">
            Start date*
          </label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={startMonth}
              onMonthChange={handleStartMonthChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={parseInt(startYear) === currentYear ? currentMonth : 12}
            />
            <EducationYearDropdown
              selectedYear={startYear}
              onYearChange={handleStartYearChange}
              disabled={loading}
              placeholder="Year"
              onCurrentYearSelected={handleCurrentYearSelected}
              minYear={currentYear - 100}
              maxYear={currentYear}
            />
          </div>

          <label className={classes.label} htmlFor="endDate">
            End date*
          </label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={endMonth}
              onMonthChange={handleEndMonthChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={12}
              minMonth={
                parseInt(endYear) === parseInt(startYear) ? startMonth : 1
              }
            />
            <EducationYearDropdown
              selectedYear={endYear}
              onYearChange={handleEndYearChange}
              disabled={loading}
              minYear={startYear ? parseInt(startYear) : undefined}
              maxYear={currentYear + 10}
              placeholder="Year"
            />
          </div>
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              className={classes.infoIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                clipRule="evenodd"
              />
            </svg>
            <p>*Required information</p>
          </div>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isFormValid || !isChanged || loading}
            onClick={handleSaveButtonClick}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSingleEducationModal;
