import React, { useState, useEffect, useRef } from "react";
import { doc, collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./AddEducationModal.module.css";
import SchoolDropdown from "../Dropdowns/SchoolDropdown";
import DegreeDropdown from "../Dropdowns/DegreeDropdown";
import SubjectDropdown from "../Dropdowns/SubjectDropdown";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import EducationYearDropdown from "../Dropdowns/EducationYearDropdown";
import monthsList from "../../utils/monthsList";

const AddEducationModal = ({ onClose, currentUser, onEducationAdded }) => {
  const [school, setSchool] = useState({ object: null, inputValue: "" });
  const [degree, setDegree] = useState({ object: null, inputValue: "" });
  const [subject, setSubject] = useState({ object: null, inputValue: "" });
  const [startMonth, setStartMonth] = useState(null);
  const [startYear, setStartYear] = useState("");
  const [endMonth, setEndMonth] = useState(null);
  const [endYear, setEndYear] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [grade, setGrade] = useState("");
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [description, setDescription] = useState("");
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const isAllRequiredFieldsFilled =
      school.inputValue.trim() !== "" &&
      degree.inputValue.trim() !== "" &&
      subject.inputValue.trim() !== "" &&
      startMonth !== null &&
      startYear !== "" &&
      endMonth !== null &&
      endYear !== "";

    setIsFormValid(isAllRequiredFieldsFilled);
    setIsChanged(isAllRequiredFieldsFilled);
  }, [
    school.inputValue,
    degree.inputValue,
    subject.inputValue,
    startMonth,
    startYear,
    endMonth,
    endYear,
  ]);

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 1000) {
      setDescription(inputValue);
      setDescriptionCharCount(inputValue.length);
      setIsChanged(true);
    }
  };

  const handleSchoolChange = (newSchool) => {
    setSchool({
      object: newSchool,
      inputValue: newSchool ? newSchool.name : "",
    });
  };

  const handleDegreeChange = (newDegree) => {
    setDegree({
      object: newDegree,
      inputValue: newDegree ? newDegree.name : "",
    });
  };

  const handleSubjectChange = (newSubject) => {
    setSubject({
      object: newSubject,
      inputValue: newSubject ? newSubject.name : "",
    });
  };

  const handleGradeChange = (e) => {
    const input = e.target.value;
    if (input.length <= 50) {
      setGrade(input);
      setIsChanged(true);
    }
  };

  const handleStartMonthChange = (newMonth) => {
    setStartMonth(newMonth);
    setIsChanged(true);
    if (parseInt(startYear) === currentYear && newMonth > currentMonth) {
      setStartMonth(null);
    }
    if (parseInt(startYear) === parseInt(endYear) && endMonth < newMonth) {
      setEndMonth(null);
    }
  };

  const handleStartYearChange = (newYear) => {
    setStartYear(newYear);
    if (endYear && parseInt(endYear) < parseInt(newYear)) {
      setEndYear("");
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(newYear) &&
      endMonth < startMonth
    ) {
      setEndMonth(null);
    }
    if (parseInt(newYear) === currentYear && startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleCurrentYearSelected = () => {
    if (startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleEndMonthChange = (newMonth) => {
    if (parseInt(endYear) === parseInt(startYear) && newMonth < startMonth) {
      setEndMonth(null);
    } else {
      setEndMonth(newMonth);
    }
    setIsChanged(true);
  };

  const handleEndYearChange = (newYear) => {
    setEndYear(newYear);
    if (
      parseInt(newYear) === parseInt(startYear) &&
      endMonth &&
      endMonth < startMonth
    ) {
      setEndMonth(null);
    }
    setIsChanged(true);
  };

  const handleSchoolInputChange = (inputValue) => {
    setSchool((prev) => ({ ...prev, inputValue }));
  };

  const handleDegreeInputChange = (inputValue) => {
    setDegree((prev) => ({ ...prev, inputValue }));
  };

  const handleSubjectInputChange = (inputValue) => {
    setSubject((prev) => ({ ...prev, inputValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const educationCollectionRef = collection(userDocRef, "education");

      const newEducation = {
        school: school.inputValue.trim(),
        degree: degree.inputValue.trim(),
        subject: subject.inputValue.trim(),
        grade: grade.trim(),
        description: description.trim(),
        startMonth: monthsList[startMonth - 1],
        startYear: Number(startYear),
        endMonth: monthsList[endMonth - 1],
        endYear: Number(endYear),
        dateAdded: new Date(),
      };

      const docRef = await addDoc(educationCollectionRef, newEducation);

      onEducationAdded({ id: docRef.id, ...newEducation });
    } catch (error) {
      setError("Failed to add education. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors and the form is valid
      if (!error && isFormValid) {
        onClose(true);
      }
    }
  };

  const handleInputChange = () => {
    setIsChanged(true);
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Add Education
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)} // Pass false for regular close
              onTouchStart={(e) => handleCloseTouch(e, false)} // Pass false for regular close
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <label className={classes.label}>School*</label>
          <SchoolDropdown
            selectedSchool={school.object}
            onSchoolChange={handleSchoolChange}
            onInputChange={handleSchoolInputChange}
            inputValue={school.inputValue}
            disabled={loading}
            placeholder="Ex: London South Bank University"
          />
          <label className={classes.label}>Qualification*</label>
          <DegreeDropdown
            selectedDegree={degree.object}
            onDegreeChange={handleDegreeChange}
            onInputChange={handleDegreeInputChange}
            inputValue={degree.inputValue}
            disabled={loading}
            placeholder="Ex: Bachelor of Science (BSc)"
          />
          <label className={classes.label}>Subject*</label>
          <SubjectDropdown
            selectedSubject={subject.object}
            onSubjectChange={handleSubjectChange}
            onInputChange={handleSubjectInputChange}
            inputValue={subject.inputValue}
            disabled={loading}
            placeholder="Ex: Product Design"
          />
          <label className={classes.label} htmlFor="grade">
            Grade
          </label>
          <input
            type="text"
            id="grade"
            value={grade}
            onChange={handleGradeChange}
            maxLength={50}
            className={classes.userInput}
            disabled={loading}
            placeholder="Ex: First Class Honours"
          />
          <div className={classes.charCount}>{grade.length}/50</div>
          {/* New Description input */}
          <label className={classes.label}>Description</label>
          <textarea
            className={classes.textArea}
            value={description}
            onChange={handleDescriptionChange}
            disabled={loading}
            placeholder="Describe your educational experience"
            maxLength={1000}
          />
          <div className={classes.charCount}>{descriptionCharCount}/1000</div>
          <label className={classes.label} htmlFor="currentlyStudying">
            Start date*
          </label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={startMonth}
              onMonthChange={handleStartMonthChange}
              onInputChange={handleInputChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={parseInt(startYear) === currentYear ? currentMonth : 12}
            />
            <EducationYearDropdown
              selectedYear={startYear}
              onYearChange={handleStartYearChange}
              disabled={loading}
              placeholder="Year"
              onCurrentYearSelected={handleCurrentYearSelected}
              minYear={currentYear - 100}
              maxYear={currentYear}
            />
          </div>
          <label className={classes.label} htmlFor="endDate">
            End date*
          </label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={endMonth}
              onMonthChange={handleEndMonthChange}
              onInputChange={handleInputChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={12}
              minMonth={
                parseInt(endYear) === parseInt(startYear) ? startMonth : 1
              }
            />
            <EducationYearDropdown
              selectedYear={endYear}
              onYearChange={handleEndYearChange}
              disabled={loading}
              minYear={startYear ? parseInt(startYear) : undefined}
              maxYear={currentYear + 10}
              placeholder="Year"
            />
          </div>
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              className={classes.infoIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                clipRule="evenodd"
              />
            </svg>
            <p>*Required information</p>
          </div>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isFormValid || loading}
            onClick={() => {
              handleSaveButtonClick();
              // Only close the modal if there are no errors and the form is valid
              if (!error && isFormValid) {
                onClose(true);
              }
            }}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEducationModal;
